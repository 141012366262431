import React, { useState } from "react";
import UserItem from "../../../UserItem";
import "./AddMember.scss";

export default function AddMember({ onCloseMember, onAddMember }) {
  const [, /*selectedUsers*/ setSelectedUsers] = useState([]);
  const [item, setItem] = useState();
  console.log("user selectionné", item);

  const handleSelectChange = (select) => {
    setItem(select);
    // onItemSelected(select);
  };

  const handleAdd = async () => {
    await onAddMember(item);
    onCloseMember();
  };

  return (
    <div className="AddMember">
      <div className="add-title">Select user from your link</div>
      <UserItem
        ListUsers={setSelectedUsers}
        onSelectChange={handleSelectChange}
      />
      <div className="decision-buttons">
        <div className="block-btn cancel">Cancel</div>
        <div className="block-btn confirm" onClick={handleAdd}>
          Add Member
        </div>
      </div>
    </div>
  );
}
