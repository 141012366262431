import React, { useState } from 'react';
import './Button.scss'

function Button({ onSubmit, children }) {
  return (
    <div className="button" onClick={onSubmit
    }>
      {children}
    </div>
  );
}

export default Button;