import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from '../Redux/slices/auth';

export default function useAuth(){
    const dispatch = useDispatch();
    const { initialized, user, isAdmin, isLoading, isAuthenticated } = useSelector((state) => state.Auth);

    return{
			user: user,
			initialized: initialized,
			isAdmin: isAdmin,
			isLoading: isLoading,
			isAuthenticated: isAuthenticated,

			login: ({ email, password }) =>
				dispatch(
					login({
						email: email,
						password: password,
					})
				),
			logout: () => dispatch(logout()),

    }
}
