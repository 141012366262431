import React, { useState, useEffect, useLayoutEffect } from "react";

import puzzle from "../../../Assets/img/Profile/puzzle_example.png";
import likes from "../../../Assets/vectors/Profile/number_likes_profile.svg";
import comments from "../../../Assets/vectors/Profile/number_comments_profile.svg";
import percent from "../../../Assets/vectors/Profile/icon_percent_profile.svg";
import avatar_puzzle from "../../../Assets/vectors/Profile/avatar_puzzle.svg";
import avatar_puzzle_ from "../../../Assets/img/Profile/avatar_puzzle.png";
import more from "../../../Assets/vectors/Profile/shopping_cart.svg";
import dot from "../../../Assets/vectors/Profile/dot.svg";
import Puzzle from "../../Puzzle";
import PuzzleList from "./PuzzleList";
import password_hidden from "../../../Assets/img/password_hidden.svg";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { useSelector, useDispatch } from "react-redux";

const Gallery = ({ allClasses, allPuzzles, isMyProfile }) => {
  console.log("isMyProfil : ", isMyProfile);
  const user = useSelector((state) => state.Auth.user);
  const [ClassActive, setClassActive] = useState("");
  const [isSelectActive, setIsSelectActive] = useState(false);
  const [allCategorieState, setAllCategorieState] = useState(null);
  const [allClassChecked, setAllClassChecked] = useState(null);
  const [allPuzzleChecked, setAllPuzzleChecked] = useState(null);
  console.log("first typeof allClass : ", typeof allClassChecked);

  useEffect(() => {
    if (!allPuzzleChecked) {
      setAllPuzzleChecked([]);
    }
    if (!allClassChecked) {
      setAllClassChecked([]);
    }
  }, []);
  useEffect(() => {
    console.log("allPuzzles useEffect first : ", allPuzzles);
    let data = [];
    if (allPuzzles?.length) {
      allPuzzles.map((value) => {
        if (!value.display) {
          data.push(value.reference);
        }
      });
      setAllPuzzleChecked(data);
    }
  }, [allPuzzles]);

  useEffect(() => {
    console.log("allPuzzle changed -- allPuzzlesChe : ", allPuzzleChecked);
  }, [allPuzzleChecked]);

  const puzzlesCounter = (class_name) => {
    let counter = 0;
    allPuzzles?.map((value) => {
      if (value.puzzle_class == class_name) {
        counter++;
      } else if (class_name == "allPuzzles") {
        counter++;
      }
    });
    //console.log(counter)
    return counter;
  };

  const handleGallery = (name) => {
    let currentGallery = document.getElementById(name);
    let counter = document.getElementById("counter-" + name);
    let gallery = document.getElementById("gallery");
    let puzzles = [];

    currentGallery.classList.add("profile_gallery_tab_selected");
    counter.style = "color: rgb(155, 142, 255) !important;";
    if (name != "allPuzzles") {
      document
        .getElementById("allPuzzles")
        .classList.remove("profile_gallery_tab_selected");
      document.getElementById("counter-allPuzzles").style =
        "color: rgba(76, 82, 112, 1) !important;";
    }
    allClasses.map((value) => {
      if (value.name != name) {
        document
          .getElementById(value.name)
          .classList.remove("profile_gallery_tab_selected");
        document.getElementById("counter-" + value.name).style =
          "color: rgba(76, 82, 112, 1) !important;";
      }
    });

    setClassActive(name);
    //console.log(ClassActive);

    //allPuzzles.map((value, key) => {

    //  gallery.appendChild(document.createElement(<Puzzle puzzle_title={value.get("title")} puzzle_class={value.get('class')} puzzle_percent = {value.get('percent')} />))
    //});
  };

  const handleCategorieChange = (e, name) => {
    let data = allClassChecked;
    if (e.target.checked && !data.includes(name)) {
      data.push(name);
    } else if (!e.target.checked && data.includes(name)) {
      let index = data.indexOf(name);
      if (index > -1) {
        data.splice(index, 1);
      }
      let listChecked = allPuzzleChecked;
      allPuzzles.map((value) => {
        console.log("value in map : ", value);
        if (value.puzzle_class == name) {
          console.log("first comp");
          console.log(
            "listchecked : ",
            listChecked + " / value.ref : ",
            value.reference
          );

          if (listChecked.includes(value.reference)) {
            console.log("second comp");
            let ind = listChecked.indexOf(value.reference);
            if (ind > -1) {
              listChecked.splice(ind, 1);
            }
          }
        }
      });
      console.log("listchecked after delete : ", listChecked);
      setAllPuzzleChecked(listChecked);
    }
    setAllClassChecked([...data]);
  };

  const fetchAllPuzzlesChecked = (data) => {
    //let listChecked = []
    //if(allPuzzleChecked) {
    let listChecked = allPuzzleChecked;
    //}
    if (data.value && !listChecked.includes(data.ref)) {
      listChecked.push(data.ref);
    } else if (!data.value && listChecked.includes(data.ref)) {
      let index = listChecked.indexOf(data.ref);
      if (index > -1) {
        listChecked.splice(index, 1);
      }
    }
    setAllPuzzleChecked(listChecked);
  };

  const handleValidate = () => {
    console.log("allPuzzles to send : ", allPuzzleChecked);
    // TODO : send puzzle hidden
    const puzzlesRoute = `${routes.routes.back_end.puzzle.puzzle.url}`;
    const puzzlesMethod = `${routes.routes.back_end.puzzle.puzzle.put_method}`;
    let data = {};
    data["puzzle"] = allPuzzleChecked;
    //console.log("puzzlesRoute+user : ",puzzlesRoute+user.id)
    ApiService.sendingPutData(
      puzzlesRoute + user.id + "/puzzle_to_display",
      puzzlesMethod,
      data,
      "puzzle"
    )
      .then((result) => {
        console.log("result :: ", result);
        setIsSelectActive(false);
      })
      .catch(() => {
        console.log("error");
      });
  };
  return (
    <div className="profile_gallery flex flex-fd-c flex-js-fs">
      <div className="profile_gallery_content flex flex-js-fs">
        <div className="profile_gallery_tab inline">
          <div
            className="profile_gallery_tab_content profile_gallery_tab_selected"
            onClick={() => handleGallery("allPuzzles")}
            id="allPuzzles"
          >
            <span>All Puzzles</span> <img src={dot} alt=""></img>
            <span
              id="counter-allPuzzles"
              className="profile_gallery_tab_content_allPuzzles"
            >
              {" "}
              {puzzlesCounter("allPuzzles")}
            </span>
          </div>
        </div>
        <div className="profile_gallery_content_all-tab">
          {allClasses.map((value) => {
            //console.log("value all classes : ",value)
            return (
              <div
                className="profile_gallery_tab inline"
                id={value.name + "_content"}
              >
                <div
                  className="profile_gallery_tab_content "
                  onClick={() => handleGallery(value.name)}
                  id={value.name}
                >
                  <span>{value.name}</span> <img src={dot} alt=""></img>
                  <span
                    id={"counter-" + value.name}
                    className="profile_gallery_tab_content_counter"
                  >
                    {" "}
                    {puzzlesCounter(value.name)}
                  </span>
                </div>

                {isSelectActive && allClassChecked.includes(value.name) && (
                  <input
                    type="checkbox"
                    //defaultChecked
                    //checked={allCategorieState[value.id]}
                    checked={true}
                    onChange={(e) => handleCategorieChange(e, value.name)}
                  />
                )}
                {isSelectActive && !allClassChecked.includes(value.name) && (
                  <input
                    type="checkbox"
                    //defaultChecked
                    //checked={allCategorieState[value.id]}
                    checked={false}
                    onChange={(e) => handleCategorieChange(e, value.name)}
                  />
                )}
                {/*isSelectActive && 
                !value.id && allCategorieState &&
                <input type="checkbox" 
                //defaultChecked
                checked={allCategorieState[value.id]}
                onChange={(e)=>handleCategorieChange(value.id)}
                />*/}
              </div>
            );
          })}
        </div>
        {isMyProfile && (
          <>
            <div
              className="profile_gallery_icon"
              onClick={() => setIsSelectActive(!isSelectActive)}
            >
              <img src={password_hidden} />
            </div>
          </>
        )}
      </div>
      <hr className="profile_gallery_hr"></hr>
      {/*console.log("all class in checked before puzzle list : ",allClassChecked)*/}
      {/* allPuzzleChecked && allClassChecked ?
      <PuzzleList class_name={ClassActive} all_puzzle={allPuzzles} allClassChecked={allClassChecked} isSelectActive={isSelectActive} fetchAllPuzzlesChecked={fetchAllPuzzlesChecked} allPuzzleChecked={allPuzzleChecked} />
        :
      <PuzzleList class_name={ClassActive} all_puzzle={allPuzzles}  isSelectActive={isSelectActive} fetchAllPuzzlesChecked={fetchAllPuzzlesChecked} />
      */}

      {console.log("allPuzzleChecked before PuzzlesList: ", allPuzzleChecked)}
      {console.log("allClassChecked before PuzzlesList: ", allClassChecked)}
      {allPuzzleChecked && allClassChecked && (
        <PuzzleList
          class_name={ClassActive}
          all_puzzle={allPuzzles}
          allClassChecked={allClassChecked}
          isSelectActive={isSelectActive}
          fetchAllPuzzlesChecked={fetchAllPuzzlesChecked}
          allPuzzleChecked={allPuzzleChecked}
        />
      )}

      {isSelectActive && (
        <div className="profile_gallery_btn flex">
          <button
            className="profile_gallery_btn_content flex"
            onClick={() => handleValidate()}
          >
            validate
          </button>
        </div>
      )}
      {console.log("all puzzles checked in gallery : ", allPuzzleChecked)}
    </div>
  );
};

export default Gallery;
