import link_avatar from "../../Assets/img/Links/link_avatar.svg";
import accept from "../../Assets/img/Links/accept_button.svg";
import refuse from "../../Assets/img/Links/refuse_button.svg";
import ApiService from "../../Utils/ApiService";
import routes from "../../Routes/routes.json";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { userData } from "../../Redux/Actions/userData";
import { acceptLink, refuseLink } from "../../Redux/slices/user";

const Request = ({ avatar, username }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);

  const [acceptRequest, setacceptRequest] = useState(false);
  const [refuseRequest, setrefuseRequest] = useState(false);

  const routeGetUser =
    `${routes.routes.back_end.iconic_user.get_user.url}/` + user.username;
  const methodGetUser = `${routes.routes.back_end.iconic_user.get_user.method}`;

  console.log(user);

  const handleAccept = () => {
    let data = new Object();
    const user_username = user.username;
    data.target = username;

    console.log(user_username);
    console.log(username);
    dispatch(acceptLink(user_username, username));
  };

  const handleRefuse = () => {
    let data = new Object();
    const user_username = user.username;
    data.target = username;
    dispatch(refuseLink(user_username, username));
  };

  return (
    <div className="links_body_left_contacts_requests_content flex flex-ai-c flex-js-fs">
      <img src={link_avatar} alt=""></img>
      <div className="links_body_left_contacts_requests_content_text">
        @{username}
      </div>
      <img
        src={accept}
        alt=""
        className="links_body_left_contacts_requests_content_accept cursor"
        onClick={() => handleAccept()}
      ></img>
      <img
        src={refuse}
        alt=""
        className="cursor"
        onClick={() => handleRefuse()}
      ></img>
    </div>
  );
};

export default Request;
