import React, { useEffect, useState } from "react";
import watch from "../../../Assets/vectors/watch_product.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import CircleProgressBar from "../../CircleProgressBar";
import confirm_check from "../../../Assets/img/Account/confirm_check.svg";

const DetailsPuzzleUser = () => {
  const user = useSelector((state) => state.Auth.user);
  const location = useLocation();
  const [puzzle, setPuzzle] = useState(location.state.initialPuzzle);
  const [puzzleOfUser, setPuzzleOfUser] = useState(location.state.puzzleOfUser);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmMiles, setConfirmMiles] = useState(false);
  const [dayBooking, setDayBooking] = useState("");
  const [firstMilesParam, setFirstMilesParam] = useState(null);
  const [dateStartValue, setDateStartValue] = useState("");
  const [dateEndValue, setDateEndValue] = useState("");
  const [popupExchangeMilesActive, setPopupExchangeMilesActive] =
    useState(false);
  const percentage = 100;
  let rate = 0;
  const [progressBar, setProgressBar] = useState(0);
  const [characters, setCharacters] = useState(
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  );
  console.log("puzzle fetched : ", puzzle);
  const [url, setUrl] = useState(
    "/details/" + puzzle.title + "-" + puzzle.reference
  );
  const [historicData, setHistoricData] = useState([
    {
      order: "N° 20022-21-03",
      date: "Feb 21,2022",
      value: "0.O12 ETH",
      pieces: "+3,000",
    },
    {
      order: "N° 20022-26-03",
      date: "Feb 25,2022",
      value: "0.O19 ETH",
      pieces: "+7,000",
    },
  ]);
  const [buyData, setBuyData] = useState(null);
  const [sellData, setSelldata] = useState(null);
  useEffect(() => {
    fetchOrders();
    fetchRate();
    fetchMilesParam();
  }, []);

  const fetchOrders = () => {
    const urlOrders = `${routes.routes.back_end.user.account.list_all_orders_user.url}`;
    const methodOrders = `${routes.routes.back_end.user.account.list_all_orders_user.method}`;
    console.log("url  : ", urlOrders + user.id + "/buy");
    console.log("method : ", methodOrders);
    //TODO change route
    ApiService.fetchApiData(
      urlOrders + user.id + "/buy",
      methodOrders,
      "transaction"
    )
      .then((result) => {
        console.log("result buy : ", result);
        setBuyData(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };
  useEffect(() => {
    if (progressBar < percentage) updatePercentage();
  }, [progressBar]);
  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
  };
  const handleSuccessMiles = () => {
    setConfirmMiles(!confirmMiles);
  };
  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, 5);
  };

  const generateRef = (length) => {
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleGetMissingPieces = () => {
    const urlPieces = `${routes.routes.back_end.user.account.get_missing_pieces.url}`;
    const methodPieces = `${routes.routes.back_end.user.account.get_missing_pieces.method}`;
    //TODO change route
    /*ApiService.fetchApiData(urlPieces + user.id, methodPieces, "transaction")
      .then((result) => {
        setConfirmSuccess(!confirmSuccess);
      })
      .catch(() => {
        console.log("error : ");
      });*/
    //TODO to delete when route available
    console.log("get missing pieces : ");
    setConfirmSuccess(!confirmSuccess);
  };

  const handleExchangePieces = () => {
    const urlExchange = `${routes.routes.back_end.puzzle.puzzle.url}`;
    const methodExchange = `${routes.routes.back_end.puzzle.puzzle.put_method}`;
    let data = {};
    data["delivery_address"] = "delivery_address";
    console.log(
      "url :  ",
      urlExchange + puzzleOfUser.id + "/exchange_my_pieces"
    );
    console.log("method :  ", methodExchange);
    console.log("data :  ", data);

    ApiService.sendingPutData(
      urlExchange + puzzleOfUser.id + "/exchange_my_pieces",
      methodExchange,
      data,
      "puzzle"
    )
      .then((result) => {
        if (result["message"]) {
          //
        } else {
          setConfirmSuccess(!confirmSuccess);
        }
        console.log(result);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const fetchRate = () => {
    const urlRate = `${routes.routes.back_end.puzzle.rate.url}`;
    const methodRate = `${routes.routes.back_end.puzzle.rate.method}`;

    // fetchApi Data rate
    ApiService.fetchApiData(
      urlRate + "test/" + puzzle.reference,
      methodRate,
      "puzzle"
    )
      .then((result) => {
        console.log("result rate : ", result);
        setDayBooking(result.day_booking_cost);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const fetchMilesParam = () => {
    const urlMilesParam = `${routes.routes.back_end.puzzle.miles_param.url}`;
    const method = `${routes.routes.back_end.puzzle.miles_param.method}`;

    // fetchApi Data rate
    ApiService.fetchApiData(urlMilesParam, method, "puzzle")
      .then((result) => {
        setFirstMilesParam(result[0]);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const handleExchangeMiles = () => {
    const urlMiles = `${routes.routes.back_end.puzzle.create_miles_order.url}`;
    const methodMiles = `${routes.routes.back_end.puzzle.create_miles_order.method}`;

    //setConfirmMiles(!confirmMiles);

    let data = {};
    data.user_id = user.id;
    data.reference = generateRef(10);
    data.puzzle_reference = puzzle.reference;
    data.miles_reference = firstMilesParam.reference;
    data.miles_rate = dayBooking;

    //data.start_date = dateStartValue;
    //data.end_date = dateEndValue;
    data.start_date = "2022-08-22";
    data.end_date = "2022-09-05";
    data.status = "confirmed";

    console.log("data before : ", data);
    console.log(
      "dateStartValue : ",
      dateStartValue,
      " -- dateEndValue : ",
      dateEndValue
    );
    //setConfirmMiles(!confirmMiles)
    ApiService.sendingPutData(urlMiles, methodMiles, data, "puzzle")
      .then((result) => {
        console.log(result);
        console.log("resulr after miles order : ", result);
        setPopupExchangeMilesActive(false);
        setConfirmMiles(!confirmMiles);
      })
      .catch(() => {
        console.log("error miles order");
      });
  };

  const displayConfirm = (message, handleConfirm) => {
    return (
      <div className="flex">
        <div className="exchange_popup flex flex-fd-c flex-js-fs">
          <CircleProgressBar
            trailStrokeColor="#FD55BA"
            strokeColor="#9B8EFF"
            percentage={progressBar}
            innerText="complete"
            radioCircle1={10}
            radioCircle2={12}
            y={19}
            size={38}
            x={19}
            className=""
            withtext={false}
            strokeDasharray={`${progressBar} ${100 - progressBar}`}
            strokeDashoffset={25}
          />
          <img
            src={confirm_check}
            alt=""
            className="transferout_popup_success"
          ></img>
          <div className="transferout_popup_confirm_text flex flex-ai-c">
            {message}
          </div>
        </div>
        <div id="overlay" onClick={() => handleConfirm()}></div>
      </div>
    );
  };
  return (
    <div className=" flex flex-fd-c flex-js-fs">
      <div className="page_title page_title_transferin">
        {puzzle.title}
        <div className="register_header_path flex flex-js-fs">
          <Link to="/home">Home</Link>{" "}
          <div className="register_header_path_gt">{">"}</div>{" "}
          <Link to="/my_puzzles">
            <span>My puzzles</span>
          </Link>{" "}
          <div className="register_header_path_gt">{">"}</div>{" "}
          <span>{puzzle.title}</span>
        </div>
      </div>

      <div className="flex-elem puzzle-user_content">
        <div className="flex-elem flex-fd-c puzzle-user_content_left">
          <div className="puzzle-user_content_left_title">puzzle title</div>
          <div className="puzzle-user_content_left_img">
            {puzzle.images.length > 0 ? (
              <img
                className="puzzle-user_content_left_img_content"
                src={puzzle.images[0]}
                alt=""
              />
            ) : (
              <img
                className="puzzle-user_content_left_img_content"
                src={watch}
                alt="watch"
              />
            )}
          </div>
          <Link to={url} state={puzzle}>
            <div className="puzzle-user_content_left_btn">
              <span className="puzzle-user_content_left_btn_text">
                See puzzle details
              </span>
            </div>
          </Link>
        </div>
        <div className="flex-elem flex-fd-c puzzle-user_content_right">
          <div className="puzzle-user_content_right_details ">
            <div className="puzzle-user_content_right_details_all-text puzzle-user_content_right_details_title">
              Details
            </div>
            <div className="puzzle-user_content_right_details_line"></div>
            {/*<div className="flex-elem puzzle-user_content_right_details_piece puzzle-user_content_right_details_all-text">
              <span>Piece owned</span>
              <span>{-1 * puzzleOfUser.piece}</span>
            </div>*/}
            <div className="flex-elem puzzle-user_content_right_details_all-item">
              <div className="flex-elem flex-fd-c puzzle-user_content_right_details_all-item_first puzzle-user_content_right_details_all-text">
                <span>Piece owned</span>
                <span>Piece required</span>
                <span>Miles owned</span>
                <span>Miles required</span>
              </div>
              <div className="flex-elem flex-fd-c puzzle-user_content_right_details_all-item_first puzzle-user_content_right_details_all-text">
                <span>{-1 * puzzleOfUser.piece}</span>
                <span>{puzzle.piece_required} piece</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
            <div className="flex-elem puzzle-user_content_right_details_all-btn">
              <button
                className="flex-elem puzzle-user_content_right_details_all-btn_miles puzzle-user_content_right_details_all-btn_text"
                //onClick={() => handleExchangeMiles()}
                onClick={() =>
                  setPopupExchangeMilesActive(!popupExchangeMilesActive)
                }
              >
                Exchange my miles
              </button>
              {popupExchangeMilesActive && (
                <>
                  <div className="puzzle-user_popup-miles">
                    <div className="flex flex-fd-c puzzle-user_popup-miles_content">
                      <div className="flex puzzle-user_popup-miles_content_title">
                        Exchange My miles
                      </div>
                      <div className="flex flex-fd-c puzzle-user_popup-miles_content_date-start">
                        <div className="flex-elem puzzle-user_popup-miles_content_date-start_text">
                          Date Start
                        </div>
                        <div className="flex-elem puzzle-user_popup-miles_content_date-start_input">
                          <input
                            className="puzzle-user_popup-miles_content_date-start_input_content"
                            type="date"
                            onChange={(e) => setDateStartValue(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="flex flex-fd-c puzzle-user_popup-miles_content_date-start">
                        <div className="flex-elem puzzle-user_popup-miles_content_date-start_text">
                          End Start
                        </div>
                        <div className="flex-elem puzzle-user_popup-miles_content_date-start_input">
                          <input
                            className="puzzle-user_popup-miles_content_date-start_input_content"
                            type="date"
                            onChange={(e) => setDateEndValue(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex puzzle-user_popup-miles_content_confirm">
                        <button
                          className="puzzle-user_popup-miles_content_confirm_btn"
                          onClick={() => handleExchangeMiles()}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                    <div id="over"></div>
                  </div>
                </>
              )}
              <button
                className="flex-elem puzzle-user_content_right_details_all-btn_pieces puzzle-user_content_right_details_all-btn_text"
                onClick={() => handleExchangePieces()}
              >
                Exchange my pieces
              </button>
              {puzzleOfUser.piece < puzzle.piece_required ? (
                <button
                  className="flex-elem puzzle-user_content_right_details_all-btn_missing puzzle-user_content_right_details_all-btn_text"
                  //disabled
                  onClick={() => handleGetMissingPieces()}
                >
                  Get missing pieces
                </button>
              ) : (
                <button
                  className="flex-elem puzzle-user_content_right_details_all-btn_missing puzzle-user_content_right_details_all-btn_text"
                  onClick={() => handleGetMissingPieces()}
                >
                  Get missing pieces
                </button>
              )}
            </div>
          </div>
          <div className="flex-elem flex-fd-c puzzle-user_content_right_historic">
            <div className="puzzle-user_content_right_details_all-text puzzle-user_content_right_details_title">
              Historic exchange
            </div>
            <div className="flex-elem puzzle-user_content_right_historic_tab">
              <div className="puzzle-user_content_right_historic_tab_text-column puzzle-user_content_right_historic_tab_order">
                Order
              </div>
              <div className="puzzle-user_content_right_historic_tab_text-column puzzle-user_content_right_historic_tab_date">
                Date
              </div>
              <div className="puzzle-user_content_right_historic_tab_text-column puzzle-user_content_right_historic_tab_value">
                value
              </div>
              <div className="puzzle-user_content_right_historic_tab_text-column puzzle-user_content_right_historic_tab_value">
                Pieces
              </div>
            </div>

            {historicData.map((value) => {
              return (
                <div className="flex-elem puzzle-user_content_right_historic_item">
                  <div className="puzzle-user_content_right_historic_item_text puzzle-user_content_right_historic_tab_order">
                    {value.order}
                  </div>
                  <div className="puzzle-user_content_right_historic_item_text puzzle-user_content_right_historic_tab_date">
                    {value.date}
                  </div>
                  <div className="puzzle-user_content_right_historic_item_text puzzle-user_content_right_historic_tab_value">
                    {value.value}
                  </div>
                  <div className="puzzle-user_content_right_historic_item_text puzzle-user_content_right_historic_tab_value">
                    {value.pieces}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {confirmSuccess && displayConfirm("Exchange pieces done", handleSuccess)}
      {confirmMiles &&
        displayConfirm("Exchange Miles done", handleSuccessMiles)}
      {firstMilesParam && console.log("firstMilesPraam  : ", firstMilesParam)}
    </div>
  );
};

export default DetailsPuzzleUser;
