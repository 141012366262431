import MarketHeader from "./Market_header";
import MarketBody from "./Market_body";
import { Link } from "react-router-dom";
const Market = () => {
    return (
      <div className="market flex flex-fd-c flex-js-fs">
        <div className="page_title-puzzle">
         What's new in your market?
          <div className="register_header_path flex flex-js-fs">
            <Link to={"/home"}>Home</Link> <div className="register_header_path_gt">{">"}</div>{" "}
            <span>Market</span>
          </div>
        </div>
        <MarketHeader  />
        <div className="account flex flex-fd-c">
       
            <MarketBody/>
        </div>
      </div>
    );
};
  
export default Market;