import React, { useEffect, useState, useRef } from "react";
import logo from "./logo.svg";
//import './App.css';

import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
  Redirect,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./Style/style.scss";
import Navbar from "./Components/User/Navbar/Navbar";
import Home from "./Components/Home";
import Account from "./Components/User/Account";
import Purchase from "./Components/User/Purchase";
import Details from "./Components/User/Details";
import Details2 from "./Components/User/Details2";
import Register from "./Components/User/Register";
import Profile from "./Components/User/Profile/Profile";
import Myprofile from "./Components/User/Profile/Myprofile";
import Connection from "./Components/User/Connection/Connection";
import Mywallet from "./Components/User/Mywallet";
import Market from "./Components/User/Market/Market";
import TransferOut from "./Components/User/TransferOut";
import DefaultPage from "./Components/User/Default/DefaultPage";
import routes from "./Routes/routes.json";
import { useSelector, useDispatch } from "react-redux";
import { darkMode } from "./Redux/Actions/darkMode";
import Links from "./Components/Links/Links";
import Exchange from "./Components/User/Exchange";
import Transferin from "./Components/User/Transferin";
import SearchResult from "./Components/SearchResult";
import Mypuzzles from "./Components/User/Mypuzzles/Mypuzzles";
import DetailsPuzzleUser from "./Components/User/DetailsPuzzleUser/DetailsPuzzleUser";
import AllTransactions from "./Components/User/Account/AllTransactions";
import MyWallet from "./Components/User/Account/MyWallet";
import Settings from "./Components/User/Settings";
import Faq from "./Components/User/Faqs";
import Footer from "./Components/common/Footer";
import About from "./Components/common/About";
import Contact from "./Components/common/Contact";
import Policy from "./Components/common/Policy";
import Term from "./Components/common/Term";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

function App() {
  const darkMode = useSelector((state) => state.darkMode);
  const user = useSelector((state) => state.Auth);
  const [detailsPath, setDetailsPath] = useState(
    routes.routes.front_end.user.details + "/:name"
  );
  const [passwordRoute, setPasswordRoute] = useState(
    routes.routes.front_end.user.connection + "/:code"
  );

  const queryClient = new QueryClient();

  return (
    // TODO

    <QueryClientProvider client={queryClient}>
      <div className={`app  ${darkMode == false ? "app_dark" : "app_light"}`}>
        <Router>
          {user.isAuthenticated && <Navbar />}

          <div className="app_content">
            <Routes>
              {!user.isAuthenticated ? (
                <>
                  <Route
                    path={routes.routes.front_end.user.default}
                    //element={<DefaultPage />}
                    element={<DefaultPage />}
                  />
                  <Route
                    path={routes.routes.front_end.user.register}
                    element={<Register />}
                  />
                  <Route
                    path={routes.routes.front_end.user.connection}
                    element={<Connection isConnection={true} />}
                  />
                  <Route
                    path={passwordRoute}
                    element={<Connection isConnection={false} />}
                  />
                  <Route
                    path={routes.routes.front_end.user.faq}
                    element={<Faq />}
                  />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </>
              ) : (
                <>
                  <Route
                    path={routes.routes.front_end.user.home}
                    element={<Home />}
                  />

                  <Route
                    path={routes.routes.front_end.user.account}
                    element={<Account />}
                  />
                  <Route
                    path={routes.routes.front_end.user.purchase}
                    //element={<Purchase />}
                    element={<Purchase />}
                  />
                  <Route path={detailsPath} element={<Details />} />

                  <Route
                    path={routes.routes.front_end.user.faq}
                    element={<Faq />}
                  />
                  <Route
                    path={routes.routes.front_end.user.myprofile}
                    element={<Myprofile />}
                  />
                  <Route
                    path={routes.routes.front_end.user.mywallet}
                    element={<Mywallet />}
                  />
                  <Route
                    path={routes.routes.front_end.user.market}
                    element={<Market />}
                  />
                  <Route
                    path={routes.routes.front_end.user.transfer_out}
                    element={<TransferOut />}
                  />
                  <Route path="*" element={<Navigate to="/home" replace />} />
                  <Route
                    path={routes.routes.front_end.user.links}
                    element={<Links />}
                  />
                  <Route
                    path={routes.routes.front_end.user.exchange}
                    element={<Exchange />}
                  />
                  <Route
                    path={routes.routes.front_end.user.profile}
                    element={<Profile />}
                  />
                  <Route
                    path={routes.routes.front_end.user.transfer_in}
                    element={<Transferin />}
                  />
                  <Route
                    path={routes.routes.front_end.user.search_result}
                    element={<SearchResult />}
                  />
                  <Route
                    path={routes.routes.front_end.user.my_puzzles}
                    element={<Mypuzzles />}
                  />
                  <Route
                    path={routes.routes.front_end.user.details_puzzle_user}
                    element={<DetailsPuzzleUser />}
                  />
                  <Route
                    path={routes.routes.front_end.user.all_transactions}
                    element={<AllTransactions />}
                  />
                  <Route
                    path={routes.routes.front_end.user.my_wallet}
                    element={<MyWallet />}
                  />
                  <Route
                    path={`${routes.routes.front_end.user.settings}`}
                    element={<Settings />}
                  >
                    {/* <Route 
                  path={routes.routes.front_end.user.faq} 
                  element={<FAQ/>} /> */}
                  </Route>
                  <Route
                    path={routes.routes.front_end.user.about}
                    element={<About />}
                  />
                  <Route
                    path={routes.routes.front_end.user.contact}
                    element={<Contact />}
                  />
                  <Route
                    path={routes.routes.front_end.user.policy}
                    element={<Policy />}
                  />
                  <Route
                    path={routes.routes.front_end.user.term}
                    element={<Term />}
                  />
                </>
              )}
            </Routes>
          </div>
          {/* <div className="footer">
          
        </div> */}
          <Footer />
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;
