import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Register from "./User/Register";
import Login from "./User/Login";

import watch from "../Assets/img/watch.png";
import whisky from "../Assets/img/whisky.png";
import board_3d from "../Assets/img/board_3d.png";
import pendentif from "../Assets/img/pendentif.png";

import rings from "../Assets/img/rings.png";
import person from "../Assets/img/person.png";
import bottles from "../Assets/img/bottles.png";
import luxury_watch from "../Assets/img/luxury_watch.png";
import pp from "../Assets/img/pp.png";
import ben from "../Assets/img/ben.png";
import amir from "../Assets/img/amir.png";
import red_bottle from "../Assets/img/red_bottle.png";
import options from "../Assets/vectors/options.svg";
import notification from "../Assets/vectors/notification.svg";
import share from "../Assets/vectors/share.svg";
import like from "../Assets/vectors/like.svg";
import liked from "../Assets/vectors/liked.svg";
import Profile from "../Assets/vectors/Profile.svg";
import right_arrow from "../Assets/vectors/right_arrow_button.svg";
import left_arrow from "../Assets/vectors/left_arrow_button.svg";
import line from "../Assets/vectors/line_button.svg";
import stars from "../Assets/img/stars.svg";
import ring from "./rings.png";
import "./home.scss";

import likes from "../Assets/vectors/number_likes_profile.svg";
import comments from "../Assets/vectors/number_comments_profile.svg";

import routes from "../Routes/routes.json";

import { useSelector, useDispatch } from "react-redux";

import { darkMode } from "../Redux/Actions/darkMode";
import { lightMode } from "../Redux/Actions/darkMode";
import ApiService from "../Utils/ApiService";
import { userData } from "../Redux/Actions/userData";
import Puzzle from "./Puzzle";
import CarouselHome from "./CarouselHome";
import { getWallets, getLink, getUser } from "../Redux/slices/user";
import Footer from "./common/Footer";
import SwiperHome from "./SwiperHome/SwiperHome";
import BannerHeader from "./User/Market/Market_header";

const Home = () => {
  const dispatch = useDispatch();
  const [allPuzzles, setAllPuzzles] = useState([]);
  const [allClasses, setAllClasses] = useState([]);
  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.user);
  console.log("user in home : ", user);
  console.log(useSelector((state) => state.User));
  /*console.log("localStorage  :", localStorage.getItem("username"));
  console.log("localStorage  :", localStorage.getItem("id_user"));*/
  const [puzzles, setPuzzles] = useState([
    watch,
    whisky,
    board_3d,
    pendentif,
    Profile,
    person,
    bottles,
    luxury_watch,
  ]);

  useEffect(() => {
    const puzzlesRoute = `${routes.routes.back_end.puzzle.puzzles.url}`;
    const classesRoute = `${routes.routes.back_end.puzzle.classes.url}`;
    const method = `${routes.routes.back_end.puzzle.puzzles.method}`;
    //console.log(puzzlesRoute);
    dispatch(getWallets(user.id));
    dispatch(getUser(user.username));

    ApiService.fetchApiData(puzzlesRoute, method, "puzzle").then((result) => {
      setAllPuzzles(result);
      processPuzzlesHome(result);
    });

    ApiService.fetchApiData(classesRoute, method, "puzzle").then((result) => {
      //console.log(result);
      processClasses(result);
    });
  }, []);
  console.log("Puzzles Acceuil" , allPuzzles)

  const processPuzzlesHome = (result) => {
    let listPuzzles = [];
    let puzzle = new Object();
    for (let key in result) {
      puzzle = new Object();
      puzzle = result[key];
      listPuzzles.push(puzzle);
      if (key > 4) {
        break;
      }
    }
    listPuzzles.sort((formerValue, followingValue) => {
      return formerValue.likes.length - followingValue.likes.length;
    });
    setAllPuzzles(listPuzzles.slice(0).reverse());
  };

  const processClasses = (result) => {
    let classe = new Map();
    let listClasses = [];
    for (let key in result) {
      classe = new Map();
      for (let value in result[key]) {
        if (value !== "_id") {
          classe.set(value, result[key][value]);
        }
      }
      listClasses.push(classe);
    }
    setAllClasses(listClasses);
  };

  const [puzzlestest, setPuzzlestest] = useState([ring, watch]);
  // const [isliked, setIsliked] = useState(false);

  const [allLinks, setAlllinks] = useState([
    new Map([
      ["image", pp],
      ["name", "@eddie97"],
      ["description", "bought 5 puzzles"],
      ["temps", "today at 11:49 am"],
      ["content", allPuzzles[0]],
      ["comments", 50],
      ["likes", 300],
      ["type", "achat"],
      ["id", 1],
    ]),
    new Map([
      ["image", ben],
      ["name", "@ben220"],
      ["description", "Accepts your contact request"],
      ["temps", "today at 11:49 am"],
      ["content", allPuzzles[0]],
      ["comments", 50],
      ["likes", 300],
      ["type", "demande"],
      ["id", 2],
    ]),
    new Map([
      ["image", amir],
      ["name", "@amir94"],
      ["description", "wishes to be part of your links"],
      ["temps", "today at 11:49 am"],
      ["content", allPuzzles[0]],
      ["comments", 50],
      ["likes", 300],
      ["type", "demande"],
      ["id", 3],
    ]),
  ]);

  return (
    <div className="home flex flex-fd-c flex-js-fs">
      <div className="home_section2_title">Discover</div>

      <CarouselHome />
      {/* <CarouselHome /> */}

      <SwiperHome />

      <div className="flex home_section3 flex-js-sb">
        <div className="flex flex-js-fs home_section3_links">
          <span className="home_section3_links_text">Last links</span>
          {allLinks.map((value, key) => {
            return (
              <div
                key={key}
                className="flex home_section3_links_show flex-js-fs"
              >
                <img
                  draggable="false"
                  src={value.get("image")}
                  alt="pp"
                  className="home_section3_links_show_img"
                />
                <div className="home_section3_links_show_text">
                  <span>{value.get("name")}</span>
                  <span className="home_section3_links_show_text_description">
                    {value.get("description")}
                  </span>
                  <br></br>

                  <span className="home_section3_links_show_text_temps">
                    {value.get("temps")}{" "}
                  </span>
                  {value.get("type") == "achat" && (
                    <div className="home_section3_links_show_content flex flex-js-fs">
                      <div className="home_section3_links_show_content_img">
                        <img
                          draggable="false"
                          src={watch}
                          alt="pp"
                          className="home_section3_links_show_content_img"
                        />
                      </div>
                      <div className="home_section3_links_show_content_text flex flex-fd-c">
                        <div className="home_section3_links_show_content_text_category">
                          {"ART"}
                        </div>
                        <div className="home_section3_links_show_content_text_title">
                          {"GALLERY"}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="home_section3_links_show_text_footer flex flex-js-fs">
                    <img
                      draggable="false"
                      src={likes}
                      alt="pp"
                      className="home_section3_links_show_text_footer_likes_img"
                    />
                    <div className="home_section3_links_show_text_footer_likes_text">
                      {value.get("likes")}
                    </div>
                    <img
                      draggable="false"
                      src={comments}
                      alt="pp"
                      className="home_section3_links_show_text_footer_comments_img"
                    />
                    <div className="home_section3_links_show_text_footer_comments_text">
                      {value.get("comments")}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <Link to={"/links"}>
            <div className="home_section3_links_button flex">
              Go to the links
            </div>
          </Link>
        </div>
        <div className="flex flex-fd-c flex-ai-c">
          <span className="home_section3_links_text title_puzzles">
            Hottest puzzles
          </span>
          <div className="puzzle_acceuil">
            {allPuzzles.map((puzzle, key) => {
              const url = "/details/" + puzzle.title + "-" + puzzle.reference;
              console.log("puzzle length : ", puzzle.likes.length);
              return (
                <Link key={key} to={url} state={puzzle}>
                  <div className="home_section3_body_card flex">
                    <div className="home_section3_body_card_view">
                      <Puzzle ObjPuzzle={puzzle} Market={false} Home={true} />
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
          <Link to="/market">
            <div className="home_section3_links_button flex button-margin button-width">
              Go to the market place
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Home;
