import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { connexion } from "../../../Redux/Actions/connexion";
import videoBackground from "../../../Assets/img/puzzle_iconic-v12.mp4";
import "./default.scss";

const DefaultPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRegister = () => {
    console.log("in hnadleRegi");
    //navigate("/register");
    location.pathname = "/register";
  };
  return (
    <div className="video-container-default">
      <video autoPlay muted loop id="background-video">
        <source src={videoBackground} type="video/mp4" />
      </video>
      <div className="content-default flex">
        <Link to="/register">
          <button
            className="defaultPage_connect"
            onClick={() => handleRegister()}
          >
            <span className="defaultPage_connect_text">Register</span>
          </button>
        </Link>
        <Link to="/connection">
          <button className="defaultPage_connect defaultPage_btn_connexion">
            <span className="defaultPage_connect_text">Connexion</span>
          </button>
        </Link>
      </div>
    </div>
  );
};
export default DefaultPage;
