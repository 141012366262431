/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./UserItem.scss";
import { useSelector } from "react-redux";

export default function UserItem({ ListUsers, selectAll, onSelectChange }) {
  const links = useSelector((state) => state.User.link);

  const updatedUsers = links.map((user) => ({
    ...user,
    isChecked: false,
  }));

  const [users, setUsers] = useState(updatedUsers);
  const [select, setSelect] = useState();

  const handleUserCheck = (id) => {
    const updatedUsers = [...users];
    const index = updatedUsers.findIndex((user) => user.id === id);
    updatedUsers[index] = {
      ...updatedUsers[index],
      isChecked: !updatedUsers[index].isChecked,
    };
    setUsers(updatedUsers);
    setSelect(updatedUsers.filter((user) => user.isChecked));
  };

  useEffect(() => {
    updateSelectedUsers();
    onSelectChange(select);
  }, [users, select]);

  useEffect(() => {
    if (selectAll) {
      setSelect(users);
    } else {
      setSelect([]);
    }
  }, [selectAll]);

  const updateSelectedUsers = () => {
    const selectedUsers = users.filter((user) => user.isChecked);
    ListUsers(selectedUsers);
  };

  return (
    <div className="users-list">
      {users.map((user) => (
        <div className="user-item-container" key={user?.id}>
          <div className="user-item">
            <div className="profile-pic" />
            <label>{user?.username}</label>
          </div>
          <div
            className={`check-user ${
              selectAll || user?.isChecked ? "checked" : "unchecked"
            }`}
            onClick={() => handleUserCheck(user.id)}
          />
        </div>
      ))}
    </div>
  );
}
