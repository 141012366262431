import React from 'react'
import './RadioButton.scss'

export default function RadioInput({label, value, checked, setter}){
	return (
	  <label className='RadioInput'>
	    <input type="radio" checked={checked == value}
	           onChange={() => setter(value)} />
	    <span>{label}</span>
	  </label>
	);
};
   