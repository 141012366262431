/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../common/Input";
import ApiService from "../../../../Utils/ApiService";
import routes from "../../../../Routes/routes.json";
import Swal from "sweetalert2";
import "./verification.scss";
import { FiCamera } from "react-icons/fi";
import { getUser, getUserSuccess } from "../../../../Redux/slices/user";
import Webcam from "react-webcam";

const VerificationAccount = ({ onClose, setVerificationStatus }) => {
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [date, setDate] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const user = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const [photoURL, setPhotoURL] = useState(null);
  const [photoUrlTaken, setPhotoUrlTaken] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [photoTaken, setPhotoTaken] = useState(false);
  const videoRef = useRef(null);
  const [uploadedPhotoURL, setUploadedPhotoURL] = useState(null);
  const [, setPhotoUploadName] = useState(null);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const openCamera = () => {
    setCameraOpen(true);
    setPhotoTaken(false);
  };

  const generateFileName = () => {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const randomString = Math.random().toString(36).substring(2, 8);
    const fileName = `${timestamp}_${randomString}.jpg`;
    return fileName;
  };
  const capturePhoto = () => {
    if (videoRef.current) {
      const screenshot = videoRef.current.getScreenshot();

      const canvas = document.createElement("canvas");
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob((blob) => {
          const fileName = generateFileName();
          const file = new File([blob], fileName, { type: "image/jpeg" });

          setPhotoUrlTaken(URL.createObjectURL(file));
          setPhotoURL(file);
          setPhotoTaken(true);
        }, "image/jpeg");
      };

      img.src = screenshot;
    }
  };

  const retakePhoto = () => {
    setPhotoTaken(false);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const uploadFileName = file.name;
    const uploadedPhotoURL = URL.createObjectURL(file);
    setFile(uploadedPhotoURL);
    setUploadedPhotoURL(file);
    setPhotoUploadName(uploadFileName);
  };

  const handleVerify = () => {
    const routeVerifyUser = `${routes.routes.back_end.iconic_user.verify_user.url}`;
    const method = `${routes.routes.back_end.iconic_user.verify_user.method}`;
    const userId = user.id;

    let data = {};
    data.first_name = firstName;
    data.last_name = lastname;
    data.birth_date = date;
    data.photo_prise = photoURL;
    data.photo_upload = uploadedPhotoURL;
    console.log("dataCONSOLE : ", data);
    ApiService.postFormData(routeVerifyUser, method, data, "user")
      .then((result) => {
        console.log("result", result);
        getUser(result);
        if (result.message === "success") {
          setVerificationStatus("success");
          localStorage.setItem(`verificationStatus_${userId}`, "success");
          Swal.fire({
            title: "Verification in Progress",
            text: "SUCCESS",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
          }).then(() => {
            window.location.reload();
          });
          dispatch(getUserSuccess({ ...user, status_verified: "pending" }));
        } else {
          setVerificationStatus("failed");
          localStorage.setItem(`verificationStatus_${userId}`, "failed");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            timer: 1500,
            text: "Verification Failed",
            timerProgressBar: true,
          });
        }
      })
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.log("error", routeVerifyUser);
      });
  };

  console.log("photo d'identité 1", photoURL);
  console.log("photo upload", uploadedPhotoURL);

  return (
    <div className="NewFAQ">
      {currentStep === 1 && (
        <div className="form">
          <div className="container-input">
            <Input
              label="FirstName"
              placeholder="First Name"
              type="text"
              name="firstname"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <Input
              label="LastName"
              placeholder="Last Name"
              type="text"
              name="lastname"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <div className="container-input">
              <Input
                label="Birth Date"
                placeholder="dd/mm/yyyy"
                value={date}
                type="date"
                name="birthdate"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="buttons">
            <button className="button" onClick={() => setCurrentStep(2)}>
              Next
            </button>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div className="form">
          <div className="photo-title">Take Your Photo</div>
          <div className="photo-upload-container">
            {photoTaken ? (
              <img
                draggable="false"
                className="profile_container_about_left_avatar_img"
                src={photoUrlTaken}
                alt="Captured Photo"
              />
            ) : (
              <div className="profile_container_about_left_avatar">
                <Webcam
                  audio={false}
                  ref={videoRef}
                  screenshotFormat="image/jpeg"
                  className={`profile_container_about_left_avatar_img ${
                    cameraOpen ? "active" : ""
                  }`}
                />
              </div>
            )}
            {!cameraOpen && !photoTaken && (
              <button className="button" onClick={openCamera}>
                Open Camera
              </button>
            )}
            {cameraOpen && !photoTaken && (
              <button className="button" onClick={capturePhoto}>
                <FiCamera />
              </button>
            )}
            {photoTaken && (
              <button className="button" onClick={retakePhoto}>
                Retake Your Photo
              </button>
            )}
          </div>
          <div className="photo-title"> Upload Your Photo</div>
          <div className="photo-upload-container img-bottom">
            <div className="profile_container_about_left_avatar">
              {uploadedPhotoURL && (
                <img
                  draggable="false"
                  className="profile_container_about_left_avatar_img"
                  src={file}
                  alt="Uploaded Photo"
                />
              )}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
            </div>
            <button
              className="button"
              onClick={() => fileInputRef.current.click()}
            >
              Upload Photo
            </button>
          </div>
          <div className="buttons">
            <button className="button" onClick={handleVerify}>
              Verify My Identity
            </button>
            <button className="button" onClick={() => setCurrentStep(1)}>
              Previous
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerificationAccount;
