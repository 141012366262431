import dot from "../../Assets/vectors/Profile/dot.svg";
import Request from "./Request";
const LinkRequests = ({ request_link }) => {
  console.log(request_link);

  if (request_link.length != "0") {
    return (
      <div className="links_body_left_contacts_requests flex-fd-c flex-js-fs">
        <div className="links_body_left_contacts_requests_title flex-ai-c flex flex-js-fs ">
          <p>Link request</p> <img src={dot} alt=""></img>{" "}
          <span> {request_link.length} </span>
        </div>
        {request_link.map((request) => {
          return (
            <Request avatar={request.avatar} username={request.username} />
          );
        })}
      </div>
    );
  } else {
    return (
      <div className="links_body_left_contacts_requests flex-fd-c flex-js-fs">
        <div className="links_body_left_contacts_requests_title flex-ai-c flex flex-js-fs ">
          No add request in progress
        </div>
      </div>
    );
  }
};

export default LinkRequests;
