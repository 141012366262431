/* eslint-disable no-mixed-operators */
/* eslint-disable no-new-object */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Activity from "./Activity";
import { useSelector, useDispatch } from "react-redux";
import avatar from "../../../Assets/img/Profile/avatar_profile.png";
import verified from "../../../Assets/vectors/Profile/account_verified.svg";
import edit from "../../../Assets/vectors/Profile/edit_profile_icon.svg";
import save from "../../../Assets/vectors/Profile/save_profile.svg";
import add from "../../../Assets/vectors/Profile/add_social_network.svg";

import twitter from "../../../Assets/vectors/Profile/twitter.svg";
import facebook from "../../../Assets/vectors/Profile/facebook.svg";
import linkedin from "../../../Assets/vectors/Profile/linkedin.svg";
import other from "../../../Assets/vectors/Profile/other_social_network.svg";

import badge from "../../../Assets/vectors/Profile/unverified_profile_badge.svg";
import default_avatar from "../../../Assets/vectors/Profile/default_avatar.svg";
import add_link_btn from "../../../Assets/img/Profile/add_link_btn.svg";

import { requestLink } from "../../../Redux/slices/user";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { getUser } from "../../../Redux/slices/user";
import { updateUsers } from "../../../Redux/slices/user";
import btn_link_accept from "../../../Assets/vectors/Profile/btn_link_accept.svg";
import btn_link_refuse from "../../../Assets/vectors/Profile/btn_link_refuse.svg";
import { acceptLink, refuseLink } from "../../../Redux/slices/user";
import { AddLink } from "./AddLink";
import Modal from "../../common/Modal";
//import { slice } from "../../../Redux/slices/user";

const Dashboard = ({ user, userconnected }) => {
  const userAuth = useSelector((state) => state.Auth.user);
  const linksUser = useSelector((state) => state.User.link);
  const otherUser = useSelector((state) => state.User);
  const dispatch = useDispatch();
  console.log(linksUser);
  //console.log(user)
  //console.log(userAuth)

  const [file, setFile] = useState(null);

  const [profile, setProfile] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [inputDescription, setInputDescription] = useState(user.about_me);
  const [linkValue, setLinkValue] = useState();
  const [dataLink_in, setDataLinkIn] = useState();
  const [dataLink_twitter, setDataLinkTwitter] = useState();
  const [dataLink_fb, setDataLinkFb] = useState();

  useEffect(() => {
    setDataLinkIn(user.link_in);
    setDataLinkTwitter(user.link_twitter);
    setDataLinkFb(user.link_fb);
  }, [user]);

  function handleChange(event) {
    if (event.target.files.length > 0) {
      const file = URL.createObjectURL(event.target.files[0]);
      setFile(file);
      console.log("file : ", file);
    }
  }
  console.log(user);

  const handleAccept = () => {
    let data = new Object();
    const user_username = user.username;
    data.target = otherUser.username;

    console.log("user_username : ", user_username);
    console.log("otherUser.username : ", otherUser.username);
    console.log("before dispatch Link");
    dispatch(acceptLink(otherUser.username, user_username));
  };

  const handleRefuse = () => {
    let data = new Object();
    const user_username = user.username;
    data.target = otherUser.username;
    dispatch(refuseLink(otherUser.username, user_username));
  };

  const handleSave = () => {
    setProfile(true);
    const routeUpdateUser = `${routes.routes.back_end.iconic_user.update_user.url}`;
    const methodUpdateUser = `${routes.routes.back_end.iconic_user.update_user.method}`;
    let data = {};
    data.about_me = inputDescription;
    data.avatar = file;
    data.link_fb = linkValue?.facebook;
    data.link_twitter = linkValue?.twitter;
    data.link_in = linkValue?.linkedin;
    console.log("data to update", data);
    ApiService.sendingPutData(
      routeUpdateUser + "/" + user.username,
      methodUpdateUser,
      data,
      "user"
    )
      .then((result) => {
        console.log("result after update user : ", result);
        dispatch(updateUsers(result));
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };

  const handleUpdateAvatar = () => {
    // console.log("avatar clicked");
    // TODO  : upload file and display it in change
  };

  const handleAddLink = () => {
    setIsOpen((prev) => !prev);
  };

  const handleLinkSave = (linkData) => {
    console.log("Received link data:", linkData);
    setLinkValue(linkData);
    setIsOpen(false);
  };

  const renderButton = () => {
    if (user) {
      console.log(linksUser.length, userconnected);

      if (
        linksUser.length !== 0 &&
        userconnected == false &&
        linksUser.some((link) => link["username"] == user.username)
      ) {
        if (
          linksUser.some(
            (link) =>
              link["status"] === "pending" && link["username"] == user.username
          )
        ) {
          console.log("pending");
          return (
            <div className="profile_container_about_right_linkrequest flex flex-ai-c">
              <div className="profile_container_about_right_linkrequest_text">
                Link request
              </div>
              <img
                className="profile_container_about_right_linkrequest_accept"
                src={btn_link_accept}
                alt=""
                onClick={() => handleAccept()}
              ></img>
              <img
                className="profile_container_about_right_linkrequest_refuse"
                src={btn_link_refuse}
                alt=""
                onClick={() => handleRefuse()}
              ></img>
            </div>
          );
        } else if (
          linksUser.some(
            (link) =>
              link["status"] === "bind" && link["username"] == user.username
          )
        ) {
          console.log("bind");
          return (
            <div className="profile_container_about_right_edit linked">
              Linked
            </div>
          );
        } else if (
          linksUser.some(
            (link) =>
              link["status"] === "sending" && link["username"] == user.username
          )
        ) {
          console.log("sending");
          return (
            <div className="profile_container_about_right_edit linked">
              Link Sent
            </div>
          );
        }
      } else {
        console.log("else");
        return (
          <div
            className="profile_container_about_right_edit"
            onClick={() =>
              dispatch(requestLink(userAuth.username, user.username))
            }
          >
            <img
              draggable="false"
              src={add_link_btn}
              className={"profile_container_about_right_add_link"}
              alt=""
            />
          </div>
        );
      }
    }
  };

  const renderDefaultProfile = () => {
    return (
      <div className="profile_container inline">
        <div className="profile_container_about inline">
          <div className="profile_container_about_left">
            <div className="profile_container_about_left_avatar flex">
              {user.avatar ? (
                <>
                  <img
                    draggable="false"
                    src={user.avatar}
                    className="profile_container_about_left_avatar_img"
                  />
                </>
              ) : (
                <img
                  draggable="false"
                  src={default_avatar}
                  className="profile_container_about_left_avatar_img"
                />
              )}
              {user.verified == 1 && (
                <img
                  draggable="false"
                  src={verified}
                  className="profile_container_about_left_avatar_verified"
                />
              )}
            </div>
            <div className="profile_container_about_left_pseudo">
              @{user.username}
            </div>
          </div>
          <hr className="profile_container_about_hr inline"></hr>
          <div className="profile_container_about_right">
            <div className="profile_container_about_right_title">About me</div>
            {user.about_me ? (
              <div className="profile_container_about_right_description">
                {user.about_me}
              </div>
            ) : (
              <div className="profile_container_about_right_description">
                Tell us something about yourself. Press the edit button.
              </div>
            )}
            <div className="profile_container_about_right_social_title">
              Réseaux
            </div>
            <div className="profile_container_about_right_social inline">
              {user?.link_twitter && (
                <div className="profile_container_about_right_social_logo">
                  <a href={user.link_twitter}>
                    <img
                      draggable="false"
                      src={twitter}
                      className="profile_container_about_right_social_logo_img"
                    />
                  </a>
                </div>
              )}
              {user?.link_fb && (
                <div className="profile_container_about_right_social_logo">
                  <a href={user.link_fb}>
                    <img
                      draggable="false"
                      src={facebook}
                      className="profile_container_about_right_social_logo_img"
                    />
                  </a>
                </div>
              )}
              {user?.link_in && (
                <div className="profile_container_about_right_social_logo">
                  <a href={user.link_in}>
                    <img
                      draggable="false"
                      src={linkedin}
                      className="profile_container_about_right_social_logo_img"
                    />
                  </a>
                </div>
              )}

              {!user.link_twitter && !user.link_fb && !user.link_in && (
                <div className="profile_container_about_right_social_content">
                  Share your social media. Press the edit button.
                </div>
              )}
            </div>
            {userconnected ? (
              <div
                className="profile_container_about_right_edit"
                onClick={() => setProfile(false)}
              >
                <img
                  draggable="false"
                  src={edit}
                  className={"profile_container_about_right_edit"}
                  alt=""
                />
              </div>
            ) : (
              renderButton()
            )}
          </div>
        </div>
        <Activity />
      </div>
    );
  };

  const renderEditProfile = () => {
    return (
      <div className="profile_container inline">
        <div className="profile_container_about inline">
          <div className="profile_container_about_left">
            <div className="profile_container_about_left_avatar flex">
              {user.avatar ? (
                <>
                  {file == null ? (
                    <img
                      draggable="false"
                      src={user.avatar}
                      className="profile_container_about_left_avatar_img"
                    />
                  ) : (
                    <img
                      draggable="false"
                      src={file}
                      className="profile_container_about_left_avatar_img"
                    />
                  )}
                  <div className="avatar_backgroung">
                    <label for="file">
                      <div
                        className="avatar_hover"
                        onClick={() => handleUpdateAvatar()}
                      ></div>
                    </label>
                  </div>
                  <input
                    id="file"
                    type="file"
                    onChange={handleChange}
                    className="avatar_input"
                  />
                </>
              ) : (
                <>
                  {file == null ? (
                    <img
                      draggable="false"
                      src={default_avatar}
                      className="profile_container_about_left_avatar_img"
                    />
                  ) : (
                    <img
                      draggable="false"
                      src={file}
                      className="profile_container_about_left_avatar_img"
                    />
                  )}
                  <div className="avatar_backgroung">
                    <label for="file">
                      <div
                        className="avatar_hover"
                        onClick={() => handleUpdateAvatar()}
                      ></div>
                    </label>
                  </div>
                  <input
                    id="file"
                    type="file"
                    onChange={handleChange}
                    className="avatar_input"
                  />
                </>
              )}
              {user.verified == 1 && (
                <img
                  draggable="false"
                  src={verified}
                  className="profile_container_about_left_avatar_verified"
                />
              )}
            </div>
            <div className="profile_container_about_left_pseudo">
              @{user.username}
            </div>
          </div>
          <hr className="profile_container_about_hr inline"></hr>
          <div className="profile_container_about_right">
            <div className="profile_container_about_right_title">About me</div>
            <textarea
              className="profile_container_about_right_textarea"
              placeholder="Type here"
              onChange={(e) => {
                setInputDescription(e.target.value.trim());
              }}
            >
              {user.about_me}
            </textarea>
            <div className="profile_container_about_right_social_title">
              Réseaux
            </div>
            {user?.link_in || user?.facebook || user?.linkedin ? (
              <div className="profile_container_about_right_social inline">
                {user.link_in && (
                  <div className="profile_container_about_right_social_logo">
                    <a href={user.link_in}>
                      <img
                        draggable="false"
                        src={twitter}
                        className="profile_container_about_right_social_logo_img"
                      />
                    </a>
                  </div>
                )}
                {user.facebook && (
                  <div className="profile_container_about_right_social_logo">
                    <a href={user.facebook}>
                      <img
                        draggable="false"
                        src={facebook}
                        className="profile_container_about_right_social_logo_img"
                      />
                    </a>
                  </div>
                )}
                {user.linkedin && (
                  <div className="profile_container_about_right_social_logo">
                    <a href={user.linkedin}>
                      <img
                        draggable="false"
                        src={linkedin}
                        className="profile_container_about_right_social_logo_img"
                      />
                    </a>
                  </div>
                )}
                <div className="profile_container_about_right_social_add">
                  <img
                    draggable="false"
                    src={add}
                    className="profile_container_about_right_social_add_img"
                    onClick={handleAddLink}
                  />
                </div>
              </div>
            ) : (
              <div className="profile_container_about_right_social inline">
                {linkValue?.twitter && (
                  <div className="profile_container_about_right_social_logo">
                    <a href={linkValue?.twitter}>
                      <img
                        draggable="false"
                        src={twitter}
                        className="profile_container_about_right_social_logo_img"
                      />
                    </a>
                  </div>
                )}
                {linkValue?.facebook && (
                  <div className="profile_container_about_right_social_logo">
                    <a href={linkValue?.facebook}>
                      <img
                        draggable="false"
                        src={facebook}
                        className="profile_container_about_right_social_logo_img"
                      />
                    </a>
                  </div>
                )}
                {linkValue?.linkedin && (
                  <div className="profile_container_about_right_social_logo">
                    <a href={linkValue?.linkedin}>
                      <img
                        draggable="false"
                        src={linkedin}
                        className="profile_container_about_right_social_logo_img"
                      />
                    </a>
                  </div>
                )}

                <div className="profile_container_about_right_social_add">
                  <img
                    draggable="false"
                    src={add}
                    className="profile_container_about_right_social_add_img"
                    onClick={handleAddLink}
                  />
                </div>
              </div>
            )}

            <Modal isOpen={isOpen} title="Add Link" onClose={handleAddLink}>
              <AddLink
                onClose={handleLinkSave}
                dataLink_fb={dataLink_fb}
                dataLink_in={dataLink_in}
                dataLink_twitter={dataLink_twitter}
              />
            </Modal>
            <div
              className="profile_container_about_right_edit"
              onClick={() => handleSave()}
            >
              <img
                draggable="false"
                src={save}
                className="profile_container_about_right_edit_icon"
              />
            </div>
          </div>
        </div>
        <Activity />
      </div>
    );
  };

  return (
    <>
      {!userconnected && (
        <div className="profile_path">
          {user.verified == 0 && (
            <img
              draggable="false"
              src={badge}
              alt=""
              className="profile_path_img"
            />
          )}
          <div className="profile_path_current">Links</div>
          <div className="profile_path_info">
            HOME {">"} <span>Links</span>
            {">"} <span>@{user.username} profile</span>
          </div>
        </div>
      )}
      {userconnected && (
        <div className="profile_path">
          {user.verified == 0 && (
            <img
              draggable="false"
              src={badge}
              alt=""
              className="profile_path_img"
            />
          )}
          <div className="profile_path_current">My profile</div>
          <div className="profile_path_info">
            HOME {">"} <span>My profile</span>
          </div>
        </div>
      )}
      {profile ? renderDefaultProfile() : renderEditProfile()}
    </>
  );
};

export default Dashboard;
