/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import rings from "../../Assets/img/rings.png";
import cart from "../../Assets/vectors/cart.svg";
import pp from "../../Assets/img/pp.png";
import hammer from "../../Assets/vectors/hammer.svg";
import routes from "../../Routes/routes.json";
import ApiService from "../../Utils/ApiService";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import checked from "../../Assets/vectors/Account/checked.svg";
import transfer_dollar from "../../Assets/img/Account/transfer_dollar.svg";
import transfer_euro from "../../Assets/img/Account/transfer_euro.svg";
import bitcoin from "../../Assets/vectors/Account/bitcoin.svg";
import etherum from "../../Assets/vectors/Account/etherum.svg";
import market_btn from "../../Assets/vectors/purchase/market_btn.svg";
import danger from "../../Assets/vectors/purchase/danger.svg";
import CircleProgressBar from "../CircleProgressBar";
import confirm_check from "../../Assets/img/Account/confirm_check.svg";
import { getWallets } from "../../Redux/slices/user";

const Purchase = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [puzzle, setPuzzle] = useState(location.state.puzzle);
  const [pieceOwned, setPieceOwned] = useState(location.state.userPiece);
  const [percentUser, setPercentUser] = useState(location.state.percentUser);

  const islightMode = useSelector((state) => state.darkMode);
  const user = useSelector((state) => state.Auth.user);
  const user_wallets = useSelector((state) => state.User.wallets);
  const [countPuzzle, setCountPuzzles] = useState(puzzle.available_piece);
  const [availablePiece, setAvailablePiece] = useState(puzzle.available_piece);
  const [isBuy, setIsBuy] = useState(location.state.isBuy);
  const [allWalletsObject] = useState(user_wallets);
  const [currentWallet, setCurrentWallet] = useState(user_wallets[0]);
  const [currentWalletChecked, setCurrentWalletChecked] = useState(
    currentWallet.currency
  );
  const [isAllWallet, setIsAllWallet] = useState(false);

  const [isYourPrice, setIsYourPrice] = useState(false);

  const [getPricePrimaryMarket, setGetPricePrimaryMarket] = useState(null);
  const [PriceSecondaryMarket, setPriceSecondaryMarket] = useState(null);
  const [inputValuePrice, setInputValuePrice] = useState("");
  const percentage = 100;
  const [progressBar, setProgressBar] = useState(0);
  const [confirmSuccess, setConfirmSuccess] = useState(false);

  const [currentPriceAllMarket, setCurrentPriceAllMarket] = useState(null);

  const [marketPrice, setMarketPrice] = useState(null);

  const [characters] = useState(
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  );

  const [, setAskPrice] = useState(null);

  useEffect(() => {
    const ask_price_route = `${routes.routes.back_end.user.ask_price.url}`;
    const ask_price_url =
      ask_price_route +
      "/" +
      user.id +
      "/" +
      puzzle.reference +
      "/" +
      countPuzzle +
      "/" +
      currentWallet.currency_code;

    const bid_price_route = `${routes.routes.back_end.user.bid_price.url}`;
    const bid_price_url =
      bid_price_route +
      "/" +
      user.id +
      "/" +
      puzzle.reference +
      "/" +
      countPuzzle +
      "/" +
      currentWallet.currency_code;

    const ask_orderbook_route = `${routes.routes.back_end.user.ask_orderbook.url}`;
    const ask_orderbook_url =
      ask_orderbook_route +
      "/" +
      puzzle.reference +
      "/" +
      currentWallet.currency_code;

    const bid_orderbook_route = `${routes.routes.back_end.user.bid_orderbook.url}`;
    const bid_orderbook_url =
      bid_orderbook_route +
      "/" +
      puzzle.reference +
      "/" +
      currentWallet.currency_code;

    const MarketPriceRoute = `${routes.routes.back_end.user.primary_market_price.url}`;
    const urlPrice =
      MarketPriceRoute +
      user.id +
      "/" +
      puzzle.reference +
      "/" +
      countPuzzle +
      "/" +
      currentWallet.currency_code;
    //currentWallet.currency_code;
    //if (puzzle.market === "primary") {

    if (puzzle.market === "primary") {
      ApiService.fetchApiData(
        urlPrice,
        `${routes.routes.back_end.user.primary_market_price.method}`,
        "transactions"
      )
        .then((result) => {
          setGetPricePrimaryMarket(result);
          let tmpPriceMarket = 0;

          setCurrentPriceAllMarket(result.price);
          setMarketPrice(result.price);
          tmpPriceMarket = result.price;

          console.log("tmpPriceMarket : ", tmpPriceMarket);
        })
        .catch(() => {
          // console.log("error fetch result price : ");
        });
    } else {
      handleDeactiveSell();
      console.log(isBuy);
      if (isBuy) {
        ApiService.fetchApiData(ask_price_url, "GET", "transactions")
          .then((result) => {
            console.log(result);

            setPriceSecondaryMarket(result);
            let tmpPriceMarket = 0;

            setCurrentPriceAllMarket(result.global_price);
            setMarketPrice(result.global_price);
            setAskPrice(result);
            tmpPriceMarket = result.global_price;

            console.log("tmpPriceMarket : ", tmpPriceMarket);
          })
          .catch(() => {});

        ApiService.fetchApiData(ask_orderbook_url, "GET", "transactions")
          .then((result) => {
            console.log(result);
            setAvailablePiece(result.total_ask_quantity);
          })
          .catch(() => {});
      } else {
        ApiService.fetchApiData(bid_price_url, "GET", "transactions")
          .then((result) => {
            console.log(result);

            setPriceSecondaryMarket(result);
            let tmpPriceMarket = 0;

            setCurrentPriceAllMarket(result.global_price);
            setMarketPrice(result.global_price);
            setAskPrice(result.global_price);
            tmpPriceMarket = result.global_price;

            console.log("tmpPriceMarket : ", tmpPriceMarket);
          })
          .catch(() => {});

        ApiService.fetchApiData(bid_orderbook_url, "GET", "transactions")
          .then((result) => {
            console.log(result);
            setAvailablePiece(result.total_bid_quantity);
          })
          .catch(() => {});
        console.log("veeeente");
      }
    }
    if (countPuzzle > availablePiece) {
      handleIsYourPrice();
    }

    //}
  }, [isBuy, countPuzzle, currentWalletChecked]);

  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, 5);
  };

  useEffect(() => {
    if (percentage > 0) updatePercentage();
  }, [percentage]);

  useEffect(() => {
    if (progressBar < percentage) updatePercentage();
  }, [progressBar]);
  const handlePieces = (type) => {
    let input_puzzle = document.getElementById("puzzle_input");
    if (type === "plus") {
      input_puzzle.value = parseInt(1) + parseInt(input_puzzle.value);
      setCountPuzzles(parseInt(input_puzzle.value));
    } else {
      input_puzzle.value = parseInt(input_puzzle.value) - parseInt(1);
      setCountPuzzles(parseInt(input_puzzle.value));
    }
  };
  const handleConfirm = (nb_puzzle) => {
    const createorderPrimaryMarketRoute = `${routes.routes.back_end.user.create_order_primary_market.url}`;
    const method = `${routes.routes.back_end.user.create_order_primary_market.method}`;
    const createOrderLimitRoute = `${routes.routes.back_end.user.create_puzzle_limit_order.url}`;
    const createOrderMarketRoute = `${routes.routes.back_end.user.create_puzzle_market_order.url}`;
    const puzzlesRoute = `${routes.routes.back_end.puzzle.gallery.url}`;
    const methodPuzzle = `${routes.routes.back_end.puzzle.gallery.method}`;
    const getPuzzleRoute = `${routes.routes.back_end.puzzle.puzzle.url}`;
    const methodGetPuzzle = `${routes.routes.back_end.puzzle.puzzle.get_method}`;
    let url = "";

    let data = {};

    data.reference = generateRef(12);
    if (puzzle.market === "primary") {
      url = createorderPrimaryMarketRoute;
      data = { ...getPricePrimaryMarket };
      delete data.status;
      data.reference = generateRef(12);
      data.buyer_currency = currentWallet.currency_code;
      data.quantity = countPuzzle;
      data.type = "buy";
    } else {
      if (isYourPrice) {
        url = createOrderLimitRoute;
        data.currency = currentWallet.currency_code;
        data.user_id = user.id;
        data.reference = generateRef(12);

        data.puzzle_reference = puzzle.reference;

        data.price = currentPriceAllMarket;
        data.remaining_quantity = countPuzzle;
        data.total_quantity = countPuzzle;
        data.status = "confirmed";
      } else {
        url = createOrderMarketRoute;

        data = PriceSecondaryMarket;
        data.reference = generateRef(12);
      }

      if (isBuy) data.type = "buy";
      else {
        data.type = "sell";
      }
    }

    console.log("data in handleConfirm and url : ", data, " url : ", url);

    ApiService.sendingPutData(url, method, data, "transactions").then(
      (result) => {
        console.log("result after : ", result);
        dispatch(getWallets(user.id));
        setConfirmSuccess(!confirmSuccess);

        //TODO : Fetching user data and puzzle

        ApiService.fetchApiData(
          puzzlesRoute + puzzle.reference + "/puzzle",
          methodPuzzle,
          "puzzle"
        ).then((result) => {
          console.log("result checking user data : ", result);
          result.map((value) => {
            if (value.user_id === user.id) {
              setPieceOwned(value.piece);
              setPercentUser(value.percent);
            }
          });
        });

        ApiService.fetchApiData(
          getPuzzleRoute + puzzle.reference,
          methodGetPuzzle,
          "puzzle"
        ).then((result) => {
          console.log("new puzzle : ", result);
          setPuzzle(result);
        });
      }
    );
  };

  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
  };

  const renderCurrentImg = (wallet, classImg, id) => {
    let imgToDisplay = transfer_dollar;
    if (wallet.currency === "Bitcoin") {
      imgToDisplay = bitcoin;
    } else if (wallet.currency === "Dollar") {
      imgToDisplay = transfer_dollar;
    } else if (wallet.currency === "Etherum") {
      imgToDisplay = etherum;
    } else if (wallet.currency === "Euro") {
      imgToDisplay = transfer_euro;
    }
    return <img src={imgToDisplay} alt="" id={id} className={classImg} />;
  };

  const handleWalletSelected = (money) => {
    // TODO  : calculate price with rate

    setCurrentWalletChecked(money);
    {
      allWalletsObject.map((value, key) => {
        if (value.currency === money) {
          setCurrentWallet(value);
        }
      });
    }
    setIsAllWallet(false);
  };

  const handleIsYourPrice = () => {
    if (puzzle.market === "secondary") {
      setIsYourPrice(true);
      setCurrentPriceAllMarket(inputValuePrice);
    }
  };

  const handleMarketPrice = () => {
    setIsYourPrice(false);
  };
  const isWalletValid = (money) => {
    if (currentWalletChecked === money) {
      return (
        <img
          src={checked}
          alt="normal_down"
          className="wallet_content_puzzle-currency_select_default_down"
        />
      );
    } else {
      return (
        <img
          src={checked}
          alt="normal_down"
          className="wallet_content_puzzle-currency_select_default_down not_visible"
        />
      );
    }
  };

  const generateRef = (length) => {
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleInput = (e) => {
    setInputValuePrice(e.target.value);
    if (e.target.value != "") {
      setCurrentPriceAllMarket(parseFloat(e.target.value));
    }
  };
  const handleBuy = () => {
    setIsBuy(true);
  };

  const handleActiveSell = () => {
    console.log(pieceOwned);
    if (puzzle.market === "secondary" && pieceOwned > 0) {
      setIsBuy(false);
    }
  };

  const handleDeactiveSell = () => {
    console.log(pieceOwned);
    if (pieceOwned == 0) {
      setIsBuy(true);
    }
  };

  return (
    <div className="container_purchase">
      <div
        className={`details_path  ${
          islightMode == false ? "light-details_path" : "color_white"
        }`}
      >
        HOME {">"} PUZZLES {">"} MONTRE DE LUXE {">"} <span>PURCHASE </span>
      </div>
      <div className="flex purchase">
        <div className="flex first">
          <div
            className={`transaction  ${
              islightMode == false
                ? "background_item_light"
                : "background_item_dark"
            }`}
          >
            <div className="flex transaction_container_first">
              <span className="flex transaction_text to_all">Transaction</span>
              <div className="transaction_buy-sell flex">
                <div
                  className={`transaction_buy-sell_sell  ${
                    isBuy ? "transaction_buy-sell_content" : "content_hover"
                  }`}
                  onClick={() => handleBuy()}
                >
                  <span>Buy</span>
                </div>
                <div
                  className={`transaction_buy-sell_sell  ${
                    !isBuy ? "transaction_buy-sell_content" : "content_hover"
                  }`}
                  onClick={() => handleActiveSell()}
                >
                  <span>Sell</span>
                </div>
              </div>
            </div>
            <div
              className={`line_transaction  ${
                islightMode == false ? "light_line" : "dark_line"
              }`}
            ></div>
            <div className="wallet_content_puzzle-currency to_all">
              <span
                className={`puzzle_number font  ${
                  islightMode == false ? "light_text_color_puzzle" : ""
                }`}
              >
                Currency
              </span>
              <div
                className={`flex flex-fd-c  wallet_content_puzzle-currency_select  ${
                  isAllWallet ? "wallet_content_puzzle-currency_select_all" : ""
                }`}
              >
                <div
                  className="wallet_content_puzzle-currency_select_default wallet_content_puzzle-currency_select_first-item flex flex-js-fs"
                  onClick={() => setIsAllWallet(!isAllWallet)}
                >
                  {renderCurrentImg(
                    currentWallet,
                    "wallet_content_puzzle-currency_select_default_icon"
                  )}
                  <span className="wallet_content_puzzle-currency_select_default_text">
                    {currentWallet.currency}
                  </span>
                </div>
                {isAllWallet && (
                  <>
                    {" "}
                    {allWalletsObject.map((value, key) => {
                      return (
                        <div>
                          <div
                            className="wallet_content_puzzle-currency_select_default wallet_content_puzzle-currency_select_second-content flex flex-js-fs"
                            onClick={() => handleWalletSelected(value.currency)}
                          >
                            {renderCurrentImg(
                              value,
                              "wallet_content_puzzle-currency_select_default_icon"
                            )}
                            <span className="wallet_content_puzzle-currency_select_default_text">
                              {value.currency}
                            </span>
                            {isWalletValid(value.currency)}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div className="wallet_content_puzzle to_all">
              <span
                className={`puzzle_number font  ${
                  islightMode == false ? "light_text_color_puzzle" : ""
                }`}
              >
                Price per piece
              </span>
              <div className="flex wallet_content_puzzle_market">
                <div
                  onClick={() => handleMarketPrice()}
                  className={
                    isYourPrice
                      ? "wallet_content_puzzle_market_first flex flex-fd-c"
                      : "wallet_content_puzzle_market_back flex flex-fd-c"
                  }
                >
                  <span className="wallet_content_puzzle_market_first_text">
                    Market price
                  </span>
                  <span className="wallet_content_puzzle_market_first_text">
                    {/*
                      currentPriceAllMarket &&
                parseFloat(currentPriceAllMarket).toFixed(4)*/}
                    {marketPrice && parseFloat(marketPrice).toFixed(4)}
                  </span>
                </div>
                <div
                  onClick={() => handleIsYourPrice()}
                  className={
                    isYourPrice
                      ? "wallet_content_puzzle_market_back flex flex-fd-c"
                      : "wallet_content_puzzle_market_first flex flex-fd-c"
                  }
                >
                  <span
                    className={
                      isYourPrice
                        ? "wallet_content_puzzle_market_first_text"
                        : "wallet_content_puzzle_market_first_text-inactive"
                    }
                  >
                    Your price
                  </span>
                  {isYourPrice ? (
                    <input
                      type="number"
                      id="input_your_price"
                      className="wallet_content_puzzle_market_first_text-inactive wallet_content_puzzle_market_first_second"
                      placeholder="Get your price"
                      onChange={(e) => handleInput(e)}
                    ></input>
                  ) : (
                    <input
                      type="number"
                      className="wallet_content_puzzle_market_first_text-inactive wallet_content_puzzle_market_first_second"
                      placeholder="Get your price"
                      //defaultValue={parseFloat(puzzle.initial_price).toFixed(2)}
                      disabled="disabled"
                    ></input>
                  )}
                </div>
              </div>
            </div>
            <div className="nb_puzzle_content to_all">
              <span
                className={`puzzle_number font  ${
                  islightMode == false ? "light_text_color_puzzle" : ""
                }`}
              >
                Number of puzzle pieces
              </span>
              {puzzle.market === "primary" ? (
                <>
                  {countPuzzle > puzzle.available_piece && (
                    <div className="flex nb_puzzle_content_error">
                      <img src={danger} alt="danger" />
                      <div className="flex flex-fd-c">
                        <span className="nb_puzzle_content_error_text">
                          Attention only {puzzle.available_piece} pieces are
                          available if
                        </span>
                        <span className="nb_puzzle_content_error_text">
                          you want more you can make an offer
                        </span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {countPuzzle > availablePiece && (
                    <div className="flex nb_puzzle_content_error">
                      <img src={danger} alt="danger" />
                      <div className="flex flex-fd-c">
                        <span className="nb_puzzle_content_error_text">
                          Attenetion only {availablePiece} pieces are available
                          if
                        </span>
                        <span className="nb_puzzle_content_error_text">
                          you want more you can make an offer
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="container_puzzle_count">
                <div className="puzlles_count">
                  <button
                    className="btn_less btn "
                    onClick={() => handlePieces("minus")}
                  ></button>
                  <div className="container_number_counted">
                    <input
                      id="puzzle_input"
                      type="number"
                      defaultValue={countPuzzle}
                      onChange={(e) => setCountPuzzles(e.target.value)}
                      className="number_couted "
                    ></input>
                  </div>
                  <button
                    className="btn_plus btn font"
                    onClick={() => handlePieces("plus")}
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex billing_bloc">
            <div
              className={`billing  ${
                islightMode == false
                  ? "background_item_light"
                  : "background_item_dark"
              }`}
            >
              <div className="billing_content">
                <span className="stat_title ">billing</span>
                <div className="billing_first">
                  <img
                    draggable="false"
                    src={pp}
                    alt="pp"
                    className="billing_first_img"
                  />
                  <div className="billing_first_text">
                    <span
                      className={`billing_first_text_name  ${
                        islightMode == false ? "light_text_name" : ""
                      }`}
                    >
                      buyeur
                    </span>
                    <span
                      className={`billing_first_text_last_name  ${
                        islightMode == false ? "light_text_name" : ""
                      }`}
                    >
                      antony
                    </span>
                  </div>
                </div>

                <div
                  className={`line  ${
                    islightMode == false ? "light_line" : "dark_line"
                  }`}
                ></div>
                <div className="item_stats">
                  <span
                    className={`item_order_first  ${
                      islightMode == false ? "light_text_item_order_first" : ""
                    }`}
                  >
                    Adress
                  </span>
                  <span
                    className={`item_order_second text_position_right  ${
                      islightMode ? "light_text_item_order_second" : ""
                    }`}
                  >
                    46 tichef
                  </span>
                </div>
                <div className="item_stats espace_billing_two">
                  <span
                    className={`item_order_first  ${
                      islightMode == false ? "light_text_item_order_first" : ""
                    }`}
                  >
                    Town
                  </span>
                  <span
                    className={`item_order_second text_position_right  ${
                      islightMode == false ? "light_text_item_order_second" : ""
                    }`}
                  >
                    London
                  </span>
                </div>
                <div className="flex container_btn_billing">
                  <button className="btn_billing">
                    <img
                      draggable="false"
                      src={hammer}
                      alt="cart"
                      className=""
                    />
                    <span className="puzzle_title_btn">Puzzle title</span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`stats  ${
                islightMode == false
                  ? "background_item_light"
                  : "background_item_dark"
              }`}
            >
              <div className="stats_content">
                <span className="stat_title ">PUZZLE STATS</span>
                <div className="item_stats espace_stat_firsts">
                  <span
                    className={`item_order_first  ${
                      islightMode ? "light_text_item_order_first" : ""
                    }`}
                  >
                    piece owned
                  </span>
                  <span
                    className={`item_order_second text_position_right  ${
                      islightMode == false ? "light_text_item_order_second" : ""
                    }`}
                  >
                    {pieceOwned}/{puzzle.total_piece}
                  </span>
                </div>
                <div className="item_stats espace_stats">
                  <span
                    className={`item_order_first  ${
                      islightMode ? "light_text_item_order_first" : ""
                    }`}
                  >
                    After
                  </span>
                  <span
                    className={`item_order_second text_position_right  ${
                      islightMode == false ? "light_text_item_order_second" : ""
                    }`}
                  >
                    {puzzle.available_piece}/{puzzle.total_piece}
                  </span>
                </div>
                <div
                  className={`line  ${
                    islightMode == false ? "light_line" : "dark_line"
                  }`}
                ></div>
                <div className="item_stats">
                  <span
                    className={`item_order_first  ${
                      islightMode == false ? "light_text_item_order_first" : ""
                    }`}
                  >
                    Piece available
                  </span>
                  <span
                    className={`item_order_second text_position_right  ${
                      islightMode == false ? "light_text_item_order_second" : ""
                    }`}
                  >
                    {puzzle.available_piece}/{puzzle.total_piece}
                  </span>
                </div>
                <div
                  className={`line  ${
                    islightMode == false ? "light_line" : "dark_line"
                  }`}
                ></div>
                <div className="item_stats">
                  <span
                    className={`my_part font  ${
                      islightMode == false ? "dark-black-color" : ""
                    }`}
                  >
                    My Part after
                  </span>
                  <span
                    className={`percent_part font  ${
                      islightMode == false ? "dark-black-color" : ""
                    }`}
                  >
                    {percentUser}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`order_purchase  ${
            islightMode == false
              ? "background_item_light"
              : "background_item_dark"
          }`}
        >
          <div className="content_order_all">
            <span className="flex transaction_text">YOUR Order</span>
            <div
              className={`line  ${
                islightMode == false ? "light_line" : "dark_line"
              }`}
            ></div>
            <div className="watch_content">
              {puzzle.images.length > 0 ? (
                <img
                  draggable="false"
                  src={puzzle.images[0]}
                  alt="first_puzzle_image"
                  className="watch_content_img"
                />
              ) : (
                <img
                  draggable="false"
                  src={rings}
                  alt="classic_watch"
                  className="watch_content_img"
                />
              )}

              <div className="watch_all_text">
                <span className="watch_all_text_first watch_text">
                  {puzzle.class}
                </span>
                <span className="watch_all_text_second watch_text">
                  {puzzle.title}
                </span>
              </div>
            </div>
            <div
              className={`line  ${
                islightMode == false ? "light_line" : "dark_line"
              }`}
            ></div>
            <div className="flex">
              <img src={market_btn} alt="market_btn" />
            </div>
            <div className="content_three">
              <div className="item_order">
                <span
                  className={`item_order_first  ${
                    islightMode == false ? "light_text_item_order_first" : ""
                  }`}
                >
                  Total pieces
                </span>
                <span
                  className={`item_order_second  ${
                    islightMode == false ? "light_text_item_order_second" : ""
                  }`}
                  style={{ letterSpacing: "0.1em" }}
                >
                  {countPuzzle ? Number(countPuzzle).toLocaleString() : ""}
                </span>
              </div>
              <div className="item_order espace">
                <span
                  className={`item_order_first  ${
                    islightMode == false ? "light_text_item_order_first" : ""
                  }`}
                >
                  piece price
                </span>
                <span
                  className={`item_order_second  ${
                    islightMode == false ? "light_text_item_order_second" : ""
                  }`}
                >
                  {currentWallet.currency_code === "USD" && <span>$</span>}
                  {currentWallet.currency_code === "ETH" && <span>ETH</span>}
                  {currentWallet.currency_code === "EUR" && <span>*</span>}
                  {currentWallet.currency_code === "BTC" && <span>*</span>}
                  &nbsp;
                  {puzzle.market === "primary" &&
                    currentPriceAllMarket &&
                    parseFloat(currentPriceAllMarket).toFixed(2)}
                  {puzzle.market === "secondary" &&
                    (isYourPrice
                      ? parseFloat(currentPriceAllMarket).toFixed(2)
                      : parseFloat(marketPrice).toFixed(2))}
                </span>
              </div>
            </div>
            <div
              className={`line  ${
                islightMode == false ? "light_line" : "dark_line"
              }`}
            ></div>
            <div className="content_four">
              <span
                className={`total font  ${
                  islightMode == false ? "light_total" : ""
                }`}
              >
                TOTAL
              </span>
              <span
                className={`total_value font  ${
                  islightMode == false ? "dark-black-color" : ""
                }`}
              >
                {parseFloat(currentPriceAllMarket * countPuzzle).toFixed(2)}
              </span>
            </div>
            <div className="container_btn">
              <button
                className="btn_confirmed"
                onClick={() => handleConfirm(countPuzzle)}
              >
                <div className="btn_confirmed_content">
                  <img
                    draggable="false"
                    src={cart}
                    alt="cart"
                    className="img_confirmed"
                  />
                  <span className="text_confirmed font">Confirm Order</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {confirmSuccess && (
        <div className="flex">
          <div className="exchange_popup flex flex-fd-c flex-js-fs">
            <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c">
              {" "}
              Order Confirmed
            </div>
          </div>
          <div id="overlay" onClick={() => handleSuccess()}></div>
        </div>
      )}
      {console.log("isYourPrice  : ", isYourPrice)}
    </div>
  );
};

export default Purchase;
