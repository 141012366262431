import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import Contact from "../Contact";
import Modal from "../Modal";
import iconic from "../../../Assets/img/Iconic.png";
import ButtonFooter from "./ButtonFooter";

function Footer() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className="Footer">
      <div className="new">
        <div className="new-wrapper">
          <div className="iconic"></div>
          <Link to="/about">
            <ButtonFooter className="about" text="About" />
          </Link>

          <Modal isOpen={isOpen} title="Contact us" onClose={onClose}>
            <Contact />
          </Modal>
          <span onClick={handleClick}>
            <ButtonFooter className="contact" text="Contact" />
          </span>

          <Link to="/policy">
            <ButtonFooter className="policy" text="Privacy policy" />
          </Link>

          <Link to="/term-of-use">
            <ButtonFooter className="term" text="Terms of use" />
          </Link>

          <Link to="/faq">
            <ButtonFooter className="faq" text="FAQ" />
          </Link>
        </div>

        <span className="copyright">Copyright &copy; 2022 Iconic</span>
      </div>
    </div>
  );
}

export default Footer;
