import { useLocation, Link } from "react-router-dom";
import line from "../../Assets/vectors/Account/line.svg";
import "./transferin.scss";
import transfer_arrow from "../../Assets/vectors/Account/transfer_arrow.svg";
import checked from "../../Assets/vectors/Account/checked.svg";
import transfer_shield from "../../Assets/img/Account/transfer_shield.svg";
import transfer_send from "../../Assets/vectors/Account/transfer_send.svg";
import transfer_dollar from "../../Assets/img/Account/transfer_dollar.svg";
import transfer_euro from "../../Assets/img/Account/transfer_euro.svg";
import bitcoin from "../../Assets/vectors/Account/bitcoin.svg";
import etherum from "../../Assets/vectors/Account/etherum.svg";
import transfer_account from "../../Assets/img/Account/transfer_account.svg";
import React, { useEffect, useState } from "react";
import transaction_success from "../../Assets/img/Account/transaction_success.svg";
import transfer_expand from "../../Assets/img/Account/select_button.svg";
import transfer_expand_active from "../../Assets/img/Account/select_button_active.svg";
import add_money from "../../Assets/img/Account/add_money.svg";
import transferin_separation from "../../Assets/vectors/Account/transferin_separation.svg";
import add_button from "../../Assets/img/Account/transferin_add_button.svg";
import purple_line from "../../Assets/vectors/Account/purple_line.svg";
import big_purple_line from "../../Assets/vectors/Account/big_purple_line.svg";
import select_arrow from "../../Assets/vectors/Account/select_arrow.svg";
import select_arrow_active from "../../Assets/vectors/Account/select_arrow_active.svg";
import card_img from "../../Assets/vectors/Account/card_img.svg";
import btn_change_card from "../../Assets/vectors/Account/btn_change_card.svg";
import img_devise_wallet from "../../Assets/vectors/Account/img_devise_wallet.svg";
import down from "../../Assets/vectors/Account/down.svg";
import down_white from "../../Assets/vectors/Account/down_white.svg";
import { useSelector, useDispatch } from "react-redux";
import Select, { StylesConfig } from "react-select";
import config from "../../Config/config";
import routes from "../../Routes/routes.json";
import ApiService from "../../Utils/ApiService";
import CircleProgressBar from "../CircleProgressBar";
import confirm_check from "../../Assets/img/Account/confirm_check.svg";
import { getWallets, getLink, getUser } from "../../Redux/slices/user";
import transferin_exit_btn from "../../Assets/vectors/Account/transferin_exit_btn.svg";
import transferin_add_card from "../../Assets/vectors/Account/transferin_add_card.svg";
import transferin_crypto_wallet from "../../Assets/vectors/Account/transferin_crypto_wallet.svg";
import transferin_bank_transfer from "../../Assets/vectors/Account/transferin_bank_transfer.svg";
import transferin_return_arrow from "../../Assets/vectors/Account/transferin_return_arrow.svg";
import transferin_card_img from "../../Assets/img/Account/transferin_card_img.svg";
import transferin_qr_code from "../../Assets/img/Account/transferin_qr_code.svg";
import transferin_btn_copy from "../../Assets/img/Account/transferin_btn_copy.svg";

const Transferin = () => {
  const location = useLocation();
  console.log(location)
  const dispatch = useDispatch();
  const [currentWallet, setCurrentWallet] = useState(location.state);
  const user = useSelector((state) => state.Auth.user);
  const user_wallets = useSelector((state) => state.User.wallets);
  const [currencyActive, setCurrencyActive] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmActive, setConfirmActive] = useState(false);
  const [addWalletActive, setAddWalletActive] = useState(false);
  const [isAddWallet, setIsAddWallet] = useState(true);
  const [monthsActive, setMonthsActive] = useState(false);
  const [yearsActive, setYearsActive] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("01");
  const [selectedYear, setSelectedYear] = useState("2022");
  const [allWalletsObject, setAllWalletsObject] = useState(user_wallets);
  const [currentWalletChecked, setCurrentWalletChecked] = useState(
    currentWallet.currency
  );
  const [valueInputToTransfer, setValueInputToTransfer] = useState(0);
  const [cardAdded, setCardAdded] = useState(null);
  const [allCard, setAllCard] = useState();
  const [walletAdded, setWalletAdded] = useState(false);
  const [valueInputNumber, setValueInputNumber] = useState("");
  const [valueInputCsv, setValueInputCsv] = useState("");
  const [walletName, setWalletName] = useState("");
  const [walletAdress, setWalletAdress] = useState("");
  const [isCardCurrentAdded, setIsCardCurrentAdded] = useState(false);
  const [isSaveCard, setIsSaveCard] = useState(false);
  const percentage = 100;
  const [progressBar, setProgressBar] = useState(0);
  const [isSelectMethod, setIsSelectMethod] = useState(false);
  const [isCryptoWallet, setIsCryptoWallet] = useState(false);



  const handleCard = () => {
    setIsCardCurrentAdded(true);
    setIsCryptoWallet(false);
    setAddWalletActive(false);
    setIsSelectMethod(false);
  };
  const handleCryptoWallet = () => {
    setIsCryptoWallet(!isCryptoWallet);
    setAddWalletActive(false);
    setIsSelectMethod(false);
    setIsCardCurrentAdded(false);
  };
  const handleSelectMethod = () => {
    setIsSelectMethod(!isSelectMethod);
    setAddWalletActive(false);
  };
  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, 5);
  };

  useEffect(() => {
    if (percentage > 0) updatePercentage();
  }, [percentage]);

  useEffect(() => {
    if (progressBar < percentage) updatePercentage();
  }, [progressBar]);

  console.log("test" , location);

  const endtransaction = () => {
    setConfirmSuccess(!confirmSuccess);
    setConfirmActive(false);
  };

  const fetchData = (fullUrl, methodRoute) => {
    return fetch(fullUrl, {
      method: methodRoute,
    }).then((response) => response.json());
  };

  /*useEffect(() => {
    const walletsRoute = `${routes.routes.back_end.user.account.get_wallets.url}`;
    const walletsFullUrl = config.getBackEndUrl() + walletsRoute;
    fetchData(
      walletsFullUrl + "/" + user.id,
      `${routes.routes.back_end.user.account.get_wallets.method}`
    ).then((result) => {
      console.log("result : ,,", result);
      setAllWalletsObject(result);
    });
  }, []);*/

  const selectMonth = (month) => {
    setSelectedMonth(month);
    setMonthsActive(!monthsActive);
  };

  const selectYear = (year) => {
    setSelectedYear(year);
    setYearsActive(!yearsActive);
  };

  const handleYears = () => {
    setYearsActive(!yearsActive);
  };
  const handleMonths = () => {
    setMonthsActive(!monthsActive);
  };
  const handleAddWallet = () => {
    setAddWalletActive(!addWalletActive);
    setIsSelectMethod(false);
    setIsAddWallet(true);
  };

  const firstOption = () => {
    setIsAddWallet(true);
  };

  const secondOption = () => {
    setIsAddWallet(false);
  };

  const handleConfirm = () => {
    setConfirmActive(!confirmActive);
  };

  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
    setConfirmActive(false);
  };
  const handleConfirmSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
    setConfirmActive(false);

    /*const updateWalletRoute =
      routes.routes.back_end.user.account.updateWallets.url +
      "/" +
      currentWallet.name;

    const method = `${routes.routes.back_end.user.account.updateWallets.method}`;
    const total =
      parseFloat(currentWallet.amount) + parseFloat(valueInputToTransfer);

    ApiService.sendingPutData(
      updateWalletRoute,
      method,
      {
        amount: total,
      },
      "transactions"
    ).then((result) => {
      console.log("update wallet : ", result);
      dispatch(getWallets(user.id));
    });*/

    const updateWalletRoute =
      routes.routes.back_end.user.account.transfert_in.url + currentWallet.id;
    const method = `${routes.routes.back_end.user.account.transfert_in.method}`;

    ApiService.sendingPutData(
      updateWalletRoute,
      method,
      {
        amount: parseFloat(valueInputToTransfer),
      },
      "transactions"
    ).then((result) => {
      console.log("update wallet : ", result);
      setCurrentWallet(result);
      dispatch(getWallets(user.id));
    });

    /*let tmpCurrentWallet = {};
    tmpCurrentWallet = currentWallet;
    tmpCurrentWallet.amount =
      parseFloat(currentWallet.amount) + parseFloat(valueInputToTransfer);
    setCurrentWallet(tmpCurrentWallet);*/
    setProgressBar(0);
  };

  const handleCurrency = () => {
    let expand = document.getElementById("transferfromexpand");
    setCurrencyActive(!currencyActive);

    if (currencyActive) {
      expand.src = transfer_expand;
    } else {
      expand.src = transfer_expand_active;
    }
  };

  const handleWalletSelected = (money) => {
    setCurrentWalletChecked(money);
    {
      allWalletsObject.map((value, key) => {
        if (value.currency === money) {
          setCurrentWallet(value);
        }
      });
    }
  };

  const isWalletValid = (money) => {
    if (currentWalletChecked === money) {
      return (
        <img
          src={checked}
          alt=""
          id="euro-img"
          className="transferout_from_choice_select_option_checked checked_margin"
        />
      );
    } else {
      return (
        <img
          src={checked}
          alt=""
          id="euro-img"
          className="transferout_from_choice_select_option_checked checked_margin not_visible"
        />
      );
    }
  };

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const years = [
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
  ];

  const handleTransfer = (e) => {
    setValueInputToTransfer(e.target.value);
  };

  const renderCurrentImg = (wallet, classImg, id) => {
    let imgToDisplay = transfer_dollar;
    if (wallet.currency === "Bitcoin") {
      imgToDisplay = bitcoin;
    } else if (wallet.currency === "Dollar") {
      imgToDisplay = transfer_dollar;
    } else if (wallet.currency === "Etherum") {
      imgToDisplay = etherum;
    } else if (wallet.currency === "Euro") {
      imgToDisplay = transfer_euro;
    }
    return <img src={imgToDisplay} alt="" id={id} className={classImg} />;
  };

  const handleNumberCard = (e) => {
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    setValueInputNumber(e.target.value);
  };
  const handleCsvCard = (e) => {
    setValueInputCsv(e.target.value.trim());
  };
  const handleAddCard = (isCard) => {
    if (isCard) {
      if (valueInputNumber.length != 19 || valueInputCsv.length != 3) {
        return;
      }
      let cardElementTmp = {};
      cardElementTmp.number = valueInputNumber;
      cardElementTmp.csv = valueInputCsv;
      cardElementTmp.mounth = selectedMonth;
      cardElementTmp.year = selectedYear;

      if (isSaveCard) {
        let allCardtmp = allCard;
        allCardtmp.push(cardElementTmp);
        setAllCard(allCardtmp);
      }

      setCardAdded(cardElementTmp);
      setAddWalletActive(!addWalletActive);
      setIsAddWallet(true);
      setIsCardCurrentAdded(true);
      setIsSaveCard(false);
      setIsCryptoWallet(false);
    } else {
      let walletElementTmp = {};
      walletElementTmp.name = walletName;
      walletElementTmp.adress = walletAdress;
      setWalletAdded(walletElementTmp);
      setAddWalletActive(!addWalletActive);
      setIsAddWallet(true);
      setIsCardCurrentAdded(false);
    }
  };

  const renderInput = () => {
    return (
      <input
        className="transferin_wallet_input"
        defaultValue={valueInputToTransfer}
        min={0}
        type="number"
        onChange={(e) => handleTransfer(e)}
      ></input>
    );
  };

  const renderDefaultFrom = () => {
    return (
      <div className="transferin_wallet flex flex-ai-c flex-js-fs">
        <div
          className="transferin_wallet_add flex flex-ai-c flex-js-fs"
          onClick={() => handleSelectMethod()}
        >
          <img
            src={add_button}
            alt=""
            className="transferin_wallet_add_img"
          ></img>
          <div className="transferin_wallet_add_text">
            Select card or other method
          </div>
        </div>
      </div>
    );
  };

  const renderCardAdded = () => {
    return (
      <div className="transferin_wallet flex flex-ai-c flex-js-fs">
        <div
          className="transferin_wallet_card flex flex-ai-c flex-js-fs"
          onClick={() => handleSelectMethod()}
        >
          <img
            src={card_img}
            alt=""
            className="transferin_wallet_add_img"
          ></img>
          <div className="flex flex-fd-c">
            <div className="transferin_wallet_card_text">Mastercard</div>
            <div className="transferin_wallet_card_text">
              **** **** ****{" "}
              {cardAdded.number.substr(
                cardAdded.number.length - 4,
                cardAdded.number.length - 1
              )}
            </div>
          </div>
          <img
            src={btn_change_card}
            alt=""
            className="transferin_wallet_card_change"
          ></img>
        </div>
      </div>
    );
  };

  const renderWalletAdded = () => {
    return (
      <div className="transferin_wallet flex flex-ai-c flex-js-fs">
        <div className="transferin_wallet_crypto flex flex-ai-c flex-js-fs">
          <img
            src={img_devise_wallet}
            alt=""
            className="transferin_wallet_add_img"
          ></img>
          Crypto wallet
        </div>

        <img
          src={btn_change_card}
          alt=""
          className="transferin_wallet_crypto_change"
          onClick={() => handleSelectMethod()}
        ></img>
      </div>
    );
  };
  return (
    <div>
      <div className="page_title page_title_transferin">
        <Link to="/transfer_in">Transfer in</Link>
        <div className="register_header_path flex flex-js-fs">
          <Link to="/home">Home</Link>{" "}
          <div className="register_header_path_gt">{">"}</div>{" "}
          <Link to="/account">
            <span>Account</span>
          </Link>{" "}
          <div className="register_header_path_gt">{">"}</div>{" "}
          <span>Transfer in</span>
        </div>
      </div>

      <div className="transferout_transfer flex flex-ai-c flex-fd-c">
        <div className="transferout_from flex flex-fd-c">
          <div>Add money</div>
          <div className="transferout_from_line">
            <img src={line} alt=""></img>
          </div>
          
          {!cardAdded && !isCryptoWallet
            ? renderDefaultFrom()
            : isCardCurrentAdded
            ? renderCardAdded()
            : renderWalletAdded()}
        </div>
        {!isCryptoWallet && (
          <div className="transferin_wallets flex flex-ai-c  to_margin">
            <div className="transferout_from_choice_select new_wallet_size flex flex-fd-c">
              <div
                className="transferout_from_choice_select_default new_wallet_height flex flex-js-fs"
                onClick={() => handleCurrency()}
              >
                <div className="transferout_from_choice_select_text flex flex-fd-c selected_width">
                  <div
                    className="transferout_from_choice_select_text_currency"
                    id="transferfromcurrency"
                  >
                    {currentWallet.currency}
                  </div>
                  <div className="transferout_from_choice_select_text_amount  flex flex-ai-c">
                    <div id="transferfromamount">
                      {parseFloat(currentWallet.amount).toFixed(2)}
                    </div>{" "}
                    <span
                      className="currency_code_margin"
                      id="transferfromcode"
                    >
                      {currentWallet.currency_code}
                    </span>
                  </div>
                </div>
                <img
                  src={transfer_expand}
                  alt=""
                  id="transferfromexpand"
                  className="transferout_from_choice_select_expand_currency expand_margin"
                ></img>
              </div>
              {currencyActive && (
                <div className="dropdown_currency">
                  {allWalletsObject.map((value, key) => {
                    return (
                      <div
                        className="transferout_from_choice_select_option  flex flex-js-fs flex-ai-c"
                        onClick={() => handleWalletSelected(value.currency)}
                      >
                        <div className="transferout_from_choice_select_text flex flex-ai-c flex-js-fs">
                          {renderCurrentImg(
                            value,
                            "transferout_from_choice_select_option_img",
                            "transferfromimg"
                          )}
                          <div className="transferout_from_choice_select_text_currency currency_margin ">
                            {value.currency}
                          </div>
                          <div className="transferout_from_choice_select_text_amount flex flex-ai-c currency_margin">
                            {parseFloat(value.amount).toFixed(2)}
                            <span className="currency_code_margin">
                              {value.currency_code}
                            </span>
                          </div>
                          {isWalletValid(value.currency)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <img src={transferin_separation} alt=""></img>
            <input
              className="transferin_wallet_input"
              min={0}
              type="number"
              onChange={(e) => handleTransfer(e)}
            />
          </div>
        )}

        {isCryptoWallet && (
          <div className="transferin_crypto flex flex-fd-c flex-js-fs">
            <div className="transferin_crypto_header flex flex-ai-c flex-js-sb">
              <div className="transferin_crypto_header_instructions">
                Copy the items to your crypto <br></br>wallet or scan the qr
                code
              </div>
              <img src={transferin_qr_code} alt=""></img>
            </div>

            <div className="transferin_crypto_title">Adress</div>
            <div className="transferin_crypto_content flex flex-ai-c">
              <div className="transferin_crypto_content_container flex flex-ai-c flex-js-fs">
                1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa
              </div>
              <img src={transferin_btn_copy} alt=""></img>
            </div>

            <div className="transferin_crypto_title">Network</div>
            <div className="transferin_crypto_content flex flex-ai-c">
              <div className="transferin_crypto_content_container flex flex-ai-c flex-js-fs">
                ERC20 Etherum (ETH)
              </div>
              <img src={transferin_btn_copy} alt=""></img>
            </div>
          </div>
        )}
      </div>
      {!isCryptoWallet && (
        <div className="transferout_footer no_margin_right flex flex-fd-c">
          <div className="transferout_footer_clause flex flex-ai-c">
            <img
              src={transfer_shield}
              alt=""
              className="transferout_footer_clause_img"
            ></img>
            This transaction is secure by protocole nom
          </div>
          <div
            className="transferout_footer_button bottom_add flex flex-ai-c"
            onClick={() => handleConfirm()}
          >
            <img src={add_money} alt=""></img>
            Add money
          </div>
        </div>
      )}
      {confirmActive && (
        <div className="flex flex-ai-c">
          <div className="transferout_popup flex flex-fd-c flex-js-fs">
            <div className="transferout_popup_header">
              <div>Transfer in</div>
              <div className="transferout_from_line">
                <img src={line} alt=""></img>
              </div>
            </div>
            <div className="transferout_popup_total flex flex-fd-c flex-ai-c">
              <div>
                <img
                  src={transfer_account}
                  alt=""
                  className="transferout_popup_total_img"
                ></img>
                {renderCurrentImg(currentWallet, "", "")}
              </div>
              <div
                className="transferout_popup_total_amount"
                id="transfer_total"
              >
                {valueInputToTransfer}
              </div>
            </div>
            <div className="transferout_popup_buttons flex flex-fd-c flex-ai-c">
              <div className="transferout_popup_buttons_text">
                Do you confirm this transaction ?
              </div>
              <div className="flex">
                <div
                  className="transferout_popup_buttons_cancel flex flex-ai-c"
                  onClick={() => handleConfirm()}
                >
                  Cancel
                </div>
                <div
                  className="transferout_popup_buttons_confirm flex flex-ai-c"
                  onClick={() => handleConfirmSuccess()}
                >
                  I confirm
                </div>
              </div>
            </div>
          </div>
          <div id="overlay" onClick={() => handleConfirm()}></div>
        </div>
      )}
      {confirmSuccess && (
        <div className="flex">
          <div className="transferout_popup flex flex-fd-c flex-js-fs">
            <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c">
              {" "}
              Transaction confirmed !
            </div>
          </div>
          <div id="overlay" onClick={() => endtransaction()}></div>
        </div>
      )}
      {isSelectMethod && (
        <div className="flex flex-ai-c">
          <div className="transferin_popup flex flex-fd-c flex-js-fs">
            <div className="transferin_popup_select_method flex flex-js-sb flex-ai-c">
              Select method
              <img
                src={transferin_exit_btn}
                alt=""
                onClick={() => handleSelectMethod()}
              />
            </div>
            <div className="transferin_popup_card">
              <div className="transferin_popup_card_title flex flex-js-sb">
                <div>Card</div>
                {cardAdded !== null && (
                  <div
                    className="cursor_pointer transferin_popup_card_title_add"
                    onClick={() => handleAddWallet()}
                  >
                    Add new card
                  </div>
                )}
              </div>
              {cardAdded === null && (
                <div
                  className="transferin_popup_card_add flex flex-ai-c flex-js-fs"
                  onClick={() => handleAddWallet()}
                >
                  <img
                    src={transferin_add_card}
                    alt=""
                    className="transferin_popup_card_add_img"
                  />
                  Add card
                </div>
              )}

              {cardAdded !== null && (
                <div
                  className="transferin_popup_card_added flex flex-ai-c flex-js-fs"
                  onClick={() => handleCard()}
                >
                  <img
                    src={transferin_card_img}
                    alt=""
                    className="transferin_popup_card_added_img"
                  />
                  <div className="flex flex-fd-c">
                    <div className="transferin_popup_card_added_title">
                      Mastercard
                    </div>
                    <div className="transferin_popup_card_added_text">
                      **** **** ****{" "}
                      {cardAdded.number.substr(
                        cardAdded.number.length - 4,
                        cardAdded.number.length - 1
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="transferin_popup_other">
              <div className="transferin_popup_other_title">Other</div>
              <div
                className="transferin_popup_card_add flex flex-ai-c flex-js-fs"
                onClick={() => handleCryptoWallet()}
              >
                <img
                  src={transferin_crypto_wallet}
                  alt=""
                  className="transferin_popup_card_add_img"
                />
                Crypto wallet
              </div>
              <div className="transferin_popup_card_add flex flex-ai-c flex-js-fs">
                <img
                  src={transferin_bank_transfer}
                  alt=""
                  className="transferin_popup_card_add_img"
                />
                Bank transfer
              </div>
            </div>
          </div>
          <div id="overlay" onClick={() => handleSelectMethod()}></div>
        </div>
      )}
      {addWalletActive && (
        <div className="flex flex-ai-c">
          <div className="transferin_popup flex flex-fd-c flex-js-fs">
            {isAddWallet ? (
              <div className="transferin_popup_inputs flex flex-fd-c flex-js-se">
                <div className="transferin_popup_select_method flex flex-js-sb flex-ai-c add_card_margin">
                  <div
                    className="flex flex-ai-c cursor_pointer"
                    onClick={() => handleSelectMethod()}
                  >
                    <img src={transferin_return_arrow} alt="" />
                    Add card
                  </div>
                  <img
                    src={transferin_exit_btn}
                    alt=""
                    onClick={() => handleAddWallet()}
                  />
                </div>
                <div className="transferin_popup_inputs_cardnumber flex flex-fd-c">
                  <div className="transferin_popup_inputs_text">
                    Card number
                  </div>
                  <input
                    className="transferin_popup_inputs_cardnumber_input"
                    type="text"
                    placeholder="Enter your card number"
                    id="cardNumber"
                    maxLength={19}
                    onChange={(e) => handleNumberCard(e)}
                    //onKeyDown={(e) => handleNumberCard(e)}
                  ></input>
                </div>
                <div className="flex flex-js-sb">
                  <div className="transferin_popup_inputs_date">
                    <div className="transferin_popup_inputs_text">
                      Expires date
                    </div>
                    <div className="flex">
                      <div className="transferin_popup_inputs_date_month flex flex-fd-c">
                        <div
                          className={
                            monthsActive
                              ? "flex flex-ai-c transferin_popup_inputs_date_text whitegrey"
                              : "flex flex-ai-c transferin_popup_inputs_date_text"
                          }
                          id="1"
                          onClick={() => handleMonths()}
                        >
                          {selectedMonth}
                          <img
                            src={
                              monthsActive ? select_arrow_active : select_arrow
                            }
                            alt=""
                            className="transferin_popup_inputs_date_arrow"
                          ></img>
                        </div>
                        {monthsActive &&
                          months.map((value, index) => {
                            return (
                              <div
                                className="flex flex-ai-c "
                                id={index + 2}
                                onClick={() => selectMonth(value)}
                              >
                                {value}
                              </div>
                            );
                          })}
                      </div>
                      <div className="transferin_popup_inputs_date_year flex flex-fd-c">
                        <div
                          className={
                            yearsActive
                              ? "flex flex-ai-c transferin_popup_inputs_date_text whitegrey"
                              : "flex flex-ai-c transferin_popup_inputs_date_text"
                          }
                          id="2022"
                          onClick={() => handleYears()}
                        >
                          {selectedYear}
                          <img
                            src={
                              yearsActive ? select_arrow_active : select_arrow
                            }
                            alt=""
                            className="transferin_popup_inputs_date_arrow"
                          ></img>
                        </div>
                        {yearsActive &&
                          years.map((value, index) => {
                            return (
                              <div
                                className="flex flex-ai-c"
                                id={index + 2023}
                                onClick={() => selectYear(value)}
                              >
                                {value}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="transferin_popup_inputs_cvc">
                    <div className="transferin_popup_inputs_text">CVC/CVV</div>
                    <input
                      type="text"
                      placeholder="Enter csv"
                      className="transferin_popup_inputs_cvc_input"
                      maxLength={3}
                      onChange={(e) => handleCsvCard(e)}
                    ></input>
                  </div>
                </div>
                <div className="flex">
                  <input
                    type="checkbox"
                    onChange={(e) => setIsSaveCard(e.target.value)}
                  />
                  <div className="transferin_popup_inputs_checkbox">
                    Save this card for the next time
                  </div>
                </div>
              </div>
            ) : (
              <div className="transferin_popup_inputs buttons_adjust  flex flex-fd-c flex-js-se">
                <div className="transferin_popup_inputs_cardnumber flex flex-fd-c">
                  <div className="transferin_popup_inputs_text">
                    Wallet’s name
                  </div>
                  <input
                    className="transferin_popup_inputs_cardnumber_input"
                    type="text"
                    placeholder="Choose a name for your wallet"
                    onChange={(e) => setWalletName(e.target.value)}
                  ></input>
                </div>
                <div className="transferin_popup_inputs_cardnumber  flex flex-fd-c">
                  <div className="transferin_popup_inputs_text">Adress</div>
                  <input
                    className="transferin_popup_inputs_cardnumber_input"
                    type="text"
                    placeholder="Enter wallet adress"
                    onChange={(e) => setWalletAdress(e.target.value.trim())}
                  ></input>
                </div>
              </div>
            )}
            <div className="flex">
              <div
                className="transferout_popup_buttons_cancel flex flex-ai-c"
                onClick={() => handleAddWallet()}
              >
                Cancel
              </div>
              <div
                className="transferout_popup_buttons_confirm flex flex-ai-c"
                onClick={() => handleAddCard(isAddWallet)}
              >
                Add card
              </div>
            </div>
          </div>
          <div id="overlay" onClick={() => handleAddWallet()}></div>
        </div>
      )}
      {console.log("setAllCardTmp : ", allCard)}
    </div>
  );
};
export default Transferin;
