import React from 'react';
import ReactDOM from 'react-dom';
import "./Modal.scss"

function Modal({ children, onClose, isOpen, title }) {
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div className="modal">
      <div className="modal_content">
        <div className="modal_header">
          <div></div>
          <h2 className="modal_header_title"> {title} </h2>
          <button className="modal_header_close" onClick={onClose}>
            <span className="modal_header_close_sr-only">Close</span>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal_body">{children}</div>
      </div>
    </div>,
    document.body
  );
}

export default Modal;