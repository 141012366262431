import React, { useEffect, useState, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PopupMenuNavbar from "./PopupMenuNavbar";
import SearchNavbar from "./SearchNavbar";
import NavbarLinks from "./NavbarLinks";

import iconic from "../../../Assets/img/Iconic.png";
import iconic_light from "../../../Assets/img/Iconic_light.png";

// import connection_logo from "../../Assets/img/register_logo.svg";
// import connection_icon_purple from "../../Assets/vectors/navbar_connection_icon_purple.svg";
// import watch from "../../Assets/img/watch.png";

const Navbar = () => {
  const [isScroolDetected, setIsScroolDetected] = useState(false);
  const [isProfileActive, setIsProfileActive] = useState(false);

  const ref = useRef();

  /*** ** detect scroll */
  const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    useEffect(() => {
      const updatePosition = () => {
        setScrollPosition(window.pageYOffset);
      };
      window.addEventListener("scroll", updatePosition);
      return () => window.removeEventListener("scroll", updatePosition);
    }, []);
    return scrollPosition;
  };
  /****** */
  const scrollPosition = useScrollPosition();
  const islight = useSelector((state) => state.darkMode);


  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  });

  useEffect(() => {
    if (scrollPosition > 100) {
      setIsScroolDetected(true);
    } else {
      setIsScroolDetected(false);
    }
  }, [scrollPosition]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    const checkIfClickedOutsideSearch = (e) => {
      if (isProfileActive && ref.current && !ref.current.contains(e.target)) {
        setIsProfileActive(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideSearch);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsideSearch);
    };
  }, [isProfileActive]);

  return (
    <div
      className={`navbar ${!islight ? "navbar_light" : "navbar_dark"} ${
        isScroolDetected ? "scrool_detected" : "scrool_not_detected"
      }`}
    >
      <div
        className={`flex navbar_content ${
          isScroolDetected
            ? "navbar_scrool_detected"
            : "navbar_scrool_not_detected"
        }`}
      >
        <Link to="/home">
          <div className="navbar_content_logo">
            {!islight ? (
              <img
                draggable="false"
                src={iconic_light}
                alt="logo"
                className="navbar_content_logo_iconic"
              />
            ) : (
              <img
                draggable="false"
                src={iconic}
                alt="logo"
                className="navbar_content_logo_iconic"
              />
            )}
          </div>
        </Link>

        <SearchNavbar Popup={false} />

        { !show ?
          <div className="navbar_burger">
            <div className="navbar_burger_button" onClick={()=>setShow(!show)}>
            </div>
          </div>
        :
          <div className="navbar_burger">
            <div className="navbar_burger_button_close" onClick={()=>setShow(!show)}>
            </div>
          </div>
            }

        <div className="navbar_text inline">
          <NavbarLinks />
        </div>
       
      </div>
      {show ? (
        <div
          className={`navbar_burger_menu ${
            isScroolDetected ? "burger_scroll_detected" : ""
          }`}
        >
          <PopupMenuNavbar />
        </div>
      ) : null}
    </div>
  );
};

export default Navbar;
