import React from 'react';
import './inputSettings.scss'

function InputSettings({
  label,
  name,
  onChange = (e) => {},
  placeholder,
  type = 'text',
}) {
  return (
    <div className="controls" >
      <label htmlFor={name} className="controls_inputSettings_label flex flex-js-fs">
        {label}
      </label>
      {type === 'textarea' ? (
        <textarea
          onChange={onChange}
          name={name}
          id={name}
          cols="20"
          rows="5"
          className="controls_inputSettings_textarea"
          placeholder={placeholder}
          
        />
      ) : (
        <input
          id={name}
          type={type}
          name={name}
          className="controls_inputSettings"
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
    </div>
  );
}

export default InputSettings;