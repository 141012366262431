import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";

import profile from "../../../Assets/vectors/Profile.svg";
import account from "../../../Assets/vectors/account.svg";
import setting from "../../../Assets/vectors/setting.svg";
import puzzle from "../../../Assets/vectors/puzzle.svg";
import connection_icon from "../../../Assets/vectors/navbar_connection_icon.svg";
import logout_icon from "../../../Assets/vectors/logout.svg";
import notify from "../../../Assets/vectors/notify.svg";
import notify_active from "../../../Assets/vectors/notify_active.svg";
import NotifyMobile from "../../../Assets/vectors/navbar_notify_icon.svg";
import PuzzleIcon from "../../../Assets/vectors/navbar_puzzle_icon.svg";
import LinksIcon from "../../../Assets/vectors/navbar_links_icon.svg";
import MenuDown from "../../../Assets/vectors/navbar_profile_menu_down_icon.svg";
import MenuUp from "../../../Assets/vectors/navbar_profile_menu_up_icon.svg";
import AvatarProfile from "../../../Assets/vectors/navbar_profile_icon.svg";
import Notifications from "./Notifications";
import notifications_number from "../../../Assets/vectors/notifications_number.svg";
import { useSelector, useDispatch } from "react-redux";
const NavbarLinks = ({ Popup = false }) => {
  const user = useSelector((state) => state.Auth.user);
  const location = useLocation();
  const [isProfileActive, setIsProfileActive] = useState(false);
  const [isUserConnected, setIsUserConnected] = useState(false);
  const [isNotificationsActive, setIsNotificationsActive] = useState(false);
  const [numberNotifs, setNumberNotifs] = useState(0);
  const ref = useRef();
  const refNotif = useRef();
  const { logout } = useAuth();

  const handleNotifications = () => {
    setIsNotificationsActive(!isNotificationsActive);
    console.log("in handle notif");
  };
  const handledisconnected = () => {
    // TODO  : fetch api send empty data in post
    setIsUserConnected(false);
    const route = `${routes.routes.back_end.iconic_user.logout.url}`;
    const method = `${routes.routes.back_end.iconic_user.logout.method}`;
    ApiService.postWithoutData(route, method, "user").then((result) => {
      console.log("logout result   : ", result);
    });
    logout();
  };

  const fetchNumberNotifs = (number) => {
    setNumberNotifs(number);
  };

  useEffect(() => {
    fetchUserNotification();
  }, []);

  useEffect(() => {
    const checkIfClickedOutsideLogger = (e) => {
      if (isProfileActive && ref.current && !ref.current.contains(e.target)) {
        setIsProfileActive(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideLogger);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsideLogger);
    };
  }, [isProfileActive]);

  useEffect(() => {
    const checkIfClickedOutsideNotif = (e) => {
      if (
        isNotificationsActive &&
        refNotif.current &&
        !refNotif.current.contains(e.target)
      ) {
        setIsNotificationsActive(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideNotif);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsideNotif);
    };
  }, [isNotificationsActive]);

  const fetchUserNotification = () => {
    const route = `${routes.routes.back_end.iconic_user.user_notifications.url}`;
    const method = `${routes.routes.back_end.iconic_user.user_notifications.method}`;

    ApiService.fetchApiData(route + user.id, method, "user").then((result) => {
      let nbrNotifsNotRead = 0;
      result.map((value) => {
        if (value.message_read == false) {
          nbrNotifsNotRead += 1;
        }
      });
      setNumberNotifs(nbrNotifsNotRead);
    });
  };
  const [activePage, setActivePage] = useState("");
  const handlePage = (page) => {
    setActivePage(page);
  };

  return (
    <>
      {!Popup ? (
        <>
          <Link to="/market">
            <div className="navbar_text_content">
              <div
                onClick={() => handlePage("puzzles")}
                className={`navbar_text_content_font ${
                  location.pathname.includes("/market") ? "activeMenu" : ""
                }`}
              >
                Puzzles
              </div>
            </div>
          </Link>
          <Link to="/links">
            <div className="navbar_text_content">
              <div
                onClick={() => handlePage("links")}
                className={`navbar_text_content_font ${
                  location.pathname.includes("/links") ? "activeMenu" : ""
                }`}
              >
                Links
              </div>
            </div>
          </Link>
          <>
            <div
              className="navbar_text_content"
              onClick={() => setIsProfileActive(!isProfileActive)}
            >
              <div
                onClick={() => handlePage("space")}
                lassName={`navbar_text_content_font ${
                  location.pathname.startsWith("/myprofile") ||
                  location.pathname.startsWith("/account") ||
                  location.pathname.startsWith("/settings") ||
                  location.pathname.startsWith("/my_puzzles")
                    ? "activeMenu"
                    : ""
                }`}
              >
                My space
              </div>
            </div>

            {/*isProfileActive && (
              <div className="popup-settings" ref={ref}>
                <div className="popup_content"></div>
              </div>
            )*/}

            {isProfileActive && (
              <div className="popup" ref={ref}>
                <div className="popup_content">
                  <Link to="/myprofile">
                    <div className="flex popup_content_item">
                      <div className="popup_content_item_left flex">
                        <img
                          draggable="false"
                          src={profile}
                          alt="notify"
                          className="popup_content_item_img"
                        />
                      </div>
                      <div className="popup_content_item_right">
                        <span className="popup_content_item_text">Profile</span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/account">
                    <div className="flex popup_content_item">
                      <div className="popup_content_item_left flex">
                        <img
                          draggable="false"
                          src={account}
                          alt="notify"
                          className="popup_content_item_img"
                        />
                      </div>
                      <div className="popup_content_item_right">
                        <span className="popup_content_item_text">Account</span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/settings">
                    <div className="flex popup_content_item">
                      <div className="popup_content_item_left flex">
                        <img
                          draggable="false"
                          src={setting}
                          alt="notify"
                          className="popup_content_item_img"
                        />
                      </div>
                      <div className="popup_content_item_right">
                        <span className="popup_content_item_text">
                          Settings
                        </span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/my_puzzles">
                    <div className="flex popup_content_item">
                      <div className="popup_content_item_left flex">
                        <img
                          draggable="false"
                          src={puzzle}
                          alt="notify"
                          className="popup_content_item_img"
                        />
                      </div>
                      <div className="popup_content_item_right">
                        <span className="popup_content_item_text">
                          My puzzles
                        </span>
                      </div>
                    </div>
                  </Link>

                  <Link to="/">
                    <div
                      className="flex popup_content_item"
                      onClick={() => handledisconnected()}
                    >
                      <div className="popup_content_item_left flex">
                        <img
                          draggable="false"
                          src={logout_icon}
                          alt="notify"
                          className="popup_content_item_img"
                        />
                      </div>
                      <div className="popup_content_item_right">
                        <span className="popup_content_item_text">Log out</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}
            {!isNotificationsActive && numberNotifs !== 0 && (
              <>
                <img
                  src={notifications_number}
                  alt=""
                  className="notifications_number_img"
                />
                <div className="notifications_number flex flex-ai-c">
                  {numberNotifs}
                </div>
              </>
            )}

            <div
              className="navbar_text_notify flex"
              onClick={() => handleNotifications()}
            >
              <img
                draggable="false"
                src={isNotificationsActive ? notify_active : notify}
                alt="notify"
                className=""
              />
            </div>

            {isNotificationsActive && (
              <div ref={refNotif}>
                <Notifications fetchNumberNotifs={fetchNumberNotifs} />
              </div>
            )}
          </>
        </>
      ) : (
        <>
          <Link to="/market">
            <div className="navbar_text_content">
              <img src={PuzzleIcon} />
              <div className="navbar_text_content_font">Puzzles</div>
            </div>
          </Link>
          <Link to="/links">
            <div className="navbar_text_content">
              <img src={LinksIcon} />
              <div className="navbar_text_content_font">Links</div>
            </div>
          </Link>
          <>
            <div
              className="navbar_text_content"
              onClick={() => setIsProfileActive(!isProfileActive)}
            >
              <img src={AvatarProfile} />
              <div className="navbar_text_content_font">My space</div>
              {isProfileActive ? (
                <img src={MenuUp} className="navbar_text_content_icon" />
              ) : (
                <img src={MenuDown} className="navbar_text_content_icon" />
              )}
            </div>

            {isProfileActive && (
              <div className="popup" ref={ref}>
                <div className="popup_content">
                  <Link to="/myprofile">
                    <div className="flex popup_content_item">
                      <div className="popup_content_item_left flex">
                        <img
                          draggable="false"
                          src={profile}
                          alt="notify"
                          className="popup_content_item_img"
                        />
                      </div>
                      <div className="popup_content_item_right">
                        <span className="popup_content_item_text">Profile</span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/account">
                    <div className="flex popup_content_item">
                      <div className="popup_content_item_left flex">
                        <img
                          draggable="false"
                          src={account}
                          alt="notify"
                          className="popup_content_item_img"
                        />
                      </div>
                      <div className="popup_content_item_right">
                        <span className="popup_content_item_text">Account</span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/seetings/privacy">
                    <div className="flex popup_content_item">
                      <div className="popup_content_item_left flex">
                        <img
                          draggable="false"
                          src={setting}
                          alt="notify"
                          className="popup_content_item_img"
                        />
                      </div>
                      <div className="popup_content_item_right">
                        <span className="popup_content_item_text">
                          Settings
                        </span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/my_puzzles">
                    <div className="flex popup_content_item">
                      <div className="popup_content_item_left flex">
                        <img
                          draggable="false"
                          src={puzzle}
                          alt="notify"
                          className="popup_content_item_img"
                        />
                      </div>
                      <div className="popup_content_item_right">
                        <span className="popup_content_item_text">
                          My puzzles
                        </span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/">
                    <div
                      className="flex popup_content_item"
                      onClick={() => handledisconnected()}
                    >
                      <div className="popup_content_item_left flex">
                        <img
                          draggable="false"
                          src={logout_icon}
                          alt="notify"
                          className="popup_content_item_img"
                        />
                      </div>
                      <div className="popup_content_item_right">
                        <span className="popup_content_item_text">Log out</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}

            <Link to="#">
              {!isNotificationsActive && (
                <>
                  <img
                    src={notifications_number}
                    alt=""
                    className="notifications_number_img"
                  />
                  <div className="notifications_number flex flex-ai-c">
                    {numberNotifs}
                  </div>
                </>
              )}
              <div
                className="navbar_text_content"
                onClick={() => handleNotifications()}
              >
                <img
                  draggable="false"
                  src={NotifyMobile}
                  alt="notify"
                  className=""
                />
                <div className="navbar_text_content_font">Notifications</div>
              </div>
              {isNotificationsActive && (
                <div ref={refNotif}>
                  <Notifications fetchNumberNotifs={fetchNumberNotifs} />
                </div>
              )}
            </Link>
          </>
        </>
      )}
    </>
  );
};
export default NavbarLinks;
