import React from "react";
import ApiService from "../Utils/ApiService";
import routes from "../Routes/routes.json";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { Fragment } from "react";
import ImageGallery from "react-image-gallery";

import red_bottle from "../Assets/img/red_bottle.png";
import stars from "../Assets/img/stars.svg";
import notification from "../Assets/vectors/notification.svg";
import share from "../Assets/vectors/share.svg";
import "./carousel.scss";

const content = [
  <div className="home_section2 flex-fd-c flex">
    <div className="flex home_section2_first">
      <div className="flex home_section2_first_text flex-fd-c">
        <div>
          <span className="home_section2_first_text_header shadow">Auction</span>
        </div>
        <div className="home_section2_first_text_content">
          <span className="home_section2_first_text_description shadow">
            All the wine you need <br></br>for the parties !
          </span>
        </div>
      </div>
      <div className="flex home_section2_first_img ">
        <img
          draggable="false"
          src={red_bottle}
          alt="red_bottle"
          className="home_section2_first_img_img "
        />
        <div className="flex home_section2_first_img_text">
          <span className="home_section2_first_img_text_name shadow">Lucia</span>
          <span className="home_section2_first_img_text_quantity shadow">75ml</span>
          <img
            draggable="false"
            src={stars}
            alt="red_bottle"
            className="home_section2_first_img_text_img "
          />
          <span className="home_section2_first_img_text_description shadow">
            Auction at
          </span>
          <span className="home_section2_first_img_text_price shadow">50 €</span>
          {/* <div className="flex flex-js-se home_section2_first_img_text_buttons">
                <img draggable="false" src={left_arrow} alt="red_bottle" />
                <img draggable="false" src={line} alt="red_bottle" />
                <img draggable="false" src={right_arrow} alt="red_bottle" />
              </div> */}
        </div>
      </div>
    </div>
    <div className="flex flex-js-fs home_section2_second">
      <div className="home_section2_second_icon ">
        <img
          draggable="false"
          src={notification}
          alt="red_bottle"
          className="home_section2_second_icon_img "
        />
      </div>
      <div className="home_section2_second_share home_section2_second_icon ">
        <img
          draggable="false"
          src={share}
          alt="red_bottle"
          className="home_section2_second_icon_share "
        />
      </div>
      <div>
        <button className="home_section2_second_btn">
          <span className="home_section2_second_btn_text shadow">
            Participate in auctions
          </span>
        </button>
      </div>
    </div>
  </div>,
];

const CarouselHome = () => {
  const [banners, setBanners] = useState([]);

  const images = banners.map((banner, index) => ({
    original: banner,
    description: content,
    hyperlink:
      banner.hyperlink && banner.hyperlink.trim() !== ""
        ? banner.hyperlink
        : "",
  }));

  const getMarkets = async () => {
    const bannerUrl = `${routes.routes.back_end.iconic_user.banners.url}`;
    const bannerMethod = `${routes.routes.back_end.iconic_user.banners.method}`;
    await ApiService.fetchApiData(bannerUrl, bannerMethod, "user").then(
      (result) => {
        const marketPuzles = result.filter((res) =>
          res.tab.includes("homepage")
        );
        console.log("market ", marketPuzles);
        setBanners(marketPuzles);
      }
    );
  };
  useEffect(() => {
    getMarkets();
  }, []);

  console.log("tab ", banners);

  return (
    <div className=" flex flex-fd-c flex-js-fs padding">
      <Swiper
        modules={[Navigation, Pagination]}
        className="home_section2 flex-fd-c flex"
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {banners.length > 0 &&
          images.map((car, index) => {
            return (
              <Fragment key={index}>
                <SwiperSlide
                  style={{
                    backgroundImage: `url(${car.original?.img})`,
                    cursor: car.hyperlink ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (car.hyperlink) {
                      window.open(car.hyperlink, "_blank");
                    }
                  }}
                >
                  {car.description}
                </SwiperSlide>
              </Fragment>
            );
          })}
      </Swiper>
    </div>
  );
};

export default CarouselHome;
