import React, { useEffect, useState } from "react";
import dollar from "../../Assets/vectors/dollar.svg";
import euro from "../../Assets/vectors/euro.svg";
import bitcoin from "../../Assets/vectors/bitcoin.svg";
import ethereum from "../../Assets/vectors/ethereum.svg";
import addbutton from "../../Assets/vectors/addbutton.svg";
import cashback_arrow from "../../Assets/vectors/cashback_arrow.svg";
import Up from "../../Assets/vectors/currencie_icon_up.svg";
import Transfer from "../../Assets/vectors/currencie_icon_transfer.svg";
import { Link } from "react-router-dom";
const Wallets = ({ allWallets, currentWallet }) => {
  const [isWalletActive, setIsWalletActive] = useState(false);

  const handleWallets = (id) => {
    let wallets = document.getElementById(id);
    let wallet = document.getElementById(id + "content");
    console.log(id);
    setIsWalletActive(!isWalletActive);
    if (!isWalletActive) {
      wallet.classList.remove("hidden");
      wallets.style = "background : rgba(52, 55, 77, 1);";
    } else {
      wallets.style = "";
      wallet.classList.add("hidden");
    }
  };

  return (
    <div className="wallets flex flex-fd-c flex-ai-c">
      <div className="wallets_header flex flex-js-sb">
        
          <span className="wallets_name">My wallet</span>
        
        <div className="flex wallets_header_amount">
          <span className="wallets_header_amount_item wallets_header_amount_code">
            ({currentWallet.currency_code}
          </span>
          <span className="wallets_header_amount_item">
            {currentWallet.amount})
          </span>
        </div>
        <img
          draggable="false"
          src={addbutton}
          alt="+"
          className="wallets_header_btn"
        />
      </div>

      <div className="wallets_rewards flex flex-js-sb">
        <span className="wallets_name">My rewards (USD )</span>
      </div>

      <div className="wallets_rewards flex flex-js-sb">
        <span className="wallets_name">My stacks (USD )</span>
      </div>

      {/*allWallets?.map((wallet) => {
        if (wallet.amount <= 0) {
          return;
        }
        return (
          <div className="container_wallets" id={wallet.name}>
            <div
              className="flex wallets_content flex-ai-c"
              onClick={() => handleWallets(wallet.name)}
            >
              <img
                draggable="false"
                src={dollar}
                alt="dollar"
                className="wallets_content_img"
              />
              <span className="wallets_content_name">{wallet.name}</span>
              <span className="wallets_content_quantity">
                {parseFloat(wallet.amount).toFixed(2)}
              </span>
              <span className="wallets_content_money">
                {wallet.currency_code}
              </span>
            </div>
            {wallet.cashback > 0 && (
              <div className="wallets_content_convert">
                <img
                  draggable="false"
                  src={cashback_arrow}
                  alt="dollar"
                  className="wallets_content_convert_img"
                />
                <span className="wallets_content_convert_text">Cashback</span>
                <span className="wallets_content_convert_ammount">
                  {wallet.cashback}{" "}
                </span>
                <span className="wallets_content_convert_type">
                  {wallet.currency_code}
                </span>
              </div>
            )}
            <div
              className="wallets_content_buttons flex flex-js-fe hidden"
              id={wallet.name + "content"}
            >
              {wallet.cashback > 0 && (
                <div className="wallets_content_buttons_cashback flex-ai-c flex">
                  <span>Get my cashback</span>
                </div>
              )}
              <div className="wallets_content_buttons_send flex-ai-c flex">
                <img
                  draggable="false"
                  src={Up}
                  alt="dollar"
                  className="wallets_content_buttons_send_img"
                />
                <span>Send</span>
              </div>
              <Link to="/exchange" state={wallet}>
                <div className="wallets_content_buttons_change flex-ai-c flex">
                  <img
                    draggable="false"
                    src={Transfer}
                    alt="dollar"
                    className="wallets_content_buttons_change_img"
                  />
                  <span>Change</span>
                </div>
              </Link>
            </div>
          </div>
        );
      })*/}

      <div className="wallets_more">
        <span className="wallets_more_text">See all wallets</span>
      </div>
    </div>
  );
};
export default Wallets;
