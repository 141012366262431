import React, { useEffect, useState } from "react";
import puzzle from "../Assets/img/Profile/puzzle_example.png";
import likes from "../Assets/vectors/Profile/number_likes_profile.svg";
import comments from "../Assets/vectors/Profile/number_comments_profile.svg";
import percent from "../Assets/vectors/Profile/icon_percent_profile.svg";


import { Link } from "react-router-dom";
const Puzzle = ({
  ObjPuzzle,
  Market = false,
  Home = false,
  Search = false,
  allClassChecked,
  isSelectActive,
  fetchAllPuzzlesChecked,
  allPuzzleChecked
}) => {
  const [url, setUrl] = useState(
    "/details/" + ObjPuzzle.title + "-" + ObjPuzzle.reference
  );
  const [currentPuzzle,setCurrentPuzzle] = useState({})

  useEffect(() => {
    //initiateCheckedValues()
    let data = {}
    if(allClassChecked) {
      if(allClassChecked.includes(ObjPuzzle.puzzle_class)) {
      data['ref'] = ObjPuzzle.reference;
      data['value']  = true
    }
    /*else {
      data['ref'] = ObjPuzzle.reference;
      data['value']  = false
    }*/
    fetchAllPuzzlesChecked(data)
    }
    
    console.log("typeOf allClassChecked : ", typeof allClassChecked)
  }, [allClassChecked]);
  

    const handlePuzzleCheckedChange = (e,ref) => {   
      console.log("ref : ",ref)
      let data =  {}
      data['ref'] = ref;
      if(e.target.checked) {
        data['value'] = true
      }else {
        data['value'] = false
      }
      console.log("data : ",data)
      fetchAllPuzzlesChecked(data)
    }

  if (ObjPuzzle.state == "to approve" || ObjPuzzle.state == "canceled") {
    return <></>;
  }
  if (Market) {
    return (
      <Link to={url} className="flex link_height" state={ObjPuzzle}>
        <div className="market_puzzle_margin">
          {ObjPuzzle.images.length > 0 ? (
            <img
              draggable="false"
              src={ObjPuzzle.images[0]}
              className="profile_gallery_body_card_view_img market_puzzle_img"
              alt=""
            />
          ) : (
            <img
              draggable="false"
              src={puzzle}
              className="profile_gallery_body_card_view_img market_puzzle_img"
              alt=""
            />
          )}

          <div className="home_section3_body_card_view_cover market_puzzle"></div>
          <div className="home_section3_body_card_view_title">
            {ObjPuzzle.title}
          </div>
          <div className="home_section3_body_card_view_category">
            <div className="profile_gallery_body_card_view_footer_stats inline">
              <div className="profile_gallery_body_card_view_footer_stats_like inline">
                <img
                  draggable="false"
                  alt=""
                  src={likes}
                  className="profile_gallery_body_card_view_footer_stats_like_icon"
                />
                <div className="profile_gallery_body_card_view_footer_stats_like_content">
                  {ObjPuzzle.likes.length}
                </div>
              </div>
              <div className="profile_gallery_body_card_view_footer_stats_comment inline">
                <img
                  draggable="false"
                  alt=""
                  src={comments}
                  className="profile_gallery_body_card_view_footer_stats_comment_icon"
                />
                <div className="profile_gallery_body_card_view_footer_stats_comment_content">
                  {ObjPuzzle.comments.length}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  } else if (Home) {
    return (
      <div className="">
        {ObjPuzzle.images.length > 0 ? (
          <img
            draggable="false"
            src={ObjPuzzle.images[0]}
            className="profile_gallery_body_card_view_img "
            alt=""
          />
        ) : (
          <img
            draggable="false"
            src={puzzle}
            className="profile_gallery_body_card_view_img "
            alt=""
          />
        )}
        <div className="home_section3_body_card_view_cover market_puzzle"></div>
        <div className="home_section3_body_card_view_title">
          {ObjPuzzle.title}
        </div>
        <div className="home_section3_body_card_view_category">
          {/*ObjPuzzle.class*/}
          <div className="profile_gallery_body_card_view_footer_stats inline">
            <div className="profile_gallery_body_card_view_footer_stats_like inline">
              <img
                draggable="false"
                alt=""
                src={likes}
                className="profile_gallery_body_card_view_footer_stats_like_icon"
              />
              <div className="profile_gallery_body_card_view_footer_stats_like_content">
                {ObjPuzzle.likes.length}
              </div>
            </div>
            <div className="profile_gallery_body_card_view_footer_stats_comment inline">
              <img
                draggable="false"
                alt=""
                src={comments}
                className="profile_gallery_body_card_view_footer_stats_comment_icon"
              />
              <div className="profile_gallery_body_card_view_footer_stats_comment_content">
                {ObjPuzzle.comments.length}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (Search) {
    return (
      <Link to={url} state={ObjPuzzle}>
        <div className="search_puzzle flex flex-ai-c flex-js-fs">
          {ObjPuzzle.images.length > 0 ? (
            <img
              src={ObjPuzzle.images[0]}
              alt=""
              className="search_puzzle_img"
            ></img>
          ) : (
            <img src={puzzle} alt="" className="search_puzzle_img"></img>
          )}
          <div className="search_puzzle_content flex flex-fd-c">
            <div className="search_puzzle_content_class">{ObjPuzzle.class}</div>
            <div className="search_puzzle_content_title">
              {ObjPuzzle.title}{" "}
            </div>
          </div>
        </div>
      </Link>
    );
  } else {
    return (
      <div className="">
        <div className="profile_gallery_body_card_view_footer_percent inline">
          <img
            draggable="false"
            alt=""
            src={percent}
            className="profile_gallery_body_card_view_footer_percent_icon"
          />
          <div className="profile_gallery_body_card_view_footer_percent_content">
            {ObjPuzzle.percent}%
          </div>
        </div>
        
        {
          console.log("allPuzzlesChecked before condition : ",  allPuzzleChecked)}
          {console.log("allPuzzlesChecked before condition typeof : ", typeof allPuzzleChecked)
        }
        { 
         isSelectActive && (allClassChecked.includes(ObjPuzzle.puzzle_class) || allPuzzleChecked.includes(ObjPuzzle.reference) ) && 
         //allPuzzleChecked.includes(ObjPuzzle.reference) &&
          <input 
        className="profile_gallery_body_card_view_footer_input"
        //checked={true}
        defaultChecked={true}
        onChange={(e)=>handlePuzzleCheckedChange(e,ObjPuzzle.reference)}
        type="checkbox" />
        }

        {
         isSelectActive && (!allClassChecked.includes(ObjPuzzle.puzzle_class) && !allPuzzleChecked.includes(ObjPuzzle.reference)) &&
          
         !allPuzzleChecked.includes(ObjPuzzle.reference) &&
          <input 
        className="profile_gallery_body_card_view_footer_input"
        onChange={(e)=>handlePuzzleCheckedChange(e,ObjPuzzle.reference)}
        type="checkbox" />
        }
        
        {ObjPuzzle.images.length > 0 ? (
          <img
            draggable="false"
            src={ObjPuzzle.images[0]}
            className="profile_gallery_body_card_view_img"
            alt=""
          />
        ) : ( 
          <img
            draggable="false"
            src={puzzle}
            className="profile_gallery_body_card_view_img "
            alt=""
          />
        )}

        <div className="home_section3_body_card_view_cover market_puzzle margin_cover_profile "></div>
        <div className="home_section3_body_card_view_title flex flex-fd-c margin_title_profile">
          {ObjPuzzle.puzzle_title}
          <div className="profile_gallery_body_card_view_footer_stats inline">
            <div className="profile_gallery_body_card_view_footer_stats_like inline">
              <img
                draggable="false"
                alt=""
                src={likes}
                className="profile_gallery_body_card_view_footer_stats_like_icon"
              />
              <div className="profile_gallery_body_card_view_footer_stats_like_content">
                {ObjPuzzle.likes.length}
              </div>
            </div>
            <div className="profile_gallery_body_card_view_footer_stats_comment inline">
              <img
                draggable="false"
                alt=""
                src={comments}
                className="profile_gallery_body_card_view_footer_stats_comment_icon"
              />
              <div className="profile_gallery_body_card_view_footer_stats_comment_content">
                {ObjPuzzle.comments.length}
              </div>
            </div>
          </div>
        </div>
        {console.log("typeof AllClassChecked : ", typeof allClassChecked)}
        {console.log("typeof AllPuzzleCheked : ", typeof allPuzzleChecked)}
          
      </div>
    );
  }
};
export default Puzzle;
