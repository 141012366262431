import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import connection_logo from "../../../Assets/img/register_logo.svg";
import videoBackground from "../../../Assets/img/puzzle_iconic-v12.mp4";
import password_hidden from "../../../Assets/img/password_hidden.svg";
import routes from "../../../Routes/routes.json";
import { useSelector, useDispatch } from "react-redux";
import ApiService from "../../../Utils/ApiService";
import useAuth from "../../../hooks/useAuth";
import { useParams, useLocation, Navigate } from "react-router-dom";
import CircleProgressBar from "../../CircleProgressBar";
import confirm_check from "../../../Assets/img/Account/confirm_check.svg";
import "./connection.scss";

const Connection = ({ isConnection }) => {
  const [loggedError, setLoggedError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const user = useSelector((state) => state.Auth);
  const [userDataHooks, setUserDataHooks] = useState({});
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [emailForgetPassword, setEmailForgetPassword] = useState("");
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const percentage = 100;
  const [progressBar, setProgressBar] = useState(0);
  const { login } = useAuth();

  const navigate = useNavigate();

  const { code } = useParams();
  console.log("code : ", code);

  const hidePassword = (id) => {
    //let password_input = document.getElementById("password_input");
    let password_input = document.getElementById(id);
    if (password_input.type === "text") {
      password_input.type = "password";
    } else {
      password_input.type = "text";
    }
    /*switch (id) {
      case "password":
        if (password_input.type === "text") {
          password_input.type = "password";
        } else {
          password_input.type = "text";
        }
        break;
      default:
        break;
    }*/
  };

  const handleSignIn = () => {
    let data = {};
    data["email"] = email;
    data["password"] = password;
    if (email !== "" && password !== "") {
      login({ email, password }).catch((error) => {
        setLoggedError(true);
        if (error.response && error.response.status === 401) {
          setErrorMessage("Incorrect Login Details");
        } else {
          setErrorMessage("Incorrect Login Details");
        }
      });
    } else {
      setLoggedError(true);
      setErrorMessage("Please enter your email and password");
    }
  };

  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, 5);
  };

  useEffect(() => {
    if (percentage > 0) updatePercentage();
  }, [percentage]);
  useEffect(() => {
    if (progressBar < percentage) updatePercentage();
  }, [progressBar]);

  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
  };

  const sendingMail = () => {
    const routeForgetPassword = `${routes.routes.back_end.iconic_user.forget_password.url}`;
    const method = `${routes.routes.back_end.iconic_user.forget_password.method}`;
    let data = {};
    data["email"] = emailForgetPassword;
    ApiService.sendingDataWithUrl(routeForgetPassword, method, data, "user")
      .then((result) => {
        console.log("result", result);
        setConfirmSuccess(!confirmSuccess);
      })
      .catch((err) => {
        console.log("error");
      });
  };

  const savePassword = () => {
    if (newPassword != confirmNewPassword) {
      return;
    }
    const routeUpdatePassword = `${routes.routes.back_end.iconic_user.update_password.url}`;
    const method = `${routes.routes.back_end.iconic_user.update_password.method}`;
    let data = {};
    data["password"] = newPassword;
    data["confirmation_password"] = confirmNewPassword;

    console.log("data : ", data);
    ApiService.sendingPutData(routeUpdatePassword + code, method, data, "user")
      .then((result) => {
        console.log("result");
        navigate("/connection");
      })
      .catch((err) => {
        console.log("error");
      });
  };

  return (
    <div className="video-container">
      <video autoPlay muted loop id="background-video">
        <source src={videoBackground} type="video/mp4" />
      </video>
      <div className="content">
        <div className="test">
          <div className="content-form">
            <div className="connection_logo flex flex-fd-c">
              <img
                draggable="false"
                src={connection_logo}
                alt="logo"
                className="connection_logo_img"
              />
              {!isForgetPassword && isConnection ? (
                <div className="connection_logo_text">Welcome in Iconic !</div>
              ) : (
                <div className="connection_logo_text">Password changing</div>
              )}
            </div>
            {!isForgetPassword && isConnection && (
              <div className="connection_form mg-form flex flex-fd-c">
                <div className="connection_form_text flex flex-js-fs">
                  Email or Phone number
                </div>
                <input
                  type="email"
                  className={`connection_form_input  ${
                    loggedError && "connection_form_error"
                  }`}
                  placeholder="Enter your e-mail"
                  onChange={(e) => {
                    setEmail(e.target.value.trim());
                  }}
                ></input>
                <div className="connection_form_text flex flex-js-fs">
                  Password
                </div>
                <input
                  type="password"
                  className={`connection_form_input  ${
                    loggedError && "connection_form_error"
                  }`}
                  placeholder="Choose your password"
                  id="password_input"
                  onChange={(e) => {
                    setPassword(e.target.value.trim());
                  }}
                ></input>
                <img
                  draggable="false"
                  src={password_hidden}
                  alt=""
                  className="connection_form_hide"
                  onClick={() => hidePassword("password_input")}
                />
                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}
                <div
                  className="connection_form_footer flex flex-js-fs"
                  onClick={() => setIsForgetPassword(true)}
                >
                  Forgot password ?
                </div>
              </div>
            )}

            {isForgetPassword && isConnection && (
              <>
                <div className="connection_form flex flex-fd-c">
                  <div className="connection_form_text flex flex-js-fs">
                    Email
                  </div>
                  <input
                    type="email"
                    className={`connection_form_input  ${
                      loggedError && "connection_form_error"
                    }`}
                    placeholder="Enter your e-mail"
                    onChange={(e) => {
                      setEmailForgetPassword(e.target.value.trim());
                    }}
                  ></input>
                </div>
              </>
            )}

            {!isConnection && (
              <>
                <div className="connection_form flex flex-fd-c">
                  <div className="connection_form_text flex flex-js-fs">
                    Password
                  </div>
                  <input
                    type="password"
                    className={`connection_form_input  ${
                      loggedError && "connection_form_error"
                    }`}
                    placeholder="Choose your password"
                    id="password_input"
                    onChange={(e) => {
                      setNewPassword(e.target.value.trim());
                    }}
                  ></input>
                  <img
                    draggable="false"
                    src={password_hidden}
                    alt=""
                    className="connection_form_hide"
                    onClick={() => hidePassword("password_input")}
                  />

                  <div className="connection_form_text flex flex-js-fs">
                    Confirm Password
                  </div>
                  <input
                    type="password"
                    className={`connection_form_input  ${
                      loggedError && "connection_form_error"
                    }`}
                    placeholder="Choose your password"
                    id="password_input_confirm"
                    onChange={(e) => {
                      setConfirmNewPassword(e.target.value.trim());
                    }}
                  ></input>
                  <img
                    draggable="false"
                    src={password_hidden}
                    alt=""
                    className="connection_form_hide"
                    onClick={() => hidePassword("password_input_confirm")}
                  />
                </div>
              </>
            )}
            <div className="connection_footer mg-form flex flex-fd-c">
              {!isForgetPassword && isConnection && (
                <div
                  className="connection_footer_btn flex"
                  onClick={() => handleSignIn()}
                >
                  Sign in
                </div>
              )}

              {isForgetPassword && isConnection && (
                <div
                  className="connection_footer_btn flex"
                  onClick={() => sendingMail()}
                >
                  Send e-mail
                </div>
              )}

              {!isConnection && (
                <div
                  className="connection_footer_btn flex"
                  onClick={() => savePassword()}
                >
                  Save
                </div>
              )}

              <div className="connection_footer_text">
                Don’t have an account ?{" "}
                <Link to="/register">
                  <span>Create a new one</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmSuccess && (
        <div className="flex">
          <div className="exchange_popup flex flex-fd-c flex-js-fs">
            <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c">
              {" "}
              E-mail sent
            </div>
          </div>
          <div id="overlay" onClick={() => handleSuccess()}></div>
        </div>
      )}
    </div>
  );
};

export default Connection;
