import dot from "../../Assets/vectors/Profile/dot.svg";
import Link from "./Link";
const MyLinks = ({ my_links }) => {
  if (my_links.length != "0") {
    return (
      <div className="links_body_left_contacts_mylinks">
        <div className="links_body_left_contacts_requests_title flex-ai-c flex flex-js-fs ">
          <p>My links</p> <img src={dot} alt=""></img>{" "}
          <span> {my_links.length} </span>
        </div>
        {my_links.map((link) => {
          return <Link avatar={link.avatar} username={link.username} />;
        })}
      </div>
    );
  } else {
    return (
      <div className="links_body_left_contacts_mylinks">
        <div className="links_body_left_contacts_requests_title flex-ai-c flex flex-js-fs ">
          Pas de links
        </div>
      </div>
    );
  }
};

export default MyLinks;
