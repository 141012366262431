import React, { useEffect, useState } from "react";

import friend from "../../../Assets/img/Profile/friend_request_icon.png";
import react from "../../../Assets/img/Profile/react_icon.png";
import comment from "../../../Assets/img/Profile/comment_icon.png";
import post from "../../../Assets/img/Profile/post_icon.png";
import { Fragment } from "react";

const Activity = () => {

    const [allActivity, setAllActivity] = useState([
        new Map([
            ["id", 1],
            ["type", "friend"],
            ["date", "01/02/22"],
            ["from", "James"],
            ["to", "You"],
        ]),
        new Map([
            ["id", 2],
            ["type", "react"],
            ["date", "28/01/22"],
            ["from", "You"],
            ["to", "Marie"],
        ]),
        new Map([
            ["id", 3],
            ["type", "comment"],
            ["date", "27/01/22"],
            ["from", "You"],
            ["to", "Jean"],
        ]),
        new Map([
            ["id", 4],
            ["type", "post"],
            ["date", "26/01/22"],
            ["from", "You"],
            ["to", "You"],
        ])
    ]);

    const [noActivity, setNoActivity] = useState([
        new Map([
            ["id", ""],
            ["type", ""],
            ["date", ""],
            ["from", ""],
            ["to", ""],
        ]),
    ]);

  return (
    <div className="profile_container_activity">
        <div className="profile_container_activity_title">Last activity</div>
            <div className="profile_container_activity_list">
            {noActivity.map((value, key) => {
                return (
                    <Fragment key={key}>
                        {value.get("type") == "friend" &&
                            <div className="profile_container_activity_list_row inline">
                                <div className="profile_container_activity_list_row_type flex">
                                    <img draggable="false" src={friend} className="profile_container_activity_list_row_type_icon"/>
                                </div>
                                <div className="profile_container_activity_list_row_stats">
                                    <div className="profile_container_activity_list_row_stats_date">{value.get("date")}</div>
                                    <div className="profile_container_activity_list_row_stats_content">
                                        @{value.get("from")} send {value.get("to")} a friend request.
                                    </div>
                                </div>
                            </div>
                        }
                        {value.get("type") == "react" &&
                            <div className="profile_container_activity_list_row inline">
                                <div className="profile_container_activity_list_row_type flex">
                                    <img draggable="false" src={react} className="profile_container_activity_list_row_type_icon"/>
                                </div>
                                <div className="profile_container_activity_list_row_stats">
                                    <div className="profile_container_activity_list_row_stats_date">{value.get("date")}</div>
                                    <div className="profile_container_activity_list_row_stats_content">
                                        {value.get("from")} react at @{value.get("to")}'s post.
                                    </div>
                                </div>
                            </div>
                        }
                        {value.get("type") == "comment" &&
                            <div className="profile_container_activity_list_row inline">
                                <div className="profile_container_activity_list_row_type flex">
                                    <img draggable="false" src={comment} className="profile_container_activity_list_row_type_icon"/>
                                </div>
                                <div className="profile_container_activity_list_row_stats">
                                    <div className="profile_container_activity_list_row_stats_date">{value.get("date")}</div>
                                    <div className="profile_container_activity_list_row_stats_content">
                                        {value.get("from")} comment at @{value.get("to")}'s post.
                                    </div>
                                </div>
                            </div>
                        }
                        {value.get("type") == "post" &&
                            <div className="profile_container_activity_list_row inline">
                                <div className="profile_container_activity_list_row_type flex">
                                    <img draggable="false" src={post} className="profile_container_activity_list_row_type_icon"/>
                                </div>
                                <div className="profile_container_activity_list_row_stats">
                                    <div className="profile_container_activity_list_row_stats_date">{value.get("date")}</div>
                                    <div className="profile_container_activity_list_row_stats_content">
                                        {value.get("from")} have a post.
                                    </div>
                                </div>
                            </div>
                        }
                        {!value.get("id") &&
                            <div className="profile_container_activity_list">
                                <div className="profile_container_activity_list_empty">
                                    <div className="profile_container_activity_list_empty_content">
                                        No activities found.
                                    </div>
                                </div>
                            </div>
                        }
                    </Fragment>
                );
            })}  
        </div> 
        <div className="profile_container_activity_more"><a href="#">See more activity</a></div>
    </div>
  );
};

export default Activity;