import config from "../Config/config";
import { createFormData } from ".";
import * as configFile from "../Config/config.json";

class ApiService {
  async fetchApiData(route, method, service) {
    const fullUrl = config.getBackEndUrl(service) + route;

    return fetch(fullUrl, {
      method: method,
    }).then((response) => response.json());
  }

  async sendingPutData(route, method, data, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    console.log(data, 'data');
    console.log(method, 'method');

    return fetch(fullUrl, {
      method: method,
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  async sendingDataWithUrl(route, method, data, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    data["url"] = config.getFrontEndUrl();
    console.log("data in sendingUrl : ", data);
    return fetch(fullUrl, {
      method: method,
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  async fetchApiFormData(route, method, data, files, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    for (let value of files) {
      formData.append("file", value);
    }

    console.log("full url : ", fullUrl);
    return fetch(fullUrl, {
      method: method,
      body: formData,
    }).then((response) => response.json());
  }

  async postFormData(route, method, data, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    const formData = createFormData(data);
    console.log('full url : ', fullUrl);
    return fetch(fullUrl, {
      method: method,
      body: formData,
    }).then((response) => response.json());
  }

  async postWithoutData(route, method, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    return fetch(fullUrl, {
      method: method,
    }).then((response) => response.json());
  }
}

export default new ApiService();
