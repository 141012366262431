import React, { useState } from "react";
import "./ButtonProfil.scss";

function ButtonProfil({ onSubmit, children, color }) {
  return (
    <div className={`button ${color}`} onClick={onSubmit}>
      {children}
    </div>
  );
}

export default ButtonProfil;
