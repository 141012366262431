import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import puzzle from "../../Assets/img/Profile/puzzle_example.png";
import "./style.scss";

const PuzzleCard = ({
  key,
  to,
  src,
  title,
  likes,
  comments,
  Market = false,
  Home = false,
  ObjPuzzle,
}) => {



  return (
    <div key={key} className="margin"  >
     
      
        <div className="market_puzzle_margin">
          <img
            draggable="false"
            src={src}
            className="profile_gallery_body_card_view_img market_puzzle_img"
            alt=""
          />
          <div className="home_section3_body_card_view_cover market_puzzle"></div>
          <div className="home_section3_body_card_view_title">{title}</div>
          <div className="home_section3_body_card_view_category">
            <div className="profile_gallery_body_card_view_footer_stats inline">
              <div className="profile_gallery_body_card_view_footer_stats_like inline">
                <img
                  draggable="false"
                  alt=""
                  src="/static/media/number_likes_profile.64f24c85cfad91375099b95344342ed9.svg"
                  className="profile_gallery_body_card_view_footer_stats_like_icon"
                />
                <div className="profile_gallery_body_card_view_footer_stats_like_content">
                  {likes}
                </div>
              </div>
              <div className="profile_gallery_body_card_view_footer_stats_comment inline">
                <img
                  draggable="false"
                  alt=""
                  src="/static/media/number_comments_profile.eb89d6aa3ee4af85888387913aa0250e.svg"
                  className="profile_gallery_body_card_view_footer_stats_comment_icon"
                />
                <div className="profile_gallery_body_card_view_footer_stats_comment_content">
                  {comments}
                </div>
              </div>
            </div>
          </div>
        </div>
   
    </div>
  );
};

export default PuzzleCard;
