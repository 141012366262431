import { useLocation, Link } from "react-router-dom";
import line from "../../Assets/vectors/Account/line.svg";
import transfer_arrow from "../../Assets/vectors/Account/transfer_arrow.svg";
import checked from "../../Assets/vectors/Account/checked.svg";
import transfer_shield from "../../Assets/img/Account/transfer_shield.svg";
import transfer_send from "../../Assets/vectors/Account/transfer_send.svg";
import transfer_amount from "../../Assets/img/Account/transfer_amount.svg";
import transfer_date from "../../Assets/img/Account/transfer_date.svg";
import transfer_label from "../../Assets/img/Account/transfer_label.svg";
import transfer_dollar from "../../Assets/img/Account/transfer_dollar.svg";
import transfer_euro from "../../Assets/img/Account/transfer_euro.svg";
import bitcoin from "../../Assets/vectors/Account/bitcoin.svg";
import etherum from "../../Assets/vectors/Account/etherum.svg";
import transfer_account from "../../Assets/img/Account/transfer_account.svg";
import React, { useEffect, useState } from "react";
import transaction_success from "../../Assets/img/Account/transaction_success.svg";
import { render } from "@testing-library/react";

import transfer_expand from "../../Assets/img/Account/select_button.svg";
import transfer_expand_active from "../../Assets/img/Account/select_button_active.svg";
import config from "../../Config/config";
import routes from "../../Routes/routes.json";
import ApiService from "../../Utils/ApiService";
import CircleProgressBar from "../CircleProgressBar";
import confirm_check from "../../Assets/img/Account/confirm_check.svg";
import { useSelector, useDispatch } from "react-redux";
import { getWallets, getLink, getUser } from "../../Redux/slices/user";

const TransferOut = () => {
  const dispatch = useDispatch();
  var currencyMap = {
    Dollar: transfer_dollar,
    Euro: transfer_euro,
  };
  const location = useLocation();
  const [currentWallet, setCurrentWallet] = useState(location.state);
  const user = useSelector((state) => state.Auth.user);
  const user_wallets = useSelector((state) => state.User.wallets);
  console.log(user);
  //console.log("currentWallet : ", currentWallet);

  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmActive, setConfirmActive] = useState(false);
  const [currencyActive, setCurrencyActive] = useState(false);
  const [accountActive, setAccountActive] = useState(false);
  const [allWalletsObject, setAllWalletsObject] = useState(user_wallets);
  const [amountValueInput, setAmountValueInput] = useState(0);
  const [currentWalletChecked, setCurrentWalletChecked] = useState(
    currentWallet.currency
  );

  const [currentLabelInput, setCurrentLabelInput] = useState(
    "transfer from iconic"
  );
  const [currentDate, setCurrentDate] = useState();
  const percentage = 100;

  const [progressBar, setProgressBar] = useState(0);
  const [allCard, setAllCard] = useState([
    { name: "user1", iban: "FR76 6783 8373 8789 390" },
    { name: "user2", iban: "FR76 9855 9554 4488 478" },
  ]);
  const [currentCard, setCurrentCard] = useState(allCard[0]);
  const [currentCardChecked, setCurrentCardChecked] = useState(
    currentCard.iban
  );

  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, 5);
  };

  useEffect(() => {
    if (percentage > 0) updatePercentage();
  }, [percentage]);

  useEffect(() => {
    if (progressBar < percentage) updatePercentage();
  }, [progressBar]);

  const fetchData = (fullUrl, methodRoute) => {
    return fetch(fullUrl, {
      method: methodRoute,
    }).then((response) => response.json());
  };

  /*useEffect(() => {
    const walletsRoute = `${routes.routes.back_end.user.account.get_wallets.url}`;
    const walletsFullUrl = config.getBackEndUrl() + walletsRoute;
    fetchData(
      walletsFullUrl + "/" + user.id,
      `${routes.routes.back_end.user.account.get_wallets.method}`
    ).then((result) => {
      setAllWalletsObject(result);
      console.log(result);
      //setWalletConvertedToShow(result[0]);
      //setCurrentWalletConverted(result[0].currency);
    });
  }, []);*/

  const handleCurrency = () => {
    let expand = document.getElementById("transferfromexpand");
    setCurrencyActive(!currencyActive);

    if (currencyActive) {
      expand.src = transfer_expand;
    } else {
      expand.src = transfer_expand_active;
    }
  };

  const handleAccount = () => {
    let expand = document.getElementById("transfertoexpand");
    setAccountActive(!accountActive);

    if (accountActive) {
      expand.src = transfer_expand;
    } else {
      expand.src = transfer_expand_active;
    }
  };

  const accountChange = (iban) => {
    setCurrentCardChecked(iban);
    {
      allCard.map((value, key) => {
        console.log("value.iban : ", value.iban, " -- iban : ", iban);
        if (value.iban === iban) {
          setCurrentCard(value);
        }
      });
    }
  };
  const handleConfirm = () => {
    if (parseFloat(amountValueInput) > currentWallet.amount) {
      return;
    }
    let amount = document.getElementById("transfer_amount");
    setConfirmActive(!confirmActive);
  };
  const endtransaction = () => {
    setConfirmSuccess(!confirmSuccess);
    setConfirmActive(false);
  };
  const handleConfirmSuccess = () => {
    // TODO dispatch to change wallet value

    setConfirmSuccess(!confirmSuccess);
    setConfirmActive(false);
    /*console.log(
      "name : ",
      currentWallet.name,
      " amount : ",
      currentWallet.amount,
      " input : ",
      parseFloat(amountValueInput)
    );
    const updateWalletRoute =
      routes.routes.back_end.user.account.updateWallets.url +
      "/" +
      currentWallet.name;

    const method = `${routes.routes.back_end.user.account.updateWallets.method}`;
    console.log("route : ", updateWalletRoute, " : ", method);
    const total =
      parseFloat(currentWallet.amount) - parseFloat(amountValueInput);

    ApiService.sendingPutData(
      updateWalletRoute,
      method,
      {
        amount: currentWallet.amount - parseFloat(amountValueInput),
      },
      "transactions"
    ).then((result) => {
      //console.log("update wallet : ", result);
      dispatch(getWallets(user.id));
    });*/

    // TODO :fetch with new route
    const updateWalletRoute =
      routes.routes.back_end.user.account.transfert_out.url + currentWallet.id;
    const method = `${routes.routes.back_end.user.account.transfert_out.method}`;

    ApiService.sendingPutData(
      updateWalletRoute,
      method,
      {
        amount: parseFloat(amountValueInput),
      },
      "transactions"
    ).then((result) => {
      console.log("update wallet Trans out : ", result);
      setCurrentWallet(result);
      dispatch(getWallets(user.id));
    });

    /*let tmpCurrentWallet = {};
    tmpCurrentWallet = currentWallet;
    tmpCurrentWallet.amount =
      parseFloat(currentWallet.amount) - parseFloat(amountValueInput);
    setCurrentWallet(tmpCurrentWallet);*/
    setProgressBar(0);
  };

  let now = new Date();
  //console.log(now);
  let now_string = "";
  const handleTransferFrom = () => {
    let transferFrom = document.getElementById("transferFrom");
    let currency = document.getElementById("transferFromCurrency");
    currency.innerText = transferFrom.value;
    document.getElementById("transferfromimg").src =
      currencyMap[transferFrom.value];
  };

  const handleWalletSelected = (money) => {
    setCurrentWalletChecked(money);
    {
      user_wallets.map((value, key) => {
        if (value.currency === money) {
          setCurrentWallet(value);
        }
      });
    }
  };

  const isWalletValid = (money) => {
    if (currentWalletChecked === money) {
      return (
        <img
          src={checked}
          alt=""
          id="euro-img"
          className="transferout_from_choice_select_option_checked"
        />
      );
    } else {
      return (
        <img
          src={checked}
          alt=""
          id="euro-img"
          className="transferout_from_choice_select_option_checked not_visible"
        />
      );
    }
  };

  const renderCurrentImg = (wallet, classImg, id) => {
    let imgToDisplay = transfer_dollar;
    if (wallet.currency === "Bitcoin") {
      imgToDisplay = bitcoin;
    } else if (wallet.currency === "Dollar") {
      imgToDisplay = transfer_dollar;
    } else if (wallet.currency === "Etherum") {
      imgToDisplay = etherum;
    } else if (wallet.currency === "Euro") {
      imgToDisplay = transfer_euro;
    }

    return <img src={imgToDisplay} alt="" id={id} className={classImg} />;
  };

  const isCardValid = (iban) => {
    if (currentCardChecked === iban) {
      return (
        <img
          src={checked}
          alt=""
          className="transferout_from_choice_select_option_checked"
        />
      );
    } else {
      return (
        <img
          src={checked}
          alt=""
          className="transferout_from_choice_select_option_checked not_visible"
        />
      );
    }
  };

  return (
    <div className="transferout flex flex-fd-c">
      <div className="page_title">
        Market
        <div className="register_header_path flex flex-js-fs">
          <Link to="/home">Home</Link>{" "}
          <div className="register_header_path_gt">{">"}</div>{" "}
          <Link to="/account">
            <span>Account</span>
          </Link>{" "}
          <div className="register_header_path_gt">{">"}</div>{" "}
          <span>Transfer out</span>
        </div>
      </div>
      <div className="transferout_transfer flex flex-ai-c">
        <div className="transferout_from flex flex-fd-c">
          <div>From</div>
          <div className="transferout_from_line">
            <img src={line} alt=""></img>
          </div>
          <div className="transferout_from_choice_select flex flex-fd-c flex-js-fs">
            <div
              className="transferout_from_choice_select_default flex flex-js-fs"
              onClick={() => handleCurrency()}
            >
              {renderCurrentImg(currentWallet, "transferout_from_choice_img")}
              <div className="transferout_from_choice_select_text flex flex-fd-c selected_width">
                <div
                  className="transferout_from_choice_select_text_currency"
                  id="transferfromcurrency"
                >
                  {currentWallet.currency}
                </div>
                <div className="transferout_from_choice_select_text_amount flex flex-ai-c">
                  <div id="transferfromamount">
                    {parseFloat(currentWallet.amount).toFixed(2)}
                  </div>{" "}
                  <span className="currency_code_margin" id="transferfromcode">
                    {currentWallet.currency_code}
                  </span>
                </div>
              </div>
              <img
                src={transfer_expand}
                alt=""
                id="transferfromexpand"
                className="transferout_from_choice_select_expand_currency"
              ></img>
            </div>
            {currencyActive && (
              <div>
                {user_wallets.map((value, key) => {
                  return (
                    <div
                      className="transferout_from_choice_select_option flex flex-js-fs flex-ai-c"
                      onClick={() => handleWalletSelected(value.currency)}
                    >
                      <div className="transferout_from_choice_select_text flex flex-ai-c flex-js-fs">
                        {renderCurrentImg(
                          value,
                          "transferout_from_choice_select_option_img"
                        )}
                        <div className="transferout_from_choice_select_text_currency currency_margin flex">
                          {value.currency}
                        </div>
                        <div className="transferout_from_choice_select_text_amount flex flex-ai-c currency_margin">
                          {parseFloat(value.amount).toFixed(2)}
                          <span className="currency_code_margin">
                            {value.currency_code}
                          </span>
                        </div>
                        {isWalletValid(value.currency)}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="transferout_transfer_arrow">
          <img src={transfer_arrow} alt=""></img>
        </div>
        <div className="transferout_from flex flex-fd-c">
          <div>To</div>
          <div className="transferout_from_line">
            <img src={line} alt=""></img>
          </div>
          <div className="transferout_from_choice_select flex flex-fd-c flex-js-fs">
            <div
              className="transferout_from_choice_select_default flex flex-js-fs"
              onClick={() => handleAccount()}
            >
              <img
                src={transfer_account}
                alt=""
                id="transfertoimg"
                className="transferout_from_choice_img"
              ></img>
              <div className="transferout_from_choice_select_text flex flex-fd-c ">
                <div
                  className="transferout_from_choice_select_text_currency"
                  id="transfertoaccount"
                >
                  {currentCard.name}
                </div>
                <div className="transferout_from_choice_select_text_iban flex flex-ai-c">
                  <div id="transfertoiban">{currentCard.iban}</div>
                </div>
              </div>
              <img
                src={transfer_expand}
                alt=""
                id="transfertoexpand"
                className="transferout_from_choice_select_expand_account"
              ></img>
            </div>
            {accountActive && (
              <div>
                {allCard.map((value) => {
                  return (
                    <div
                      className="transferout_from_choice_select_option flex flex-js-fs flex-ai-c"
                      onClick={() => accountChange(value.iban)}
                    >
                      <div className="transferout_from_choice_select_text flex flex-ai-c flex-js-fs">
                        <img
                          src={transfer_account}
                          alt=""
                          id="transferfromimg"
                          className="transferout_from_choice_select_option_img"
                        ></img>
                        <div className="transferout_from_choice_select_text_currency currency_margin ">
                          {value.name}
                        </div>
                        <div className="transferout_from_choice_select_text_amount flex flex-ai-c currency_margin"></div>
                        {isCardValid(value.iban)}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="transferout_details flex">
        <div className="transferout_details_amount flex flex-ai-c">
          <div className="flex flex-fd-c">
            <div>Amount</div>
            <div>
              <input
                type="number"
                min={0}
                id="transfer_amount"
                className="transferout_details_amount_input"
                defaultValue={0}
                step="0.01"
                onChange={(e) =>
                  setAmountValueInput(parseFloat(e.target.value))
                }
              ></input>{" "}
              <span className="transferout_details_amount_currency">
                {currentWallet.currency_code}
              </span>
            </div>
          </div>
          <img
            src={transfer_amount}
            alt=""
            className="transferout_details_amount_img"
          ></img>
        </div>
        <div className="transferout_details_date flex flex-ai-c">
          <div className="flex flex-fd-c">
            <div>Date</div>
            <div>
              <input
                type="date"
                min={now_string}
                className="transferout_details_date_input"
                onChange={(e) => setCurrentDate(e.target.value)}
              ></input>
            </div>
          </div>
        </div>
        <div className="transferout_details_label flex flex-ai-c">
          <div className="flex flex-fd-c">
            <div>Label</div>
            <div>
              <input
                type="text"
                defaultValue={"Transfer from iconic"}
                maxLength={30}
                className="transferout_details_label_input"
                onChange={(e) => setCurrentLabelInput(e.target.value)}
              ></input>
            </div>
          </div>
          <img
            src={transfer_label}
            alt=""
            className="transferout_details_label_img"
          ></img>
        </div>
      </div>
      <div className="transferout_footer flex flex-fd-c">
        <div className="transferout_footer_clause flex flex-ai-c">
          <img
            src={transfer_shield}
            alt=""
            className="transferout_footer_clause_img"
          ></img>
          This transaction is secure by protocole nom
        </div>
        <div
          className="transferout_footer_button flex flex-ai-c"
          onClick={() => handleConfirm()}
        >
          <img src={transfer_send} alt=""></img>
          Send
        </div>
      </div>
      {confirmActive && (
        <div className="flex flex-ai-c">
          <div className="transferout_popup flex flex-fd-c flex-js-fs">
            <div className="transferout_popup_header">
              <div>Transfer out</div>
              <div className="transferout_from_line">
                <img src={line} alt=""></img>
              </div>
            </div>
            <div className="transferout_popup_total flex flex-fd-c flex-ai-c">
              <div>
                {renderCurrentImg(
                  currentWallet,
                  "transferout_popup_total_img",
                  ""
                )}
                <img src={transfer_account} alt="" className=""></img>
              </div>
              <div
                className="transferout_popup_total_amount"
                id="transfer_total"
              >
                {amountValueInput}
              </div>
            </div>
            <div className="transferout_popup_buttons flex flex-fd-c flex-ai-c">
              <div className="transferout_popup_buttons_text">
                Do you confirm this transaction ?
              </div>
              <div className="flex">
                <div
                  className="transferout_popup_buttons_cancel flex flex-ai-c"
                  onClick={() => handleConfirm()}
                >
                  Cancel
                </div>
                <div
                  className="transferout_popup_buttons_confirm flex flex-ai-c"
                  onClick={() => handleConfirmSuccess()}
                >
                  I confirm
                </div>
              </div>
            </div>
          </div>
          <div id="overlay" onClick={() => handleConfirm()}></div>
        </div>
      )}
      {confirmSuccess && (
        <div className="flex">
          <div className="transferout_popup flex flex-fd-c flex-js-fs">
            <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c">
              {" "}
              Transaction confirmed !
            </div>
          </div>
          <div id="overlay" onClick={() => endtransaction()}></div>
        </div>
      )}
    </div>
  );
};

export default TransferOut;
