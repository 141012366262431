import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import Gallery from "./Gallery";
import { useSelector, useDispatch } from "react-redux";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getUser } from "../../../Redux/slices/user";
import badge from "../../../Assets/vectors/Profile/unverified_profile_badge.svg";

const Profile = () => {
  const user = useSelector((state) => state.Auth.user);
  const linksUser = useSelector((state) => state.User.link);
  const [allClasses, setAllClasses] = useState([]);
  const [allPuzzles, setAllPuzzles] = useState([]);
  const [isfetching, setFetching] = useState(true);
  const [connected, setConnected] = useState(false);
  const [profil_visted, setProfil] = useState(null);
  const [userconnected, setUserconnected] = useState(false);
  let { username } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [userVisited, setUserVisited] = useState(location.state);
  const navigate = useNavigate();

  useEffect(() => {
    //const puzzlesRoute = `${routes.routes.back_end.puzzle.gallery.url}`;
    const puzzlesRoute = `${routes.routes.back_end.puzzle.puzzle.url}`;
    const classesRoute = `${routes.routes.back_end.puzzle.classes.url}`;
    const routeGetUser = `${routes.routes.back_end.iconic_user.get_user.url}`;
    const method = `${routes.routes.back_end.puzzle.puzzles.method}`;

    //dispatch(getUser(username));

    ApiService.fetchApiData(routeGetUser + "/" + username, method, "user")
      .then((result) => {
        setProfil(result);
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });

    ApiService.fetchApiData(
      puzzlesRoute + userVisited.id + "/puzzle_to_display",
      method,
      "puzzle"
    ).then((result) => {
      console.log("allPuzzles in result : ", result);
      setAllPuzzles(result);
    });

    ApiService.fetchApiData(classesRoute, method, "puzzle").then((result) => {
      setAllClasses(result);
    });
    setFetching(false);
  }, [username, userVisited.id]);

  /*if (profil_visted && profil_visted.visibility == "private") {
    return;
    <>{navigate("/home")}</>;
  }*/

  if (
    profil_visted &&
    profil_visted.privacy["visit_my_profile"] == "my_links"
  ) {
    return (
      <div className="profile">
        {console.log("linksUser : ", linksUser)}
        {console.log("profile visited : ", profil_visted)}
        {allClasses && allPuzzles && profil_visted ? (
          <>
            <Dashboard user={profil_visted} userconnected={userconnected} />
            {console.log("allClasses   : ", allClasses)}
            {console.log("linkUser in semi-private  : ", linksUser)}
            {linksUser.some(
              (link) =>
                link["status"] === "bind" &&
                link["username"] == profil_visted.username
            ) ? (
              <>
                {allClasses && allPuzzles && (
                  <Gallery
                    allClasses={allClasses}
                    allPuzzles={allPuzzles}
                    isMyProfile={false}
                  />
                )}
              </>
            ) : (
              <>
                
              </>
            )}
          </>
        ) : (
          <p></p>
        )}
      </div>
    );
  }

  return (
    <div className="profile">
      {console.log("profile visited : ", profil_visted)}
      {allClasses && allPuzzles && profil_visted ? (
        <>
          <Dashboard user={profil_visted} userconnected={userconnected} />
          {console.log("allClasses   : ", allClasses)}
          {console.log("allClasses   : ", allPuzzles)}
          {allClasses && allPuzzles && (
            <Gallery
              allClasses={allClasses}
              allPuzzles={allPuzzles}
              isMyProfile={false}
            />
          )}
        </>
      ) : (
        <p></p>
      )}
      {console.log("profil visited : ", profil_visted)}
    </div>
  );
};

export default Profile;
