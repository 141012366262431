import React, { useState, useEffect, useLayoutEffect } from "react";
import more from "../../../Assets/vectors/Profile/shopping_cart.png";
import Puzzle from "../../Puzzle";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const PuzzleList = ({
  class_name,
  all_puzzle,
  market,
  allDataFilter,
  marketType,
  marketSelected,
  ClassActive,
  allClassChecked,
  isSelectActive,
  fetchAllPuzzlesChecked,
  allPuzzleChecked
}) => {
  {console.log("all class in checked in puzzle list : ",allClassChecked)}
  let totalValue = 0;
  const [listDataToDisplay, setListDataToDisplay] = useState(all_puzzle);
  const [puzzlesSorted, setPuzzlesSorted] = useState(all_puzzle);
  const [showPuzzle, setShowPuzzle] = useState(true);
  const [dataPuzzleFilter, setDataPuzzleFilter] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  //const pageCount = Math.ceil(all_puzzle.length / usersPerPage);
  const pageCount = Math.ceil(listDataToDisplay.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  console.log("all puzzle : 123 ", all_puzzle);

  useEffect(() => {
    all_puzzle.sort((formerValue, followingValue) => {
      return formerValue.likes.length - followingValue.likes.length;
    });
    setListDataToDisplay(all_puzzle.slice(0).reverse());
    setPuzzlesSorted(all_puzzle.slice(0).reverse());
  }, [all_puzzle]);

  useEffect(() => {
    let listpuzzletmp = [];
    if(!allDataFilter) {
      return
    }
    {
      all_puzzle.map((puzzle) => {
        for (let { key, value } of allDataFilter) {
          if (!puzzle[key].includes(value)) {
            return;
          }
        }
        if (marketSelected === "New puzzles" && puzzle.market != "primary") {
          return;
        } else if (
          marketSelected === "Puzzles market" &&
          puzzle.market != "secondary"
        ) {
          return;
        }
        if (puzzle.class === ClassActive) {
          listpuzzletmp.push(puzzle);
        } else if (ClassActive == "" || ClassActive == "allPuzzles") {
          listpuzzletmp.push(puzzle);
        }
      });
    }
    listpuzzletmp.sort((formerValue, followingValue) => {
      return formerValue.likes.length - followingValue.likes.length;
    });
    //console.log("listpuzzletmp : ", listpuzzletmp);
    setListDataToDisplay(listpuzzletmp.slice(0).reverse());

    if (pageNumber >= pageCount) {
      setPageNumber(0);
    }
  }, [marketSelected, ClassActive, allDataFilter]);

  if (!market) {
    return (
      <div className="flex">
        <div className="profile_gallery_body flex">
          {puzzlesSorted.map((puzzle, index) => {
            if (puzzle.puzzle_class === class_name) {
              totalValue += parseInt(puzzle.piece) * parseInt(puzzle.selling);
              
              console.log("allPuzzleChecked before Puzzle: ",allPuzzleChecked)
              console.log("allPuzzleChecked before Puzzle: ",allClassChecked)
              if(allPuzzleChecked && allClassChecked) {
                return <Puzzle ObjPuzzle={puzzle} allClassChecked={allClassChecked} isSelectActive={isSelectActive} fetchAllPuzzlesChecked={fetchAllPuzzlesChecked} allPuzzleChecked={allPuzzleChecked} />;
              }
            } else if (class_name == "" || class_name == "allPuzzles") {
              totalValue += parseInt(puzzle.piece) * parseInt(puzzle.selling);
              console.log("allPuzzleChecked before Puzzle: ",allPuzzleChecked)
              console.log("allPuzzleChecked before Puzzle: ",allClassChecked)
              if(allPuzzleChecked && allClassChecked) {
                return <Puzzle ObjPuzzle={puzzle}  allClassChecked={allClassChecked} isSelectActive={isSelectActive} fetchAllPuzzlesChecked={fetchAllPuzzlesChecked} allPuzzleChecked={allPuzzleChecked} />;
              }
            }
          })}

          <div className="profile_gallery_body_card">
            <div className="profile_gallery_body_card_view_more flex flex-fd-c flex-js-fs">
              <div className="profile_gallery_body_card_view_more_title">
                Total value
              </div>
              <div className="profile_gallery_body_card_view_more_ammount">
                {totalValue} <span>$</span>
              </div>
              <Link to="/market">
                <div className="profile_gallery_body_card_view_more_button flex">
                  <img
                    src={more}
                    alt=""
                    className="profile_gallery_body_card_view_more_button_img"
                  ></img>
                  <div className="profile_gallery_body_card_view_more_button_text flex flex-fd-c">
                    <div className="profile_gallery_body_card_view_more_button_text_1">
                      You want more ?
                    </div>
                    <div className="profile_gallery_body_card_view_more_button_text_2">
                      Go to the market
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {puzzlesSorted && console.log("puzzle sorted : ", puzzlesSorted)}
      </div>
    );
  } else {
    return (
      <>
        <div className="profile_gallery_body flex">
          {listDataToDisplay
            .slice(pagesVisited, pagesVisited + usersPerPage)
            .map((puzzle, index) => {
              return <Puzzle ObjPuzzle={puzzle} Market={market}/>;
            })}
        </div>
        <div className="container_pagination flex">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </>
    );
  }
};

export default PuzzleList;
