import React, { useEffect, useState } from "react";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Historic = () => {
  const user = useSelector((state) => state.Auth.user);
  const [allHistoric, setAllHistoric] = useState([]);

  useEffect(() => {
    fetchHistoric();
  }, []);

  const fetchHistoric = () => {
    const historicRoute =
      routes.routes.back_end.user.account.historic.url + user.id;
    const method = `${routes.routes.back_end.user.account.historic.method}`;

    console.log("historicRoute : ", historicRoute);
    ApiService.fetchApiData(historicRoute, method, "transactions").then(
      (result) => {
        console.log("result historic : ", result);
        setAllHistoric(result);
      }
    );
  };

  const limitedHistoric = allHistoric.length > 0 ? allHistoric.slice(0, 3) : [];

  return (
    <div className="account-right-historic">
      <div className="flex flex-js-sb">
        <div>
          <div className="account_right_order_name">Historic</div>
          <hr className="account_currencie_underscore"></hr>
        </div>
      </div>

      <div className="account-right-historic_tab flex flex-fd-c flex-ai-c">
        <div className="account-right-historic_tab_header flex flex-ai-c flex-js-fs">
          <div className="account-right-historic_tab_header_currency">
            Currency
          </div>
          <div className="account-right-historic_tab_header_amount">Amount</div>
          <div className="account-right-historic_tab_header_amount">
            Actions
          </div>
          <div className="account-right-historic_tab_header_amount">Date</div>
        </div>
      </div>

      {limitedHistoric.length > 0 ? (
        limitedHistoric.map((value, key) => (
          <div className="account-right-historic_elems flex flex-fd-c" key={key}>
            <div className="account-right-historic_elems_row flex flex-ai-c flex-js-fs">
              <div className="account-right-historic_elems_row_currency flex  flex-js-fs">
                {value.currency}
              </div>
              <div className="account-right-historic_tab_header_amount">
                {parseFloat(value.amount).toFixed(2)}
              </div>
              <div className="account-right-historic_tab_header_amount">
                {value.action}
              </div>
              <div className="account-right-historic_tab_header_amount">
                {value.date.substr(0, 10)}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No transaction history available.</div>
      )}

      <Link to="/account/my_wallet">
        <div className="account_right_order_more">
          <span className="account_right_order_more_text">
            See all Historic
          </span>
        </div>
      </Link>
    </div>
  );
};

export default Historic;
