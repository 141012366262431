import user_avatar from "../../Assets/img/Links/user_avatar.svg";
import { useSelector, useDispatch } from "react-redux";

const User = ({ currentLinkedUserInfo, link }) => {
  console.log("length", currentLinkedUserInfo);
  const user = useSelector((state) => state.User);
  return (
    <div className="links_body_left_user flex flex-fd-c flex-ai-c">
      <div className="links_body_left_user_img flex flex-fd-c flex-ai-c">
        <img src={user_avatar} alt=""></img>@{user?.username}
      </div>
      <div className="links_body_left_user_stats flex">
        <div className="links_body_left_user_stats_stat flex-ai-c flex flex-fd-c">
          <div className="links_body_left_user_stats_stat_number">
            {link?.length}
          </div>
          <div className="links_body_left_user_stats_links_text">Links</div>
        </div>
        <div className="links_body_left_user_stats_stat flex-ai-c flex flex-fd-c">
          <div className="links_body_left_user_stats_stat_number">{currentLinkedUserInfo?.length}</div>
          <div className="links_body_left_user_stats_links_text">Posts</div>
        </div>
      </div>
    </div>
  );
};

export default User;
