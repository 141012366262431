import React, { useEffect, useState } from "react";
import exchange_img from "../../Assets/img/Account/exchange_img.svg";
import dollar from "../../Assets/img/Account/dollar.svg";
import euro from "../../Assets/img/Account/euro.svg";
import expand_button from "../../Assets/img/Account/expand_button.svg";
import exchange from "../../Assets/img/Account/exchange.svg";
import shield from "../../Assets/img/Account/shield.svg";
import taux from "../../Assets/img/Account/taux.svg";
import line from "../../Assets/vectors/Account/line.svg";
import transaction_success from "../../Assets/img/Account/transaction_success.svg";
import euro_ from "../../Assets/vectors/exchange/euro_.svg";
import valid from "../../Assets/vectors/exchange/valid.svg";
import bitcoin from "../../Assets/vectors/exchange/bitcoin.svg";
import etherum from "../../Assets/vectors/exchange/etherum.svg";
import { useLocation, Link } from "react-router-dom";
import config from "../../Config/config";
import routes from "../../Routes/routes.json";
import ApiService from "../../Utils/ApiService";
import CircleProgressBar from "../CircleProgressBar";
import confirm_check from "../../Assets/img/Account/confirm_check.svg";
import { useSelector, useDispatch } from "react-redux";
import { getWallets, getLink, getUser } from "../../Redux/slices/user";

const Exchange = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);
  //console.log(user);
  const [CurrentWallet, setCurrentWallet] = useState(location.state);

  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmActive, setConfirmActive] = useState(false);
  const [isWalletToConverttActive, setIsWalletToConverttActive] =
    useState(false);
  const [isWalletConvertedActive, setIsWalletConvertedActive] = useState(false);
  const [currentWalletToExchage, setCurrentWalletToExchage] = useState(
    CurrentWallet.currency
  );
  const [currentWalletConverted, setCurrentWalletConverted] =
    useState("Dollar");
  const [currentValueConverted, setCurrentValueConverted] = useState(0);
  const [allWalletsObject, setAllWalletsObject] = useState([]);

  const [currentValueInput, setCurrentValueInput] = useState(
    CurrentWallet.amount
  );
  const [walletConvertedToShow, setWalletConvertedToShow] = useState({});
  const [rate, setRate] = useState(0);
  const percentage = 100;

  const [progressBar, setProgressBar] = useState(0);
  const [dataRate, setDataRate] = useState('');
  const [characters, setCharacters] = useState(
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  );
  const generateRef = (length) => {
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, 5);
  };

  useEffect(() => {
    if (percentage > 0) updatePercentage();
  }, [percentage]);

  useEffect(() => {
    if (progressBar < percentage) updatePercentage();
  }, [progressBar]);

  const fetchData = (fullUrl, methodRoute) => {
    return fetch(fullUrl, {
      method: methodRoute,
    }).then((response) => response.json());
  };

  useEffect(() => {
    const walletsRoute = `${routes.routes.back_end.user.account.get_wallets.url}`;
    const walletsFullUrl = config.getBackEndUrl() + walletsRoute;
    fetchData(
      walletsFullUrl + "/" + user.id,
      `${routes.routes.back_end.user.account.get_wallets.method}`
    ).then((result) => {
      //console.log(result);
      setAllWalletsObject(result);
      setWalletConvertedToShow(result[0]);
      setCurrentWalletConverted(result[0].currency);
    });
  }, []);

  const handleConfirm = () => {
    setConfirmActive(!confirmActive);
  };

  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
    setConfirmActive(false);
  };
  const handleConfirmSuccess = () => {
    console.log("dataRate in :", dataRate);
    // TODO  change with  : new route
    /*const updateWalletRoute =
      routes.routes.back_end.user.account.updateWallets.url +
      "/" +
      CurrentWallet.name;

    const updateWalletConvertedRoute =
      routes.routes.back_end.user.account.updateWallets.url +
      "/" +
      walletConvertedToShow.name;
    const method = `${routes.routes.back_end.user.account.updateWallets.method}`;
    const total =
      parseFloat(walletConvertedToShow.amount) +
      parseFloat(dataRate.total_buy_currency_amount);
    console.log("total : ", total);
    ApiService.sendingPutData(
      updateWalletRoute,
      method,
      {
        //amount: CurrentWallet.amount - parseFloat(dataRate.total_sell_currency_amount),
        amount:
          CurrentWallet.amount -
          parseFloat(dataRate.total_sell_currency_amount),
      },
      "transactions"
    ).then((result) => {
      console.log("update wallet : ", result);
      dispatch(getWallets(user.id));
    });

    ApiService.sendingPutData(
      updateWalletConvertedRoute,
      method,
      {
        amount: total,
      },
      "transactions"
    ).then((result) => {
      console.log("update wallet : ", result);
      dispatch(getWallets(user.id));
    });*/
    const urlCreateFxorder = `${routes.routes.back_end.user.account.create_fx_market_order.url}`;
    const methodCreateFxorder = `${routes.routes.back_end.user.account.create_fx_market_order.method}`;
    dataRate.reference = generateRef(12);
    ApiService.sendingPutData(
      urlCreateFxorder,
      methodCreateFxorder,
      dataRate,
      "transactions"
    ).then((result) => {
      if (result["success"]) {
        dispatch(getWallets(user.id));
        //walletTmp = useSelector((state) => state.User.wallets);
        console.log("in success");
        setConfirmSuccess(!confirmSuccess);
        setConfirmActive(false);
      }
    });
    let tmpCurrentWallet = {};
    tmpCurrentWallet = CurrentWallet;
    tmpCurrentWallet.amount =
      CurrentWallet.amount - parseFloat(dataRate.total_sell_currency_amount);
    setCurrentWallet(tmpCurrentWallet);
    tmpCurrentWallet = walletConvertedToShow;
    tmpCurrentWallet.amount =
      parseFloat(walletConvertedToShow.amount) +
      parseFloat(dataRate.total_buy_currency_amount);
    setWalletConvertedToShow(tmpCurrentWallet);
    setProgressBar(0);
  };

  const handleMoneySelected = (money) => {
    setCurrentWalletToExchage(money);
    {
      allWalletsObject.map((value, key) => {
        if (value.currency === money) {
          setCurrentWallet(value);
          setIsWalletToConverttActive(!isWalletToConverttActive);
        }
      });
    }
  };

  const handleMoneySelectedConverted = (money) => {
    setCurrentWalletConverted(money);
    {
      allWalletsObject.map((value, key) => {
        if (value.currency === money) {
          setWalletConvertedToShow(value);
          setIsWalletConvertedActive(!isWalletConvertedActive);
        }
      });
    }
  };

  const renderAllCurrency = (currency) => {
    let imgCurrency = { etherum };
    if (currency === "Bitcoin") {
      imgCurrency = { bitcoin };
    } else if (currency === "Dollar" || currency === "Euro") {
      imgCurrency = { euro_ };
    }
    return (
      <div className="exchange_container_content-select_content_item">
        <img src={imgCurrency} alt="" className="" />
        <span className="" onClick={() => handleMoneySelected(currency)}>
          {currency}
        </span>
        {isWalletValid(currency)}
      </div>
    );
  };

  const isWalletValid = (money) => {
    if (currentWalletToExchage === money) {
      return (
        <img
          src={valid}
          alt=""
          className="exchange_container_content-select_content_item_valid"
        />
      );
    }
  };

  const isWalletRightValid = (money) => {
    if (currentWalletConverted === money) {
      return (
        <img
          src={valid}
          alt=""
          className="exchange_container_contentRight-select_content_item_valid"
        />
      );
    }
  };
  const handleConverted = () => {
    // TODO : fetch data
    let ratetmp = {};
    const fxRateUrl = `${routes.routes.back_end.user.account.fx_rate.url}`;
    const fxRateMethod = `${routes.routes.back_end.user.account.fx_rate.method}`;

    ApiService.fetchApiData(
      fxRateUrl +
        parseFloat(currentValueInput) +
        "/" +
        walletConvertedToShow.currency_code +
        "/" +
        CurrentWallet.currency_code +
        "/" +
        user.id,
      fxRateMethod,
      "transactions"
    ).then((result) => {
      console.log("result rate : ", result);
      setDataRate(result);

      if (CurrentWallet.currency === walletConvertedToShow.currency) {
        setCurrentValueConverted(parseFloat(currentValueInput));
      } else {
        if (parseFloat(CurrentWallet.amount) >= parseFloat(currentValueInput)) {
          setCurrentValueConverted(
            parseFloat(currentValueInput) * parseFloat(result.rate)
          );
        } else {
          setCurrentValueConverted(0);
        }
      }
    });
    //data_rate = data_rate_tmp;
  };
  const handleValueChange = (e) => {
    setCurrentValueInput(e.target.value);
  };

  const renderCurrentImgToConvert = (wallet, classImg) => {
    let imgToDisplay = dollar;
    if (wallet.currency === "Bitcoin") {
      imgToDisplay = bitcoin;
    } else if (wallet.currency === "Dollar") {
      imgToDisplay = dollar;
    } else if (wallet.currency === "Etherum") {
      imgToDisplay = etherum;
    } else if (wallet.currency === "Euro") {
      imgToDisplay = euro_;
    }

    return <img src={imgToDisplay} alt="" className={classImg} />;
  };

  return (
    <div className="flex flex-fd-c">
      <div className="page_title">
        Exchange
        <div className="register_header_path flex flex-js-fs">
          <Link to="/home"> Home </Link>
          <div className="register_header_path_gt">{">"}</div>{" "}
          <Link to="/account">
            <span>Account</span>
          </Link>
          <div className="register_header_path_gt">{">"}</div>{" "}
          <span>Exchange</span>
        </div>
      </div>

      <div className="exchange">
        <div className="exchange_titles flex flex-js-sb">
          <div className="exchange_titles_send">Send</div>
          <div className="exchange_titles_get">Get</div>
        </div>
        <div className="exchange_container flex flex-ai-c">
          {/*<img src={dollar} alt="" className="exchange_container_sendimg"></img>*/}

          {/*<select className="exchange_container_select_send">
            <option>Dollarr</option>
            </select>*/}
          <div
            className={
              isWalletToConverttActive
                ? "exchange_container_left flex flex-fd-c flex-js-fs background_dark"
                : "exchange_container_left flex flex-fd-c flex-js-fs"
            }
          >
            <div
              className="flex flex-ai-c flex-js-fs"
              onClick={() =>
                setIsWalletToConverttActive(!isWalletToConverttActive)
              }
            >
              <img
                src={expand_button}
                alt=""
                className="exchange_container_img-down"
              />

              {renderCurrentImgToConvert(
                CurrentWallet,
                "exchange_container_sendimg"
              )}
              <div className="flex flex-fd-c exchange_container_left_text">
                <span className="exchange_container_left_text_first">
                  {currentWalletToExchage}
                </span>
                <span className="exchange_container_left_text_second">
                  {parseFloat(CurrentWallet.amount).toFixed(2)}
                </span>
              </div>
            </div>

            {isWalletToConverttActive && (
              <div className="exchange_container_content-select_content_left flex-fd-c">
                {allWalletsObject.map((value) => {
                  return (
                    <div className="exchange_container_content-select_content_item flex flex-ai-c flex-js-fs">
                      {renderCurrentImgToConvert(
                        value,
                        "exchange_container_content-select_content_item_img"
                      )}
                      <span
                        className=""
                        onClick={() => handleMoneySelected(value.currency)}
                      >
                        {value.currency}
                      </span>
                      {isWalletValid(value.currency)}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <input
            type="number"
            defaultValue={parseFloat(CurrentWallet.amount).toFixed(2)}
            //value={CurrentWallet.amount}
            min={0}
            className="exchange_container_input"
            onChange={(e) => handleValueChange(e)}
          ></input>
          <div className="exchange_img" onClick={() => handleConverted()}></div>
          <div className="exchange_container_input flex flex-ai-c flex-js-fe">
            {currentValueConverted != 0 && currentValueConverted}
          </div>
          {/*<select className="exchange_container_select_get">
            <option>Euro</option>
        </select>*/}
          <div
            className={
              isWalletConvertedActive
                ? "exchange_container_right flex flex-fd-c flex-js-fs background_dark"
                : "exchange_container_right flex flex-fd-c flex-js-fs"
            }
          >
            <div
              className="flex flex-ai-c flex-js-fe"
              onClick={() =>
                setIsWalletConvertedActive(!isWalletConvertedActive)
              }
            >
              <div className="flex flex-fd-c exchange_container_right_text">
                <span className="exchange_container_right_text_first">
                  {walletConvertedToShow.currency}
                </span>
                <span className="exchange_container_right_text_second">
                  {parseFloat(walletConvertedToShow.amount).toFixed(2)}
                </span>
              </div>
              {renderCurrentImgToConvert(
                walletConvertedToShow,
                "exchange_container_getimg"
              )}
              <img
                src={expand_button}
                alt="dads"
                className="exchange_container_img-right"
              />
            </div>
            {isWalletConvertedActive && (
              <div className="exchange_container_contentRight-select_content exchange_container_contentRight-select_content_right flex-fd-c">
                {allWalletsObject.map((value) => {
                  return (
                    <div className="exchange_container_contentRight-select_content_itemRight flex-js-fe">
                      <div className="exchange_container_contentRight-select_content_icon">
                        {isWalletRightValid(value.currency)}
                      </div>
                      <span
                        className=""
                        onClick={() =>
                          handleMoneySelectedConverted(value.currency)
                        }
                      >
                        {value.currency}
                      </span>
                      {renderCurrentImgToConvert(
                        value,
                        "exchange_container_content-select_content_item_img"
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="exchange_footer flex flex-fd-c flex-js-fs">
          <div className="exchange_footer_taux flex flex-ai-c">
            <img src={taux} alt="" className="exchange_footer_taux_img"></img>
            <div>1$ = 0,89€</div>
          </div>
          <div className="exchange_footer_clause flex flex-ai-c">
            <img
              src={shield}
              alt=""
              className="exchange_footer_clause_img"
            ></img>
            This transaction is secure by protocole nom
          </div>
          <div
            className="exchange_footer_button flex flex-ai-c "
            onClick={() => handleConfirm()}
          >
            <img src={exchange} alt=""></img>
            Exchange
          </div>
        </div>
      </div>
      {confirmActive && currentValueConverted != 0 && (
        <div className="flex flex-ai-c">
          <div className="exchange_popup flex flex-fd-c flex-js-fs">
            <div className="exchange_popup_header">
              <div>Exchange</div>
              <div className="exchange_from_line">
                <img src={line} alt=""></img>
              </div>
            </div>
            <div className="exchange_popup_total flex  flex-ai-c ">
              <div
                className="exchange_popup_total_send flex"
                id="transfer_total"
              >
                {currentValueConverted != 0
                  ? parseFloat(currentValueInput).toFixed(2)
                  : 0}
              </div>
              <div className="flex">
                {renderCurrentImgToConvert(
                  CurrentWallet,
                  "exchange_popup_total_img"
                )}
                {renderCurrentImgToConvert(walletConvertedToShow, "")}
              </div>
              <div
                className="exchange_popup_total_get flex"
                id="transfer_total"
              >
                {parseFloat(currentValueConverted).toFixed(2)}
              </div>
            </div>
            <div className="exchange_popup_buttons flex flex-fd-c flex-ai-c">
              <div className="exchange_popup_buttons_text">
                Do you confirm this exchange ?
              </div>
              <div className="flex">
                <div
                  className="exchange_popup_buttons_cancel flex flex-ai-c"
                  onClick={() => handleConfirm()}
                >
                  Cancel
                </div>
                <div
                  className="exchange_popup_buttons_confirm flex flex-ai-c"
                  onClick={() => handleConfirmSuccess()}
                >
                  I confirm
                </div>
              </div>
            </div>
          </div>
          <div id="overlay" onClick={() => handleConfirm()}></div>
        </div>
      )}
      {confirmSuccess && (
        <div className="flex">
          <div className="exchange_popup flex flex-fd-c flex-js-fs">
            <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c">
              {" "}
              Transaction confirmed !
            </div>
          </div>
          <div id="overlay" onClick={() => handleSuccess()}></div>
        </div>
      )}
    </div>
  );
};

export default Exchange;
