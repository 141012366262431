import React from 'react';
import './Input.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Input({
  label,
  name,
  onChange = (e) => {},
  placeholder,
  type = 'text',
  value,
}) {
  const handleDateChange = (date) => {
    onChange({ target: { name, value: date } });
  };

  return (
    <div className="controls">
      <label htmlFor={name} className="controls_input_label flex flex-js-fs">
        {label}
      </label>
      {type === 'textarea' ? (
        <textarea
          onChange={onChange}
          name={name}
          id={name}
          cols="20"
          rows="5"
          className="controls_input_textarea"
          placeholder={placeholder}
          value={value}
        />
      ) : (
        type === 'date' ? (
          <DatePicker
            selected={value}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            placeholderText={placeholder}
            customInput={<input id={name} name={name} className="controls_input cursor-input" />}
          />
        ) : (
          <input
            id={name}
            type={type}
            name={name}
            className="controls_input"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        )
      )}
    </div>
  );
}

export default Input;
