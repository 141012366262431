import React, { useEffect } from "react";

import config from "../../Config/config";
import { useRef } from "react";
import "./term.scss";

function Term() {
  const ref = useRef();
  useEffect(() => {
    fetch(`${config.getBackEndUrl("user")}/static/term.html`)
      .then((response) => response.text())
      .then((text) => {
        ref.current.innerHTML = text;
      });
  }, [ref.current]);
  return <div ref={ref} className="term" />;
}

export default Term;
