import React, { useState, useCallback } from "react";
import UserItem from "../UserItem";
import "./Block.scss";
import BtnSetting from "../BtnSetting";

export default function Block() {
  return (
    <div className="Block">
      <div className="block-title">Select user from your link</div>
      <div className="users-list">
        <UserItem />
      </div>
      <div className="decision-buttons">
        <BtnSetting text="Cancel" className="cancel" />
        <BtnSetting text="Block" className="confirm" />
      </div>
    </div>
  );
}
