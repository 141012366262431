import header_title from "../../../Assets/img/Market/header_title.svg";
import header_right_img from "../../../Assets/img/Market/header_right_img.svg";
import { useEffect } from "react";
import { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import "./style.css";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import "../../SwiperHome/SwiperHome.scss";
import { useState } from "react";
import { useMemo } from "react";

const content = [
  <div className="market_header">
    {/* <div className="market_header_title">What’s new in your market ?</div> */}
    <div className="market_header_content flex flex-js-fs">
      <div className="market_header_content_left flex flex-fd-c">
        <div className="market_header_content_left_class">
          Fashion & Jewelry
        </div>
        <div>
          <img src={header_title} alt=""></img>
        </div>
        <div className="market_header_content_left_text">
          Discover the latest fashion novelties,<br></br> unique pieces that
          only you will own!
        </div>
        <div className="market_header_content_left_button flex flex-ai-c">
          Explore now
        </div>
      </div>
      <div className="market_header_content_right flex">
        <img
          src={header_right_img}
          alt=""
          className="market_header_content_right_img"
        ></img>
      </div>
    </div>
    <div className="flex home_container_point_carousel">
      {/* <div className="details_container_point_carousel_1">.</div>
            <div className="details_container_point_carousel_2">.</div>
            <div className="details_container_point_carousel_3">.</div> */}
    </div>
  </div>,
];

const MarketHeader = () => {
  const [banners, setBanners] = useState([]);

  const getMarket = async () => {
    const bannerUrl = `${routes.routes.back_end.iconic_user.banners.url}`;
    const bannerMethod = `${routes.routes.back_end.iconic_user.banners.method}`;
    await ApiService.fetchApiData(bannerUrl, bannerMethod, "user").then(
      (result) => {
        const marketPuzles = result.filter((res) =>
          res.tab.includes("Puzzlesmarket")
        );
        console.log("market ", marketPuzles);
        setBanners(marketPuzles);
      }
    );
  };

  useEffect(() => {
    getMarket();
  }, []);

  const carousel = [
    {
      back: banners[0],
      description: content,
    },
    {
      back: banners[1],
      description: content,
    },
  ];

  return (
    <div className=" flex flex-fd-c flex-js-fs padding">
        
      {console.log("tab ", banners)}
      <Swiper
        modules={[Navigation, Pagination]}
        className="home_section2 flex-fd-c flex"
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {banners.length > 0 &&
          carousel.map((car, index) => {
            return (
              <Fragment key={index}>
                <SwiperSlide
                  style={{
                    backgroundImage: `url(${car.back?.img})`,
                  }}
                >
                  {car.description}
                </SwiperSlide>
              </Fragment>
            );
          })}
      </Swiper>
    </div>
  );
};

export default MarketHeader;

