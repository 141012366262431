import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/img/register_logo.svg";
import password_hidden from "../../Assets/img/password_hidden.svg";
import ApiService from "../../Utils/ApiService";
import routes from "../../Routes/routes.json";
import validator from "validator";
import videoBackground from "../../Assets/img/puzzle_iconic-v12.mp4";
import "./register.scss";

const Register = () => {
  const [isPasswordPage, setPasswordPage] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmPassword, setConfirmPasssword] = useState("");
  const [isAcceptChecked, setIsAcceptChecked] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [isBtnPasswordActive, setIsBtnPasswordActive] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    //buttonChange();
    if (userName !== "" && userName.length < 4) {
      setUsernameError(true);
    } else {
      setUsernameError(false);
    }
    if (!validator.isEmail(email) && email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (
      isAcceptChecked &&
      validator.isEmail(email) &&
      userName !== "" &&
      userName.length > 4
    ) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [isAcceptChecked, email, userName]);

  useEffect(() => {
    const isPassword = validator.isStrongPassword(password, {
      minLength: 8,
      minUppercase: 1,
    });

    if (password !== "" && !isPassword) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    if (isPassword && password === confirmPassword) {
      setIsBtnPasswordActive(true);
    } else {
      setIsBtnPasswordActive(false);
    }
  }, [password, confirmPassword]);
  const handlesNextPage = () => {
    if (isAcceptChecked && userName != "" && validator.isEmail(email)) {
      setRegisterError(false);
      setPasswordPage(!isPasswordPage);
    } else {
      setRegisterError(true);
    }
  };
  const hidePassword = (id) => {
    let password_input = document.getElementById("password_input");
    let confirm_password_input = document.getElementById(
      "confirm_password_input"
    );
    switch (id) {
      case "password":
        if (password_input.type === "text") {
          password_input.type = "password";
        } else {
          password_input.type = "text";
        }
        break;
      case "confirm_password":
        if (confirm_password_input.type === "text") {
          confirm_password_input.type = "password";
        } else {
          confirm_password_input.type = "text";
        }
        break;
      default:
        break;
    }
  };

  const buttonChange = () => {
    console.log("new onChange");
    let username_input = document.getElementById("username_input");
    let email_input = document.getElementById("email_input");
    let phoneNumber_input = document.getElementById("phoneNumber_input");
    let button = document.getElementById("choose_button");
    setUserName(username_input.value);
    setEmail(email_input.value);
    setPhoneNumber(phoneNumber_input.value);

    console.log(username_input.value);
  };

  const processError = () => {
    setRegisterError(true);
    setPasswordPage(false);
    setIsBtnActive(false);
    setIsAcceptChecked(false);
    setEmailError(true);
    setUsernameError(true);
  };

  const handleCreateAccount = () => {
    let data = new Object();
    data.username = userName;
    data.email = email;
    data.password = password;
    data.phone_number = phoneNumber;
    //data.confirmPassword = confirmPassword;
    const route = `${routes.routes.back_end.iconic_user.create.url}`;
    const method = `${routes.routes.back_end.iconic_user.create.method}`;

    if (
      userName != "" &&
      password === confirmPassword &&
      validator.isEmail(email) &&
      validator.isStrongPassword(password, {
        minLength: 8,
        minUppercase: 1,
      })
    ) {
      ApiService.sendingPutData(route, method, data, "user")
        .then((result) => {
          setRegisterError(false);
          navigate("/connection");
        })
        .catch((err) => {
          processError();
        });
    } else {
      setRegisterError(true);
    }
  };

  return (
    <div className="video-container">
      <video autoPlay muted loop id="background-video">
        <source src={videoBackground} type="video/mp4" />
      </video>

      <div className="register flex flex-fd-c">
        <div className="register_header flex flex-js-fs flex-fd-c">
          <div className="register_header_title">Register</div>
          <div className="register_header_path flex flex-js-fs">
            Home <div className="register_header_path_gt">{">"}</div>{" "}
            <span>Register</span>
          </div>
        </div>
        <div className="content">
          <div className="test">
            <div className="content-form">
              <div className="register_logo flex flex-fd-c">
                <img
                  draggable="false"
                  src={logo}
                  alt="logo"
                  className="register_logo_img"
                />
                <div className="register_logo_text">Welcome in Iconic !</div>
                {!isPasswordPage && (
                  <>
                    {registerError && (
                      <div className="register_logo_error">
                        <span>Register Error</span>
                      </div>
                    )}

                    {emailError && (
                      <div className="register_logo_error">
                        <span> Email address already exists</span>
                      </div>
                    )}
                  </>
                )}

                {!validator.isStrongPassword(password, {
                  minLength: 8,
                  minUppercase: 1,
                }) &&
                  isPasswordPage &&
                  password !== "" && (
                    <div className="register_logo_error">
                      <span>
                        Le mot de passe doit contenir au moins 8 caracteres,une
                        majuscule,un caractère spécial et une chiffre
                      </span>
                    </div>
                  )}
              </div>
              {!isPasswordPage && (
                <div className="register_form flex flex-fd-c">
                  <div className="register_form_text flex flex-js-fs">
                    Username
                  </div>
                  <input
                    type="text"
                    //className="register_form_input"
                    className={`register_form_input  ${
                      usernameError
                        ? "register_form_error"
                        : "register_form_normal"
                    }`}
                    placeholder="Choose your username"
                    id="username_input"
                    onChange={() => buttonChange()}
                  ></input>
                  <div className="register_form_text flex flex-js-fs">
                    Email
                  </div>
                  <input
                    type="email"
                    className={`register_form_input  ${
                      emailError
                        ? "register_form_error"
                        : "register_form_normal"
                    }`}
                    placeholder="Enter your e-mail"
                    id="email_input"
                    onChange={() => buttonChange()}
                  ></input>
                  <div className="register_form_text flex flex-js-fs">
                    Phone Number
                  </div>
                  <input
                    type="number"
                    className={`register_form_input  ${
                      emailError
                        ? "register_form_error"
                        : "register_form_normal"
                    }`}
                    placeholder="Enter your Phone Number"
                    id="phoneNumber_input"
                    onChange={() => buttonChange()}
                  ></input>
                  <div className="register_form_checkbox">
                    <input
                      type="checkbox"
                      className="register_form_checkbox_input"
                      onChange={() => {
                        setIsAcceptChecked(!isAcceptChecked);
                      }}
                    ></input>
                    {console.log("isAccept checked or : ", isAcceptChecked)}
                    By signing up you accept the <a>Term of service</a>
                    <br></br> and <a>Privacy Policy</a>
                  </div>
                </div>
              )}
              {isPasswordPage && (
                <div className="register_form flex flex-fd-c">
                  <div className="register_form_text flex flex-js-fs">
                    Password
                  </div>
                  <input
                    type="password"
                    //className="register_form_input"
                    className={`register_form_input  ${
                      passwordError && "connection_form_error"
                    }`}
                    placeholder="Choose your password"
                    id="password_input"
                    onChange={(e) => {
                      setPassword(e.target.value.trim());
                    }}
                  ></input>
                  <img
                    draggable="false"
                    src={password_hidden}
                    alt=""
                    className="register_form_hide"
                    onClick={() => hidePassword("password")}
                  />
                  <div className="register_form_text flex flex-js-fs">
                    Password
                  </div>
                  <input
                    type="password"
                    className={`register_form_input  ${
                      registerError
                        ? "register_form_error"
                        : "register_form_normal"
                    }`}
                    placeholder="confirm your password"
                    id="confirm_password_input"
                    onChange={(e) => {
                      setConfirmPasssword(e.target.value.trim());
                    }}
                  ></input>
                  <img
                    draggable="false"
                    src={password_hidden}
                    alt=""
                    className="register_form_hide"
                    onClick={() => hidePassword("confirm_password")}
                  />
                </div>
              )}
              <div className="register_footer flex flex-fd-c">
                {!isPasswordPage && isBtnActive && (
                  <button
                    className="register_footer_btn flex register_footer_active"
                    id="choose_button"
                    //disabled={true}
                    onClick={() => handlesNextPage()}
                  >
                    Choose my password
                  </button>
                )}
                {!isPasswordPage && !isBtnActive && (
                  <button
                    className="register_footer_btn flex register_footer_disable"
                    disabled={true}
                  >
                    Choose my password
                  </button>
                )}
                {isPasswordPage && isBtnPasswordActive && (
                  <div
                    className="register_footer_btn flex register_footer_active"
                    onClick={() => handleCreateAccount()}
                  >
                    Create my account
                  </div>
                )}
                {isPasswordPage && !isBtnPasswordActive && (
                  <div
                    className="register_footer_btn flex register_footer_disable"
                    disabled={true}
                  >
                    Create my account
                  </div>
                )}
                <div className="register_footer_text">
                  Already have an account ?{" "}
                  <Link to="/connection">
                    <span>Sign in</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
