import link_avatar from "../../Assets/img/Links/link_avatar.svg";
const Link = ({avatar, username}) => {
    return (
        <div className="links_body_left_contacts_mylinks_content flex flex-ai-c flex-js-fs">
            <img src={link_avatar} alt=""></img>
            <div>
                @{username} 
            </div>
        </div>
    );
}

export default Link;