import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import btn_cancel_order from "../../../Assets/vectors/Account/btn_cancel_order.svg";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import { useSelector, useDispatch } from "react-redux";

const AllTransactions = () => {
  const user = useSelector((state) => state.Auth.user);
  const location = useLocation();
  const [orders, setOrders] = useState(location.state.ordersData);
  const [isSell, setIsSell] = useState(location.state.isSell);
  const [currentData, setCurrentData] = useState(null);

  console.log("isSell : ", isSell);
  useEffect(() => {
    setCurrentData(orders);
  }, []);

  const fetchingOrdersData = (buy_sell) => {
    const ordersRoute = `${routes.routes.back_end.user.account.list_all_orders_user_my_order.url}`;
    const method = `${routes.routes.back_end.user.account.list_all_orders_user_my_order.method}`;

    console.log("all route : ", ordersRoute + user.id + "/" + buy_sell);
    ApiService.fetchApiData(
      ordersRoute + user.id + "/" + buy_sell,
      method,
      "transactions"
    )
      .then((result) => {
        console.log("result in all trans : ", result);
        setCurrentData(result);
      })
      .catch(() => {
        console.log("error orders : ");
      });
     
  };
  console.log("all transactions", currentData)
  
  const handleOrderCancelled = (data) => {
    const cancelOrdersRoute = `${routes.routes.back_end.user.account.cancel_puzzle_limit_order.url}`;
    const method = `${routes.routes.back_end.user.account.cancel_puzzle_limit_order.method}`;

    if (!data["ref_order"]) {
      console.log("ref_order not exist");
      return;
    }
    console.log(
      "cancelOrdersRoute + data.ref_order : ",
      cancelOrdersRoute + data.ref_order
    );
    ApiService.fetchApiData(
      cancelOrdersRoute + data.ref_order, // adding order reference
      method,
      "transactions"
    )
      .then((result) => {
        console.log("result cancel_order : ", result);
        if (!isSell) {
          console.log("fetch buy");
          fetchingOrdersData("buy");
        } else {
          console.log("fetch sell");
          fetchingOrdersData("sell");
        }
      })
      .catch(() => {
        console.log("error cancel orders : ");
      });
  };

  console.log("orders : ", orders);
  return (
    <div className="all-transactions flex flex-fd-c flex-js-fs">
      <div className="page_title page_title_transferin">
        All transactions
        <div className="register_header_path flex flex-js-fs">
          <Link to="/home">Home</Link>{" "}
          <div className="register_header_path_gt">{">"}</div>{" "}
          <Link to="/account">
            <span>Account</span>
          </Link>{" "}
          <div className="register_header_path_gt">{">"}</div>{" "}
          <span>All transactions</span>
        </div>
      </div>

      <div className="all-transactions_container">
        <div className="all-transactions_container_content">
          <div className="all-transactions_container_content_tab scrool">
            <div className="flex-elem all-transactions_container_content_tab_row">
              <div className="all-transactions_container_content_tab_row_transac">
                Transaction
              </div>
              <div className="all-transactions_container_content_tab_row_date">
                Date
              </div>
              <div className="all-transactions_container_content_tab_row_side">
                Side
              </div>
              <div className="all-transactions_container_content_tab_row_date">
                Pieces Complete
              </div>
              <div className="all-transactions_container_content_tab_row_price">
                Total Pieces Ordered
              </div>
              <div className="all-transactions_container_content_tab_row_date">
                Price per piece
              </div>
              <div className="all-transactions_container_content_tab_row_price">
                Total Price Complete
              </div>
              <div className="all-transactions_container_content_tab_row_price">
                Total Order Price
              </div>
              <div className="all-transactions_container_content_tab_row_date">
                Price currency
              </div>
              <div className="all-transactions_container_content_tab_row_date">
                Pieces Cancelled
              </div>
              <div className="all-transactions_container_content_tab_row_price">
                Total Price Cancelled
              </div>
              <div className="all-transactions_container_content_tab_row_date">
                Status
              </div>
            </div>
          </div>

          {currentData &&
            currentData
              .slice(0)
              .reverse()
              .map((value, key) => {
                return (
                  <>
                    <div className="flex-elem flex-fd-c all-transactions_container_content_elems scrool">
                      <div className="flex-elem all-transactions_container_content_elems_row">
                        <div className="flex-elem  all-transactions_container_content_elems_row_transac">
                          {value.Transaction}
                        </div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_date">
                          {value.Date.substr(0, 10)}
                        </div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_side">
                          {value.Side}
                        </div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_date">
                          {value["Pieces Complete"]}
                        </div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_price">
                          {value["Total Pieces Ordered"]}
                        </div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_date">
                          null
                        </div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_price">
                          {value["Total Price Complete"]}
                        </div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_price">
                          {value["Total Order Price"]}
                        </div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_price">
                          {value["Total Price Complete"]}
                        </div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_date"></div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_date"></div>
                        <div className="flex-elem  all-transactions_container_content_elems_row_status">
                          {value.Status}
                        </div>

                        <div
                          className="all-transactions_container_content_elems_row_cancel flex flex-ai-c"
                          onClick={() => handleOrderCancelled(value)}
                        >
                          <img
                            src={btn_cancel_order}
                            alt=""
                            className="btn_cancel_order_img"
                          ></img>
                          Cancel order
                        </div>
                      </div>
                    </div>

                    <div className="all-transactions_container_content_line"></div>
                  </>
                );
              })}

          <div className="transaction_scrool scrool"></div>
        </div>
      </div>
    </div>
  );
};

export default AllTransactions;
