import React, { Fragment, useCallback, useState } from 'react'
import Modal from '../../../common/Modal';
import Groups from '../../Seetings/Group';
import "./RadioButton.scss"

export default function CustomGroupButton() {
  const [showCustom, setShowCustom] = useState(false);

  const showCustomModal = useCallback(() => {
    setShowCustom(true);
  }, []);

  const onClose = useCallback(() => {
    setShowCustom(false);
  }, []);


  return (
    <Fragment>
      <div className='CustomGroupButton RadioInput' onClick={showCustomModal}>
        <div className='group-button-content'>
          Custom Group
        </div>
      </div>


      <Modal isOpen={showCustom} title="Create custom group" onClose={onClose}>
        <Groups />
      </Modal>
    </Fragment>

  )
}