import React, { useEffect, useState, useRef } from "react";
import Puzzle from "../../Puzzle";
import User from "../User";
import { useNavigate } from "react-router-dom";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { useSelector } from "react-redux";

import btn_search_delete from "../../../Assets/img/btn_search_delete.svg";
import search from "../../../Assets/vectors/search.svg";

const SearchNavbar = ({ Popup = false }) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [puzzlesSearched, setPuzzlesSearched] = useState([]);
  const [usersSearched, setUsersSearched] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [allPuzzles, setAllPuzzles] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const islight = useSelector((state) => state.darkMode);
  let searchbar = document.getElementById("navbar_search");
  const Navigate = useNavigate();
  const path = window.location.pathname;
  const ref = useRef();

  useEffect(() => {
    const puzzlesRoute = `${routes.routes.back_end.puzzle.puzzles.url}`;
    const usersRoute = `${routes.routes.back_end.iconic_user.getUsers.url}`;
    const method = `${routes.routes.back_end.puzzle.puzzles.method}`;
    console.log(puzzlesRoute);

    ApiService.fetchApiData(puzzlesRoute, method, "puzzle").then((result) => {
      console.log("all puzzle : ", result);
      setAllPuzzles(result);
    });

    ApiService.fetchApiData(usersRoute, method, "user").then((result) => {
      console.log("all users : ", result);
      setAllUsers(result);
    });
  }, []);

  useEffect(() => {
    processElementsSearched(allPuzzles, allUsers);
  }, [searchValue]);

  useEffect(() => {
    const checkIfClickedOutsideLogger = (e) => {
      if (isSearchActive && ref.current && !ref.current.contains(e.target)) {
        setIsSearchActive(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideLogger);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsideLogger);
    };
  }, [isSearchActive]);

  const processElementsSearched = (allpuzzles, allusers) => {
    let listPuzzles = [];
    let listUsers = [];
    if (searchValue === "") {
      setUsersSearched(listUsers);
      setPuzzlesSearched(listPuzzles);
      setIsSearchActive(false);
    } else {
      for (let index = 0; index < allusers.length; index++) {
        const element = allusers[index];
        if (
          element.username.includes(searchValue) &&
          element.privacy["account_type"] != "private"
        ) {
          listUsers.push(element);
        }
        if (listUsers.length === 3) {
          break;
        }
      }
      for (let index = 0; index < allpuzzles.length; index++) {
        const element = allpuzzles[index];
        if (element.title.includes(searchValue)) {
          listPuzzles.push(element);
        }
        if (listPuzzles.length === 3) {
          break;
        }
      }
      setUsersSearched(listUsers);
      setPuzzlesSearched(listPuzzles);
    }
  };

  const handleSearch = (e) => {
    setIsSearchActive(true);
    setSearchValue(e.target.value);
  };

  const searchEnter = () => {
    searchbar.addEventListener("keyup", function (event) {
      console.log(event.key);
      if (event.key == "Enter") {
        Navigate("search_result", { state: searchValue });
      }
    });
  };

  const quitSearch = () => {
    setIsSearchActive(false);
  };

  return (
    <>
      {!Popup ? (
        <>
          <div
            className={`navbar_search ${
              !islight ? "background_search_white" : "background_search_black"
            }`}
          >
            <img
              draggable="false"
              src={search}
              alt="notify"
              className="navbar_search_img"
            />
            <input
              className={
                isSearchActive
                  ? "navbar_search_input active_width"
                  : "navbar_search_input"
              }
              placeholder="search puzzles, creators..."
              id="navbar_search"
              onChange={(e) => handleSearch(e)}
              onKeyPress={() => searchEnter()}
            />
            {isSearchActive && (
              <img
                src={btn_search_delete}
                alt=""
                className="navbar_search_popup_delete"
                onClick={() => quitSearch()}
              ></img>
            )}
          </div>
          {isSearchActive && path === "/links" && (
            <div className="navbar_search_popup">
              <div className="navbar_search_popup_categories">Users</div>
              <div>
                {usersSearched.map((value, index) => {
                  return <User user={value} />;
                })}
              </div>
              <div className="navbar_search_popup_categories">Puzzles</div>
              <div>
                {puzzlesSearched.map((value, index) => {
                  return <Puzzle Search={true} ObjPuzzle={value} />;
                })}
              </div>
            </div>
          )}
          {isSearchActive && path !== "/links" && (
            <div className="navbar_search_popup" ref={ref}>
              <div className="navbar_search_popup_categories">Puzzles</div>
              <div>
                {puzzlesSearched.map((value, index) => {
                  return <Puzzle Search={true} ObjPuzzle={value} />;
                })}
              </div>
              <div className="navbar_search_popup_categories">Users</div>
              <div>
                {usersSearched.map((value, index) => {
                  return <User user={value} />;
                })}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={`navbar_search_menu ${
              !islight ? "background_search_white" : "background_search_black"
            }`}
          >
            <img
              draggable="false"
              src={search}
              alt="notify"
              className="navbar_search_img"
            />
            <input
              className={
                isSearchActive
                  ? "navbar_search_input active_width"
                  : "navbar_search_input"
              }
              placeholder="search puzzles, creators..."
              id="navbar_search"
              onChange={(e) => handleSearch(e)}
              onKeyPress={() => searchEnter()}
            />
            {isSearchActive && (
              <img
                src={btn_search_delete}
                alt=""
                className="navbar_search_popup_delete"
                onClick={() => quitSearch()}
              ></img>
            )}
          </div>
          {isSearchActive && (
            <div className="navbar_search_popup" ref={ref}>
              <div className="navbar_search_popup_categories">Puzzles</div>
              <div>
                {puzzlesSearched.map((value, index) => {
                  return <Puzzle Search={true} ObjPuzzle={value} />;
                })}
              </div>
              <div className="navbar_search_popup_categories">Users</div>
              <div>
                {usersSearched.map((value, index) => {
                  return <User user={value} />;
                })}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default SearchNavbar;
