import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import Gallery from "./Gallery";
import { useSelector, useDispatch } from "react-redux";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { useParams } from "react-router-dom";
import { getUser } from "../../../Redux/slices/user";

const Myprofile = () => {
  const user = useSelector((state) => state.Auth.user);
  const [allClasses, setAllClasses] = useState([]);
  const [allPuzzles, setAllPuzzles] = useState([]);
  const profil_visted = useSelector((state) => state.User);
  const [userconnected, setUserconnected] = useState(true);

  const dispatch = useDispatch();

  //console.log(profil_visted)

  useEffect(() => {
    const puzzlesRoute = `${routes.routes.back_end.puzzle.gallery.url}`;
    const classesRoute = `${routes.routes.back_end.puzzle.classes.url}`;
    const method = `${routes.routes.back_end.puzzle.puzzles.method}`;

    console.log("route route : ", puzzlesRoute + user.username + "/user");
    ApiService.fetchApiData(
      puzzlesRoute + user.id + "/user",
      method,
      "puzzle"
    ).then((result) => {
      console.log("result all puzzles myProfil : ", result);
      setAllPuzzles(result);
    });

    ApiService.fetchApiData(classesRoute, method, "puzzle").then((result) => {
      setAllClasses(result);
    });
  }, []);

  return (
    <div className="profile">
      <Dashboard user={profil_visted} userconnected={userconnected} />
      {console.log("allClasses   : ",allClasses)}
      {console.log("allClasses   : ",allPuzzles)}
      {
        allClasses && allPuzzles &&
        
          <Gallery allClasses={allClasses} allPuzzles={allPuzzles} isMyProfile={true}/>
        
      }
     
    </div>
  );
};

export default Myprofile;
