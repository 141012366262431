
import React from "react";




const CircleProgressBarBase = ({
  className,
  trailStrokeColor,
  strokeColor,
  percentage,
  innerText,
  radioCircle1,
  radioCircle2,
  y,
  textClass="",
  size,
  x,
  withtext,
  strokeDasharray,
  strokeDashoffset

}) => {
  const INITIAL_OFFSET = 25;
  const circleConfig = {
    viewBox: "0 0 "+size+" "+size,
    x: x,
    y: "19",
    radio: "12",
  };
  return (
    <figure className={className}>
      <svg viewBox={circleConfig.viewBox}>
        <circle
          className="ring"
          cx={circleConfig.x}
          cy={circleConfig.y}
          r={radioCircle1}
          fill="transparent"
          stroke={trailStrokeColor}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}

        />

        <circle
          className="path"
          cx={circleConfig.x}
          cy={circleConfig.y}
          r={radioCircle2}

          fill="transparent"
          stroke={strokeColor}
          strokeDasharray={`${percentage} ${100 - percentage}`}
          strokeDashoffset={INITIAL_OFFSET}
        />
        {withtext &&
          <g className="circle-label">
            <text x="50%" y={y} className={textClass}>
              {percentage}%
            </text>
          </g>
        }
      </svg>
    </figure>
  );
};

export default CircleProgressBarBase;
